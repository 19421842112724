import LogRocket from "logrocket";

const useLogRocket =
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_LOG_ROCKET_APP_ID;

export const logRocket = useLogRocket
  ? LogRocket
  : { init: () => null, identify: () => null };

const scrubbedApis = ["/contactInformation"];

const sanitizedRequests = new Set<string>();

export const logRocketConfig = {
  network: {
    requestSanitizer: (request: any) => {
      const url = request.url.toLowerCase();
      for (const scrubbedApi of scrubbedApis) {
        if (url.indexOf(scrubbedApi.toLowerCase()) !== -1) {
          request.body = null; // tracking the request, but removing the body
          sanitizedRequests.add(request.reqId);
          break;
        }
      }

      // otherwise log the request normally
      return request;
    },
    responseSanitizer: (response: any) => {
      // removing PII from the responses
      if (sanitizedRequests.has(response.reqId)) {
        response.body = null; // response body is a string, so just remove the whole body
        sanitizedRequests.delete(response.reqId);
      }
      return response;
    },
  },
};
