import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Logo = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 1077 1077"
      titleAccess="The ruumi logo"
      shapeRendering="geometricPrecision"
      {...props}
    >
      <path
        d="M903.904 518.101C886.059 522.52 868.78 528.696 852.367 536.523C915.096 439.854 985.891 347.836 1064.11 261.303C958.52 314.455 860.262 379.152 771.465 453.994C773.495 445.172 776.577 436.585 780.654 428.388C891.12 231.091 1019.76 211.196 1019.76 211.196C1019.76 211.196 879.933 201.432 724.522 308.463C742.608 216.549 767.503 125.886 799.031 37.1109C731.56 112.381 673.216 194.23 625.042 281.198C568.903 188.211 500.902 101.751 422.488 23.6631C462.245 107.506 496.009 193.658 523.565 281.567C506.985 254.873 484.855 231.46 458.444 212.67C435.667 195.699 409.291 183.309 381.013 176.298C352.735 169.287 323.178 167.808 294.243 171.958C277.264 104.903 270.672 64.375 270.672 64.375C261.613 103.173 254.943 142.412 250.696 181.905C199.176 199.423 152.413 227.083 113.662 262.961C113.662 262.961 173.59 207.696 247.1 215.433C240.301 288.953 239.166 362.825 243.705 436.493C193.895 386.351 139.566 340.193 81.3014 298.515C135.575 376.886 184.156 458.489 226.725 542.786C210.398 535.068 193.264 528.896 175.587 524.365C60.7264 496.916 0 520.312 0 520.312C0 520.312 129.443 563.419 184.776 640.974C221.931 693.108 230.321 728.293 231.719 851.902V1004.54C231.719 1032.16 254.105 1054.54 281.719 1054.54H797.373C824.987 1054.54 847.373 1032.16 847.373 1004.54V851.902C848.571 721.845 856.562 686.107 894.516 633.605C949.849 556.05 1076.69 596.762 1076.69 596.762C1076.69 596.762 1018.77 490.653 903.904 518.101ZM379.939 316.936C398.317 346.78 405.309 414.756 405.908 505.206C369.411 418.977 338.265 330.901 312.621 241.408C340.335 262.157 363.23 287.845 379.939 316.936Z"
        fill="#3AAC8A"
      />
    </SvgIcon>
  );
};

export default Logo;
