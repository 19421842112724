import { HttpError, httpGet, httpPost } from "../http";
import {
  ImportedManure,
  ImportedManureData,
} from "../steps/ImportedManureStep";

export interface ImportedManureDTO {
  importedManure: ImportedManure[];
}

export default class ImportedManureClient {
  constructor(private kApiEndpoint: string) {}

  public async getImportedManure(
    guid?: string
  ): Promise<ImportedManureData | undefined> {
    if (!guid) {
      return undefined;
    }
    try {
      const response = await httpGet<ImportedManureDTO>(
        `${this.kApiEndpoint}/v1/importedManure/${guid}`
      );
      return {
        importedManure: response.importedManure,
      };
    } catch (e: any) {
      if (e instanceof HttpError) {
        if (e.originalResponse.status === 404) {
          return undefined;
        }
      }
      throw e;
    }
  }

  public async createImportedManure(
    questionnaireGuid: string,
    importedManureData: ImportedManureData
  ): Promise<string> {
    interface CreateImportedManureRequestDTO extends ImportedManureDTO {
      questionnaireGuid: string;
    }

    interface CreateImportedManureResponseDTO extends ImportedManureDTO {
      guid: string;
    }

    const response = await httpPost<
      CreateImportedManureRequestDTO,
      CreateImportedManureResponseDTO
    >(`${this.kApiEndpoint}/v1/importedManure`, {
      questionnaireGuid: questionnaireGuid,
      importedManure: importedManureData.importedManure,
    });
    return response.guid;
  }
}
