import React, { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import {
  useStore,
  onContactInformationFormCompleted,
  onContactInformationFormCancelled,
  onLandFormCompleted,
  onLandFormCancelled,
  onAnimalTypeFormNextStep,
  onAnimalTypeFormPreviousStep,
  onQuestionnaireGuidChanged,
  onPastureManagementFormCompleted,
  onPastureManagementFormCancelled,
  onSyntheticFertlizerUsageFormNextStep,
  onSyntheticFertlizerUsageFormPreviousStep,
  onManureManagementFormNextStep,
  onManureManagementFormPreviousStep,
  onFarmAnimalTypeDetailsFormNextStep,
  onFarmAnimalTypeDetailsFormPreviousStep,
  onFuelConsumptionFormNextStep,
  onFuelConsumptionFormPreviousStep,
  onFeedUsageFormNextStep,
  onFeedUsageFormPreviousStep,
  onImportedManureAnimalTypeFormNextStep,
  onImportedManureAnimalTypeFormPreviousStep,
  onImportedManureFormNextStep,
  onImportedManureFormPreviousStep,
  onOrganicFertlizerUsageFormNextStep,
  onOrganicFertlizerUsageFormPreviousStep,
  isQuestionnaireCompleted,
  Store,
  verifyQuestionnaireCompleted,
  onAdditionalInfoFormNextStep,
  onAdditionalInfoFormPreviousStep,
} from "./store";

import Intro from "./intro";
import Outro from "./outro";

import Spacer from "./spacer";
import { useIsMobileLayout } from "./hooks";

import ContactInformationForm from "./contactInformationForm";
import LandForm from "./farmlandForm";
import AnimalTypeForm from "./animalTypesForm";

import { HttpError } from "./http";
import PastureManagementForm from "./pastureManagementTab/pastureManagementForm";
import SyntheticFertilizerUsageStep from "./steps/SyntheticFertilizerUsageStep";
import ManureManagementStep from "./steps/ManureManagementStep";
import FarmAnimalTypeDetailsStep from "./steps/FarmAnimalTypeDetailsStep";
import FuelConsumptionStep from "./steps/FuelConsumptionStep";
import FeedUsageStep from "./steps/FeedUsageStep";
import ImportedManureAnimalTypeForm from "./importedManureAnimalTypesForm";
import ImportedManureStep from "./steps/ImportedManureStep";
import OrganicFertiliserStep from "./steps/TotalAppliedOrganicFertiliser";
import { CountryCode } from "./components/CountryDropdown";
import { logRocket, mixpanel, MixpanelEvents } from "./common/analytics";
import { translate } from "./common/locales/polyglot";
import AdditionalInfoStep from "./steps/AdditionalInfoStep";

export interface MainProps {
  // empty
}

function timeStepEvents() {
  mixpanel.time_event(MixpanelEvents.QUESTIONNAIRE_NEXT_STEP); // timing the next step
  mixpanel.time_event(MixpanelEvents.QUESTIONNAIRE_PREVIOUS_STEP); // timing the previous step
}

const Main = (props: MainProps) => {
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const isMobileLayout = useIsMobileLayout();
  const [activeStep, setActiveStep] = useState(-1);

  const nextStep = () => {
    mixpanel.track(MixpanelEvents.QUESTIONNAIRE_NEXT_STEP, {
      activeStep: activeStep + 1,
    });
    timeStepEvents();
    setActiveStep((v) => v + 1);
  };
  const prevStep = () => {
    mixpanel.track(MixpanelEvents.QUESTIONNAIRE_PREVIOUS_STEP, {
      activeStep: Math.max(-1, activeStep - 1),
    });
    setActiveStep((v) => Math.max(-1, v - 1));
  };

  const onStartForms = () => {
    mixpanel.track(MixpanelEvents.QUESTIONNAIRE_NEXT_STEP, { activeStep: 0 });
    timeStepEvents();

    mixpanel.track(MixpanelEvents.QUESTIONNAIRE_FORM_STARTED);
    mixpanel.time_event(MixpanelEvents.QUESTIONNAIRE_FORM_COMPLETED); // timing the whole form duration
    setActiveStep(0);
  };

  useEffect(() => {
    // We need this to make stepper scroll to the top of form step when user goes to the next step
    // Otherwise users need to scroll up to get to the beginning of the next step
    const children = Array.prototype.slice.call(
      stepperRef.current?.children || []
    );
    const steps = children.filter(
      (el) => el.classList.contains("MuiStep-root") // TODO: make sure classname is not mangled during the production build step
    );

    const stepElement = steps[activeStep];
    if (stepElement) {
      setTimeout(
        () =>
          stepElement.scrollIntoView({
            block: "start",
            inline: "nearest",
            behavior: "smooth",
          }),
        510
      );
    }
  }, [activeStep]);

  // The following allows us to step the user interface forward or
  // backward, and call the wrapped function e.g. for setting state

  const wrapNextStep = (fn: Function) => {
    return (...args: any[]) => {
      nextStep();
      fn(...args);
      verifyQuestionnaireCompleted();
    };
  };

  const wrapPrevStep = (fn: Function) => {
    return (...args: any[]) => {
      fn(...args);
      prevStep();
    };
  };

  const location = useLocation();
  const { questionnaireId } = useParams();
  const navigate = useNavigate();

  const [disableStartButton, setDisableStartButton] = useState(true);
  const loadingText = "Loading...";
  const startText = "Start";
  const [startButtonText, setStartButtonText] = useState(loadingText);
  useEffect(() => {
    setDisableStartButton(true); // wait with start until we actually have the data
    setActiveStep(-1); // for now we alwasy start from the beginning
    setStartButtonText(loadingText);
    // get questionnaire object from backend and fill into state.
    (async () => {
      if (!questionnaireId) {
        navigate(`/unknown-error`);
      } else {
        try {
          mixpanel.identify(questionnaireId); // tracking by questionnaireId, not by actual user. Current assumption: it doesn't matter who filled the form, only that the form was filled.
          logRocket.identify(questionnaireId);
          mixpanel.register({ questionnaireId });
          mixpanel.track(MixpanelEvents.LOAD_QUESTIONNAIRE);
          await onQuestionnaireGuidChanged(questionnaireId);
          setDisableStartButton(false); // enable start button now that we have the data
          setStartButtonText(startText);
          mixpanel.track(MixpanelEvents.OPEN_INTRO_PAGE, {
            activeStep: -1,
          });
        } catch (e) {
          if (e instanceof HttpError) {
            if (e.originalResponse.status === 404) {
              navigate(`/link-not-valid`);
              return;
            }
          }
          navigate(`/unknown-error`);
        }
      }
    })();
  }, [location, questionnaireId, navigate]);

  const questionnaire = useStore((state) => state.questionnaire);
  const questionnaireConfig = useStore((state) => state.questionnaireConfig);
  const yearStart = questionnaireConfig?.QUESTIONNAIRE_YEARS_RANGE.start || 0;
  const yearEnd = questionnaireConfig?.QUESTIONNAIRE_YEARS_RANGE.end || 0;

  const years = Array(yearEnd - yearStart + 1)
    .fill(1)
    .map((_, y) => yearStart + y); // [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027];

  type ContactInformationValues =
    | "name"
    | "mail"
    | "phone"
    | "countryCode"
    | "streetLine1"
    | "streetLine2"
    | "city"
    | "country"
    | "postCode";
  const getDefaultForContactForm = (valueName: ContactInformationValues) => {
    const value = questionnaire?.contactInformation?.[valueName];
    return value ?? "";
  };

  const getDefaultForInfoForm = () => {
    const value = questionnaire?.additionalInfoData?.data;
    return value ?? "";
  };
  const defaultCountry = questionnaire?.contactInformation?.country || null;
  type LandFormValues =
    | "allPasture"
    | "rentedPasture"
    | "allPastureIncludedInRuumiProgram"
    | "includeRentedPasture"
    | "unmanageablePasture";

  const getDefaultForLandForm = (
    valueName: LandFormValues,
    defaultValueIfNotStored: boolean | string
  ) => {
    const storedValue = questionnaire?.farmlandData?.[valueName];
    return storedValue ?? defaultValueIfNotStored;
  };

  type PastureManagementFormValues =
    | "isFarmOrganic"
    | "yearsOrganic"
    | "hasTemporaryPasture"
    | "areaTemporaryPasture"
    | "yearsInPasture"
    | "yearsInArable"
    | "areaMultiSpeciesSward"
    | "hasRotationalGrazingImplemented"
    | "rotationalGrazingArea"
    | "rotationalGrazingDaysMovement"
    | "hasAreaWithSoilLessThan30cmDeep"
    | "areaWithSoilLessThan30cmDeep"
    | "depthOfSoilLessThan30cmDeep";

  const getDefaultForPastureManagementForm = (
    valueName: PastureManagementFormValues,
    defaultValueIfNotStored: boolean | string
  ) => {
    const storedValue = questionnaire?.pastureManagementData?.[valueName];
    return storedValue ?? defaultValueIfNotStored;
  };

  return (
    <Box component="main">
      {activeStep === -1 && (
        <Intro
          onStartForms={onStartForms}
          disableStartButton={disableStartButton}
          startButtonText={startButtonText}
          yearStart={yearStart}
          yearEnd={yearEnd}
        />
      )}

      <Stepper activeStep={activeStep} orientation="vertical" ref={stepperRef}>
        <Step key="step-1">
          <StepLabel>{translate("mainSteps.personalDetails")}</StepLabel>
          <StepContent
            sx={{ pl: isMobileLayout ? 2 : 4, pt: isMobileLayout ? 1 : 2 }}
          >
            <ContactInformationForm
              onCompleted={wrapNextStep(onContactInformationFormCompleted)}
              onCancelled={wrapPrevStep(onContactInformationFormCancelled)}
              defaultName={getDefaultForContactForm("name")}
              defaultMail={getDefaultForContactForm("mail")}
              defaultPhone={getDefaultForContactForm("phone")}
              defaultCountryCode={getDefaultForContactForm("countryCode")}
              defaultStreetLine1={getDefaultForContactForm("streetLine1")}
              defaultStreetLine2={getDefaultForContactForm("streetLine2")}
              defaultCity={getDefaultForContactForm("city")}
              defaultCountry={
                getDefaultForContactForm("country") as CountryCode
              }
              defaultPostCode={getDefaultForContactForm("postCode")}
            />
          </StepContent>
        </Step>

        <Step key="step-2">
          <StepLabel>{translate("mainSteps.landDetails")}</StepLabel>
          <StepContent
            sx={{ pl: isMobileLayout ? 2 : 4, pt: isMobileLayout ? 1 : 2 }}
          >
            <LandForm
              yearStart={yearStart}
              yearEnd={yearEnd}
              onCompleted={wrapNextStep(onLandFormCompleted)}
              onCancelled={wrapPrevStep(onLandFormCancelled)}
              defaultAllPasture={getDefaultForLandForm(
                "allPasture",
                ""
              ).toString()}
              defaultRentedPasture={getDefaultForLandForm(
                "rentedPasture",
                ""
              ).toString()}
              defaultAllPastureIncludedInRuumiProgram={getDefaultForLandForm(
                "allPastureIncludedInRuumiProgram",
                ""
              ).toString()}
              defaultIncludeRentedPasture={
                getDefaultForLandForm("includeRentedPasture", true) === true
              }
              defaultUnmanageablePasture={getDefaultForLandForm(
                "unmanageablePasture",
                ""
              ).toString()}
            />
          </StepContent>
        </Step>
        <Step key="step-3">
          <StepLabel>{translate("mainSteps.pastureDetails")}</StepLabel>
          <StepContent
            sx={{ pl: isMobileLayout ? 2 : 4, pt: isMobileLayout ? 1 : 2 }}
          >
            <PastureManagementForm
              yearStart={yearStart}
              yearEnd={yearEnd}
              onCompleted={wrapNextStep(onPastureManagementFormCompleted)}
              onCancelled={wrapPrevStep(onPastureManagementFormCancelled)}
              defaultIsFarmOrganic={
                getDefaultForPastureManagementForm("isFarmOrganic", false) ===
                true
              }
              defaultYearsOrganic={getDefaultForPastureManagementForm(
                "yearsOrganic",
                ""
              ).toString()}
              defaultHasTemporaryPasture={
                getDefaultForPastureManagementForm(
                  "hasTemporaryPasture",
                  false
                ) === true
              }
              defaultAreaTemporaryPasture={getDefaultForPastureManagementForm(
                "areaTemporaryPasture",
                ""
              ).toString()}
              defaultYearsInPasture={getDefaultForPastureManagementForm(
                "yearsInPasture",
                ""
              ).toString()}
              defaultYearsInArable={getDefaultForPastureManagementForm(
                "yearsInArable",
                ""
              ).toString()}
              defaultAreaMultiSpeciesSward={getDefaultForPastureManagementForm(
                "areaMultiSpeciesSward",
                ""
              ).toString()}
              defaultHasRotationalGrazingImplemented={
                getDefaultForPastureManagementForm(
                  "hasRotationalGrazingImplemented",
                  false
                ) === true
              }
              defaultRotationalGrazingArea={getDefaultForPastureManagementForm(
                "rotationalGrazingArea",
                ""
              ).toString()}
              defaultRotationalGrazingDaysMovement={getDefaultForPastureManagementForm(
                "rotationalGrazingDaysMovement",
                ""
              ).toString()}
              defaultHasAreaWithSoilLessThan30cmDeep={
                getDefaultForPastureManagementForm(
                  "hasAreaWithSoilLessThan30cmDeep",
                  false
                ) === true
              }
              defaultAreaWithSoilLessThan30cmDeep={getDefaultForPastureManagementForm(
                "areaWithSoilLessThan30cmDeep",
                ""
              ).toString()}
              defaultDepthOfSoilLessThan30cmDeep={getDefaultForPastureManagementForm(
                "depthOfSoilLessThan30cmDeep",
                ""
              ).toString()}
            />
          </StepContent>
        </Step>
        <Step key="step-4">
          <StepLabel>{translate("mainSteps.animalTypes")}</StepLabel>
          <StepContent
            sx={{ pl: isMobileLayout ? 2 : 4, pt: isMobileLayout ? 1 : 2 }}
          >
            <AnimalTypeForm
              yearStart={yearStart}
              yearEnd={yearEnd}
              availableAnimalTypes={
                questionnaireConfig?.AVAILABLE_ANIMAL_TYPES || []
              }
              selectedAnimalTypes={
                questionnaire?.farmSelectedAnimalTypesData || {
                  animalTypes: [],
                }
              }
              onCompleted={wrapNextStep(onAnimalTypeFormNextStep)}
              onCancelled={wrapPrevStep(onAnimalTypeFormPreviousStep)}
            />
          </StepContent>
        </Step>

        <Step key="step-5">
          <StepLabel>{translate("mainSteps.animalDetails")}</StepLabel>
          <StepContent
            sx={{ pl: isMobileLayout ? 2 : 4, pt: isMobileLayout ? 1 : 2 }}
          >
            <FarmAnimalTypeDetailsStep
              onNextStep={wrapNextStep(onFarmAnimalTypeDetailsFormNextStep)}
              onPrevStep={wrapPrevStep(onFarmAnimalTypeDetailsFormPreviousStep)}
              data={questionnaire?.farmAnimalTypeDetailsData || { details: [] }}
              yearStart={yearStart}
              yearEnd={yearEnd}
              years={years}
            />
          </StepContent>
        </Step>
        <Step key="step-6">
          <StepLabel>{translate("mainSteps.manureTypes")}</StepLabel>
          <StepContent
            sx={{ pl: isMobileLayout ? 2 : 4, pt: isMobileLayout ? 1 : 2 }}
          >
            <ImportedManureAnimalTypeForm
              availableImportedManureAnimalTypes={
                questionnaireConfig?.AVAILABLE_IMPORTED_MANURE_ANIMAL_TYPES ||
                []
              }
              selectedImportedManureAnimalTypes={
                questionnaire?.selectedImportedManureAnimalTypesData || {
                  selectedImportedManureAnimalTypes: [],
                }
              }
              onCompleted={wrapNextStep(onImportedManureAnimalTypeFormNextStep)}
              onCancelled={wrapPrevStep(
                onImportedManureAnimalTypeFormPreviousStep
              )}
            />
          </StepContent>
        </Step>
        <Step key="step-7">
          <StepLabel>{translate("mainSteps.importedManure")}</StepLabel>
          <StepContent
            sx={{ pl: isMobileLayout ? 2 : 4, pt: isMobileLayout ? 1 : 2 }}
          >
            <ImportedManureStep
              onNextStep={wrapNextStep(onImportedManureFormNextStep)}
              onPrevStep={wrapPrevStep(onImportedManureFormPreviousStep)}
              importedManureData={
                questionnaire?.importedManureData || {
                  importedManure: [],
                }
              }
              yearStart={yearStart}
              yearEnd={yearEnd}
              years={years}
            />
          </StepContent>
        </Step>
        <Step key="step-8">
          <StepLabel>{translate("mainSteps.manureManagement")}</StepLabel>
          <StepContent
            sx={{ pl: isMobileLayout ? 2 : 4, pt: isMobileLayout ? 1 : 2 }}
          >
            <ManureManagementStep
              onNextStep={wrapNextStep(onManureManagementFormNextStep)}
              onPrevStep={wrapPrevStep(onManureManagementFormPreviousStep)}
              yearStart={yearStart}
              yearEnd={yearEnd}
              years={years}
              defaultValues={{
                manureManagement:
                  questionnaire?.manureManagementData?.manureManagement || [],
              }}
            />
          </StepContent>
        </Step>

        <Step key="step-9">
          <StepLabel>{translate("mainSteps.appliedOrganic")}</StepLabel>
          <StepContent
            sx={{ pl: isMobileLayout ? 2 : 4, pt: isMobileLayout ? 1 : 2 }}
          >
            <OrganicFertiliserStep
              onNextStep={wrapNextStep(onOrganicFertlizerUsageFormNextStep)}
              onPrevStep={wrapPrevStep(onOrganicFertlizerUsageFormPreviousStep)}
              defaultValues={{
                organicFertiliser:
                  questionnaire?.organicFertiliserData?.organicFertiliser || [],
              }}
              yearStart={yearStart}
              yearEnd={yearEnd}
              years={years}
            />
          </StepContent>
        </Step>

        <Step key="step-10">
          <StepLabel>{translate("mainSteps.syntheticFertiliser")}</StepLabel>
          <StepContent
            sx={{ pl: isMobileLayout ? 2 : 4, pt: isMobileLayout ? 1 : 2 }}
          >
            <SyntheticFertilizerUsageStep
              onNextStep={wrapNextStep(onSyntheticFertlizerUsageFormNextStep)}
              onPrevStep={wrapPrevStep(
                onSyntheticFertlizerUsageFormPreviousStep
              )}
              data={
                questionnaire?.syntheticFertilizerUsageData || { products: [] }
              }
              yearStart={yearStart}
              yearEnd={yearEnd}
              years={years}
            />
          </StepContent>
        </Step>
        <Step key="step-11">
          <StepLabel>{translate("mainSteps.fuel")}</StepLabel>
          <StepContent
            sx={{ pl: isMobileLayout ? 2 : 4, pt: isMobileLayout ? 1 : 2 }}
          >
            <FuelConsumptionStep
              onNextStep={wrapNextStep(onFuelConsumptionFormNextStep)}
              onPrevStep={wrapPrevStep(onFuelConsumptionFormPreviousStep)}
              data={questionnaire?.fuelConsumptionData}
              yearStart={yearStart}
              yearEnd={yearEnd}
              years={years}
            />
          </StepContent>
        </Step>
        <Step key="step-12">
          <StepLabel>{translate("mainSteps.feed")}</StepLabel>
          <StepContent
            sx={{ pl: isMobileLayout ? 2 : 4, pt: isMobileLayout ? 1 : 2 }}
          >
            <FeedUsageStep
              onNextStep={wrapNextStep(onFeedUsageFormNextStep)}
              onPrevStep={wrapPrevStep(onFeedUsageFormPreviousStep)}
              data={questionnaire?.feedUsageData}
              yearStart={yearStart}
              yearEnd={yearEnd}
              years={years}
            />
          </StepContent>
        </Step>
        <Step key="step-13">
          <StepLabel>{translate("mainSteps.additionalInfo")}</StepLabel>
          <StepContent
            sx={{ pl: isMobileLayout ? 2 : 4, pt: isMobileLayout ? 1 : 2 }}
          >
            <AdditionalInfoStep
              onCompleted={wrapNextStep(onAdditionalInfoFormNextStep)}
              onCancelled={wrapPrevStep(onAdditionalInfoFormPreviousStep)}
              data={getDefaultForInfoForm()}
            />
          </StepContent>
        </Step>
      </Stepper>
      <Spacer height={8} />
      {activeStep === 13 && <Outro />}
    </Box>
  );
};

export default Main;
