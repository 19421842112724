import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Hero from "./hero";

import Spacer from "./spacer";
import { Title } from "./theme";
import { useIsMobileLayout } from "./hooks";
import { mixpanel, MixpanelEvents } from "./common/analytics";
import { translate } from "./common/locales/polyglot";

export interface StartpageProps {
  //
}

const Startpage = (props: StartpageProps) => {
  const isMobileLayout = useIsMobileLayout();

  useEffect(() => {
    mixpanel.track(MixpanelEvents.OPEN_START_PAGE);
  }, []);

  return (
    <Stack>
      <Title>{translate("startPage.title")}</Title>

      <Spacer height={8} />

      <Stack direction={isMobileLayout ? "column" : "row"} spacing={4}>
        <Hero sx={{ fontSize: isMobileLayout ? 256 : 256 }} />

        <Box>
          <Typography>{translate("startPage.description")}</Typography>

          <Spacer height={4} />

          <Typography>{translate("startPage.baseline")}</Typography>
          <Spacer height={4} />
          <Typography>{translate("startPage.enroll")}</Typography>

          <Spacer height={4} />

          <Typography>
            {translate("startPage.contact", {
              contact: <a href="mailto:hello@ruumi.io">hello@ruumi.io</a>,
            })}
          </Typography>
        </Box>
      </Stack>

      <Spacer height={8} />
    </Stack>
  );
};

export default Startpage;
