import styled from "@emotion/styled";

// The styles below are somewhat reflecting the defaults at
// https://mui.com/material-ui/customization/default-theme/
// with adaptions e.g. an uppercase title treatment. This
// seems to be easier for now than coming up with a theme.

export const Description = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: #4b5563;
`;

export const Title = styled.h2`
  margin: 0;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.08em;
  line-height: 1.75;
  color: #374151;
`;

export const HighlightedText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: #3aac8a;
`;

export const HighlightedSeparatorText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 39.46px;
  letter-spacing: 0.00938em;
  color: #374151;
`;

export const ErrorMessage = styled.span`
  color: #d32f2f;
`;
