import create from "zustand";
import produce from "immer";

// for store-derived data, make sure to cache with
//import { createSelector } from 'reselect';

import {
  postNotification,
  createQuestionnaireId,
  getQuestionnaire,
  createContactInformation,
  ContactInformation,
  AnimalTypes,
  FarmlandData,
  createFarmlandData,
  createPastureManagementData,
  syntheticFertilizerUsageClient,
  manureManagementClient,
  farmSelectedAnimalTypesClient,
  farmAnimalTypeDetailsClient,
  importedManureClient,
  getQuestionnaireConfig,
  fuelConsumptionClient,
  feedUsageClient,
  selectedImportedManureAnimalTypesClient,
  organicFertiliserClient,
  additionalInfoClient,
  Questionnaire,
} from "./api";
import { PastureManagementData } from "./pastureManagementTab/PastureManagementData";
import { SyntheticFertilizerUsageData } from "./steps/SyntheticFertilizerUsageStep";
import { ManureManagementData } from "./steps/ManureManagementStep";
import {
  AnimalType as AnimalTypeSelection,
  ImportedManureAnimalType,
  QuestionnaireConfig,
} from "./interfaces";
import { FarmSelectedAnimalTypesData } from "./animalTypesForm";
import { FarmAnimalTypeDetailsData } from "./steps/FarmAnimalTypeDetailsStep";
import { FuelConsumptionData } from "./steps/FuelConsumptionStep";
import { FeedUsageData } from "./steps/FeedUsageStep";
import { SelectedImportedManureAnimalTypesData } from "./importedManureAnimalTypesForm";
import { ImportedManureData } from "./steps/ImportedManureStep";
import {
  AppliedOrganicFertiliserData,
  AppliedOrganicFertiliserTypeData,
} from "./steps/TotalAppliedOrganicFertiliser";
import { mixpanel } from "./common/analytics";
import { AdditionalInfoData } from "./api/AdditionalInfoClient";

type AreaUnit = "hectare" | "acre" | "m2";

export interface Store {
  numFormResets: number;
  areaUnit: AreaUnit;
  questionnaireCompleted: boolean; // whether this form has all steps filled or not
  questionnaire?: {
    guid: string;
    contactInformation?: ContactInformation;
    animalTypes: string[];
    farmlandData: FarmlandData;
    pastureManagementData: PastureManagementData;
    syntheticFertilizerUsageData: SyntheticFertilizerUsageData;
    manureManagementData: ManureManagementData;
    farmSelectedAnimalTypesData: FarmSelectedAnimalTypesData;
    selectedImportedManureAnimalTypesData: SelectedImportedManureAnimalTypesData;
    farmAnimalTypeDetailsData: FarmAnimalTypeDetailsData;
    fuelConsumptionData: FuelConsumptionData;
    feedUsageData: FeedUsageData;
    importedManureData: ImportedManureData;
    organicFertiliserData: AppliedOrganicFertiliserData;
    additionalInfoData: AdditionalInfoData;
  };
  questionnaireConfig?: QuestionnaireConfig;
}

const initialStore: Store = {
  numFormResets: 0,
  areaUnit: "hectare",
  questionnaire: undefined,
  questionnaireConfig: undefined,
  questionnaireCompleted: false,
};

export const useStore = create<Store>((set) => initialStore);
export const resetStore = () => useStore.setState(initialStore);

export const isQuestionnaireCompleted = (questionnaire: Questionnaire) =>
  Object.values(questionnaire).reduce((acc, cur) => {
    return acc && !!cur;
  }, true); // questionnare completed when all steps are filled

export const verifyQuestionnaireCompleted = () => {
  const questionnaire = useStore.getState().questionnaire;
  if (questionnaire) {
    const questionnaireCompleted = isQuestionnaireCompleted(questionnaire);
    mixpanel.register({ questionnaireCompleted }); // tracking whether form has been completed or not

    useStore.setState(
      produce((state: Store) => {
        state.questionnaireCompleted = questionnaireCompleted;
      })
    );
  }
};

export const onQuestionnaireGuidChanged = async (questionnaireId: string) => {
  const questionnaireConfig = await getQuestionnaireConfig();
  const questionnaire = await getQuestionnaire(questionnaireId);
  const questionnaireCompleted = isQuestionnaireCompleted(questionnaire);
  mixpanel.register({ questionnaireCompleted }); // tracking whether form has been completed or not
  // If we get a new questionnaire guid, we reset the
  // store since we are then in a new "user session"
  await resetStore();

  useStore.setState(
    produce((state) => {
      state.questionnaireConfig = questionnaireConfig;
      state.questionnaire = questionnaire;
      state.questionnaireCompleted = questionnaireCompleted;
    })
  );
};

export const onContactInformationFormCompleted = async (
  contactInformation: ContactInformation
) => {
  const questionnaire = useStore.getState().questionnaire;

  if (questionnaire) {
    const questionnaireGuid = questionnaire.guid;
    createContactInformation(questionnaireGuid, contactInformation);
    useStore.setState(
      produce((state) => {
        state.questionnaire.contactInformation = contactInformation;
      })
    );
  }
};

export const onContactInformationFormCancelled = async () => {
  // example for sending to the backend
  const n = useStore.getState().numFormResets;

  if (n > 3 && n % 3 === 0) {
    await postNotification();
  }

  console.log(`ContactInformationForm Form Cancelled`);
};

export const onPastureManagementFormCompleted = async (
  pastureManagementData: PastureManagementData
) => {
  const questionnaire = useStore.getState().questionnaire;
  if (questionnaire) {
    const questionnaireGuid = questionnaire.guid;
    createPastureManagementData(questionnaireGuid, pastureManagementData);
    useStore.setState(
      produce((state) => {
        state.questionnaire.pastureManagementData = pastureManagementData;
      })
    );

    console.log(
      `Farm Form Completed with ${JSON.stringify(pastureManagementData)}`
    );
  }
};

export const onPastureManagementFormCancelled = async () => {
  console.log(`Pasture Management Form Cancelled`);
};

export const onLandFormCompleted = async (farmlandData: FarmlandData) => {
  const questionnaire = useStore.getState().questionnaire;
  if (questionnaire) {
    const questionnaireGuid = questionnaire.guid;
    createFarmlandData(questionnaireGuid, farmlandData);
    useStore.setState(
      produce((state) => {
        state.questionnaire.farmlandData = farmlandData;
      })
    );

    console.log(`Farm Form Completed with ${JSON.stringify(farmlandData)}`);
  }
};

export const onLandFormCancelled = async () => {
  console.log(`Farm Form Cancelled`);
};

export const onAnimalTypeFormNextStep = async (
  farmSelectedAnimalTypes: FarmSelectedAnimalTypesData
) => {
  await saveFarmSelectedAnimalTypesFormData(farmSelectedAnimalTypes);
  console.log(
    `Animal Type Form Completed with ${JSON.stringify(farmSelectedAnimalTypes)}`
  );
};

export const onAnimalTypeFormPreviousStep = async () => {
  console.log(`Animal Type Form Cancelled`);
};

export const onImportedManureAnimalTypeFormNextStep = async (
  selectedImportedManureAnimalTypesData: SelectedImportedManureAnimalTypesData
) => {
  await saveSelectedImportedManureAnimalTypesFormData(
    selectedImportedManureAnimalTypesData
  );
  console.log(
    `Imported manure Animal Type Form Completed with ${JSON.stringify(
      selectedImportedManureAnimalTypesData
    )}`
  );
};

export const onImportedManureAnimalTypeFormPreviousStep = async () => {
  console.log(`Imported manure animal Type Form Cancelled`);
};

export const onGenerateAppIdCompleted = async () => {
  return createQuestionnaireId();
};

async function saveSyntheticFertlizerUsageFormData(
  syntheticFertilizerUsageData: SyntheticFertilizerUsageData
) {
  const questionnaire = useStore.getState().questionnaire;
  if (questionnaire) {
    const questionnaireGuid = questionnaire.guid;
    syntheticFertilizerUsageClient.createSyntheticFertilizerUsageData(
      questionnaireGuid,
      syntheticFertilizerUsageData
    );
    useStore.setState(
      produce((state: Store) => {
        state.questionnaire!.syntheticFertilizerUsageData =
          syntheticFertilizerUsageData;
      })
    );

    console.log(
      `SyntheticFertlizerUsage Form saved with ${JSON.stringify(
        syntheticFertilizerUsageData
      )}`
    );
  }
}

async function saveOrganicFertlizerUsageFormData(
  organicFertiliserApplicationData: AppliedOrganicFertiliserTypeData[]
) {
  const questionnaire = useStore.getState().questionnaire;
  if (questionnaire) {
    const questionnaireGuid = questionnaire.guid;
    organicFertiliserClient.createOrganicFertiliserApplicationData(
      questionnaireGuid,
      organicFertiliserApplicationData
    );
    useStore.setState(
      produce((state: Store) => {
        if (state.questionnaire!.organicFertiliserData === undefined) {
          state.questionnaire!.organicFertiliserData = {
            organicFertiliser: [],
          };
        }
        state.questionnaire!.organicFertiliserData.organicFertiliser =
          organicFertiliserApplicationData;
      })
    );

    console.log(
      `SyntheticFertlizerUsage Form saved with ${JSON.stringify(
        organicFertiliserApplicationData
      )}`
    );
  }
}

async function saveManureManagementFormData(
  manureManagementData: ManureManagementData
) {
  const questionnaire = useStore.getState().questionnaire;
  if (questionnaire) {
    const questionnaireGuid = questionnaire.guid;
    manureManagementClient.createManureManagementData(
      questionnaireGuid,
      manureManagementData
    );
    useStore.setState(
      produce((state: Store) => {
        state.questionnaire!.manureManagementData = manureManagementData;
      })
    );
  }
}

export const onSyntheticFertlizerUsageFormNextStep = async (
  syntheticFertilizerUsageData: SyntheticFertilizerUsageData
) => {
  await saveSyntheticFertlizerUsageFormData(syntheticFertilizerUsageData);
  console.log(`SyntheticFertlizerUsage Form next step`);
};

export const onSyntheticFertlizerUsageFormPreviousStep = async (
  syntheticFertilizerUsageData: SyntheticFertilizerUsageData
) => {
  await saveSyntheticFertlizerUsageFormData(syntheticFertilizerUsageData);
  console.log(`SyntheticFertlizerUsage Form previous step`);
};

export const onOrganicFertlizerUsageFormNextStep = async (
  organicFertiliserApplicationData: AppliedOrganicFertiliserData
) => {
  await saveOrganicFertlizerUsageFormData(
    organicFertiliserApplicationData.organicFertiliser
  );
  console.log(`OrganicFertlizerUsage Form next step`);
};

export const onOrganicFertlizerUsageFormPreviousStep = async (
  organicFertiliserApplicationData: AppliedOrganicFertiliserData
) => {
  await saveOrganicFertlizerUsageFormData(
    organicFertiliserApplicationData.organicFertiliser
  );
  console.log(`OrganicFertlizerUsage Form previous step`);
};

export const onManureManagementFormNextStep = async (
  manureManagementData: ManureManagementData
) => {
  await saveManureManagementFormData(manureManagementData);
};

export const onManureManagementFormPreviousStep = async (
  manureManagementData: ManureManagementData
) => {
  await saveManureManagementFormData(manureManagementData);
};

async function saveFarmSelectedAnimalTypesFormData(
  farmSelectedAnimalTypesData: FarmSelectedAnimalTypesData
) {
  const questionnaire = useStore.getState().questionnaire;
  if (questionnaire) {
    const questionnaireGuid = questionnaire.guid;
    farmSelectedAnimalTypesClient.createFarmSelectedAnimalTypes(
      questionnaireGuid,
      farmSelectedAnimalTypesData
    );
    useStore.setState(
      produce((state: Store) => {
        state.questionnaire!.farmSelectedAnimalTypesData =
          farmSelectedAnimalTypesData;
      })
    );

    console.log(
      `farmSelectedAnimalTypesData Form saved with ${JSON.stringify(
        farmSelectedAnimalTypesData
      )}`
    );
  }
}

async function saveSelectedImportedManureAnimalTypesFormData(
  selectedImportedManureAnimalTypesData: SelectedImportedManureAnimalTypesData
) {
  const questionnaire = useStore.getState().questionnaire;
  if (questionnaire) {
    const questionnaireGuid = questionnaire.guid;
    selectedImportedManureAnimalTypesClient.createSelectedImportedManureAnimalTypes(
      questionnaireGuid,
      selectedImportedManureAnimalTypesData
    );
    useStore.setState(
      produce((state: Store) => {
        state.questionnaire!.selectedImportedManureAnimalTypesData =
          selectedImportedManureAnimalTypesData;
      })
    );

    console.log(
      `selectedImportedManureAnimalTypesData Form saved with ${JSON.stringify(
        selectedImportedManureAnimalTypesData
      )}`
    );
  }
}

export const onFarmAnimalTypeDetailsFormNextStep = async (
  farmAnimalTypeDetailsData: FarmAnimalTypeDetailsData
) => {
  await saveFarmAnimalTypeDetailsData(farmAnimalTypeDetailsData);
  console.log(`FarmAnimalTypeDetails Form next step`);
};

export const onFarmAnimalTypeDetailsFormPreviousStep = async (
  farmAnimalTypeDetailsData: FarmAnimalTypeDetailsData
) => {
  await saveFarmAnimalTypeDetailsData(farmAnimalTypeDetailsData);
  console.log(`FarmAnimalTypeDetails Form previous step`);
};

async function saveFarmAnimalTypeDetailsData(
  farmAnimalTypeDetailsData: FarmAnimalTypeDetailsData
) {
  const questionnaire = useStore.getState().questionnaire;
  if (questionnaire) {
    const questionnaireGuid = questionnaire.guid;
    farmAnimalTypeDetailsClient.createFarmAnimalTypeDetails(
      questionnaireGuid,
      farmAnimalTypeDetailsData
    );
    useStore.setState(
      produce((state: Store) => {
        state.questionnaire!.farmAnimalTypeDetailsData =
          farmAnimalTypeDetailsData;
      })
    );

    console.log(
      `farmAnimalTypeDetailsData Form saved with ${JSON.stringify(
        farmAnimalTypeDetailsData
      )}`
    );
  }
}

export const onFuelConsumptionFormNextStep = async (
  fuelConsumptionData: FuelConsumptionData
) => {
  await saveFuelConsumptionData(fuelConsumptionData);
  console.log(`FuelConsumption Form next step`);
};

export const onFuelConsumptionFormPreviousStep = async (
  farmAnimalTypeDetailsData: FuelConsumptionData
) => {
  await saveFuelConsumptionData(farmAnimalTypeDetailsData);
  console.log(`FuelConsumption Form previous step`);
};

async function saveFuelConsumptionData(
  fuelConsumptionData: FuelConsumptionData
) {
  const questionnaire = useStore.getState().questionnaire;
  if (questionnaire) {
    const questionnaireGuid = questionnaire.guid;
    fuelConsumptionClient.createFuelConsumption(
      questionnaireGuid,
      fuelConsumptionData
    );
    useStore.setState(
      produce((state: Store) => {
        state.questionnaire!.fuelConsumptionData = fuelConsumptionData;
      })
    );

    console.log(
      `FuelConsumption Form saved with ${JSON.stringify(fuelConsumptionData)}`
    );
  }
}
export const onFeedUsageFormNextStep = async (feedUsageData: FeedUsageData) => {
  await saveFeedUsageData(feedUsageData);
  console.log(`FeedUsage Form next step`);
};

export const onFeedUsageFormPreviousStep = async (
  feedUsageData: FeedUsageData
) => {
  await saveFeedUsageData(feedUsageData);
  console.log(`FeedUsage Form previous step`);
};

async function saveFeedUsageData(feedUsageData: FeedUsageData) {
  const questionnaire = useStore.getState().questionnaire;
  if (questionnaire) {
    const questionnaireGuid = questionnaire.guid;
    feedUsageClient.createFeedUsage(questionnaireGuid, feedUsageData);
    useStore.setState(
      produce((state: Store) => {
        state.questionnaire!.feedUsageData = feedUsageData;
      })
    );

    console.log(`FeedUsage Form saved with ${JSON.stringify(feedUsageData)}`);
  }
}

export const onImportedManureFormNextStep = async (
  importedManureData: ImportedManureData
) => {
  await saveImportedManureData(importedManureData);
  console.log(`importedManureData Form next step`);
};

export const onImportedManureFormPreviousStep = async (
  importedManureData: ImportedManureData
) => {
  await saveImportedManureData(importedManureData);
  console.log(`importedManureData Form previous step`);
};

async function saveImportedManureData(importedManureData: ImportedManureData) {
  const questionnaire = useStore.getState().questionnaire;
  if (questionnaire) {
    const questionnaireGuid = questionnaire.guid;
    importedManureClient.createImportedManure(
      questionnaireGuid,
      importedManureData
    );
    useStore.setState(
      produce((state: Store) => {
        state.questionnaire!.importedManureData = importedManureData;
      })
    );

    console.log(
      `importedManureData Form saved with ${JSON.stringify(importedManureData)}`
    );
  }
}

export const onAdditionalInfoFormNextStep = async (
  additionalInfoData: AdditionalInfoData
) => {
  await saveAdditionalInfoData(additionalInfoData);
  console.log(`Additional Info Form next step`);
};

export const onAdditionalInfoFormPreviousStep = async (
  additionalInfoData: AdditionalInfoData
) => {
  await saveAdditionalInfoData(additionalInfoData);
  console.log(`Additional Info Form previous step`);
};

async function saveAdditionalInfoData(additionalInfoData: AdditionalInfoData) {
  const questionnaire = useStore.getState().questionnaire;
  if (questionnaire) {
    const questionnaireGuid = questionnaire.guid;
    additionalInfoClient.createAdditionalInfo(
      questionnaireGuid,
      additionalInfoData
    );
    useStore.setState(
      produce((state: Store) => {
        state.questionnaire!.additionalInfoData = additionalInfoData;
      })
    );

    console.log(
      `Additional Info Form saved with ${JSON.stringify(additionalInfoData)}`
    );
  }
}
