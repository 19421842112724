import { Box, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import YearlyQuestionnaire from "../../components/YearlyQuestionnaire";
import {
  PartialFormData,
  HTMLInputTypeAttribute,
  YearlyData,
} from "../../components/YearlyQuestionnaire/interfaces";
import BaseStepComponent from "../../components/BaseStepComponent";
import { getYearlyValue, mergeYearlyData } from "../../util";
import { HighlightedText } from "../../theme";
import { translate } from "../../common/locales/polyglot";

interface FeedUsageStepProps {
  data?: FeedUsageData;
  onPrevStep(data: FeedUsageData): void;
  onNextStep(data: FeedUsageData): void;
  yearStart: number;
  yearEnd: number;
  years: number[];
}

export interface FeedUsageData {
  concentrateFeedTonnesPerYear: number;
  grainTonnesPerYear: number;
  yearlyChangesData?:
    | {
        concentrateFeedTonnesPerYear: YearlyData[];
        grainTonnesPerYear: YearlyData[];
      }
    | undefined;
  yearlyChangesSwitchValue: boolean | undefined;
}

function feedUsageFormSectionGenerator(
  index: number,
  yearStart: number,
  yearEnd: number,
  years: number[],
  data?: FeedUsageData
) {
  return {
    name: `feed-usage-${index}`,
    title: ``,
    yearlyChangesSwitch: {
      label: "feedUsageStep.yearlyChangesSwitch.label",
      value: data?.yearlyChangesSwitchValue || false,
    },
    questions: [
      {
        name: "concentrate-tonnes-per-year",
        value: data?.concentrateFeedTonnesPerYear || 0,
        label: translate(
          "feedUsageStep.questions.concentrateTonnesPerYear.label"
        ),
        type: "number" as HTMLInputTypeAttribute,
        required: true,
        validation: { required: true, min: 0 },
        yearlyChangesData: mergeYearlyData(
          years,
          data?.yearlyChangesData?.concentrateFeedTonnesPerYear ?? []
        ),
      },
      {
        name: "grain-tonnes-per-year",
        label: translate("feedUsageStep.questions.grainTonnesPerYear.label"),
        type: "number" as HTMLInputTypeAttribute,
        required: true,
        value: data?.grainTonnesPerYear || 0,
        validation: { required: true, min: 0 },
        yearlyChangesData: mergeYearlyData(
          years,
          data?.yearlyChangesData?.grainTonnesPerYear ?? []
        ),
      },
    ],
  };
}

function formDataToFeedUsageData(data: PartialFormData): FeedUsageData {
  const { feedUsageForm } = data;

  const feedUsageData = feedUsageForm.sections.map(
    (item): FeedUsageData => ({
      concentrateFeedTonnesPerYear: item.questions[0].value as number,
      grainTonnesPerYear: item.questions[1].value as number,
      yearlyChangesData: {
        concentrateFeedTonnesPerYear:
          item.questions[0].yearlyChangesData?.map(getYearlyValue) || [],
        grainTonnesPerYear:
          item.questions[1].yearlyChangesData?.map(getYearlyValue) || [],
      },
      yearlyChangesSwitchValue: item.yearlyChangesSwitch
        ? item.yearlyChangesSwitch.value
        : false,
    })
  );

  return feedUsageData[0];
}

const FeedUsageStep = (props: FeedUsageStepProps) => {
  const { data } = props;
  const { yearStart, yearEnd, years } = props;

  const methods = useForm<PartialFormData>({
    mode: "onBlur",
    defaultValues: {
      feedUsageForm: {
        sections: [
          feedUsageFormSectionGenerator(0, yearStart, yearEnd, years, data),
        ],
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <form noValidate>
        <BaseStepComponent
          title={translate("feedUsageStep.title")}
          description={translate("feedUsageStep.description")}
          onPrevStep={(data: PartialFormData) => {
            props.onPrevStep(formDataToFeedUsageData(data));
          }}
          onNextStep={(data: PartialFormData) => {
            props.onNextStep(formDataToFeedUsageData(data));
          }}
        >
          <Stack spacing={4}>
            <HighlightedText>
              All questions apply from {yearStart} - {yearEnd}
            </HighlightedText>
          </Stack>
          <Box>
            <YearlyQuestionnaire
              years={props.years}
              sectionsEditable={false}
              baseFormPath={"feedUsageForm"}
            />
          </Box>
        </BaseStepComponent>
      </form>
    </FormProvider>
  );
};

export default FeedUsageStep;
