import { DeepPartial, Path, useFormContext } from "react-hook-form";
import { onWheelOverride } from "../../util";
import SwitchElement from "../inputs/SwitchElement";
import TextFieldElement from "../inputs/TextFieldElement";
import { PartialFormData, QuestionFormData } from "./interfaces";

export interface QuestionItemProps {
  question: DeepPartial<QuestionFormData>;
  questionIndex: number;
  sectionIndex: number;
  sectionName: string;
  baseFormPath: Path<PartialFormData>;
  yearlyChangesEnabled: boolean | undefined;
}

function QuestionItem({
  question,
  questionIndex,
  sectionIndex,
  baseFormPath,
  yearlyChangesEnabled,
}: QuestionItemProps) {
  const { control, formState, clearErrors } = useFormContext<PartialFormData>();
  const errors = formState.errors?.[baseFormPath]?.sections?.[sectionIndex];

  const shouldShowInput = // when to show field input
    !question.yearlyChangesData || // always show for questions that don't have yearly changes data
    ((!question.questionEnabledSwitch ||
      question.questionEnabledSwitch.value) && // either without "do you use it" switch or with this switch enabled
      !yearlyChangesEnabled); // and when yearly changes section is hidden

  const error = errors?.questions?.[questionIndex]?.value;

  return (
    <>
      {question.questionEnabledSwitch && (
        <SwitchElement
          label={question.questionEnabledSwitch.label}
          labelPlacement="start"
          name={`${baseFormPath}.sections.${sectionIndex}.questions.${questionIndex}.questionEnabledSwitch.value`}
          control={control}
          onChange={() => {
            {
              clearErrors(`${baseFormPath}.sections.${sectionIndex}`);
            }
          }}
        />
      )}

      {shouldShowInput && (
        <TextFieldElement
          inputMode="text"
          variant="filled"
          fullWidth
          type={question.type || "text"}
          label={question.label}
          control={control}
          helperText={error?.message || ""}
          onWheel={onWheelOverride}
          error={!!error}
          name={`${baseFormPath}.sections.${sectionIndex}.questions.${questionIndex}.value`}
          required={question.required}
          validation={question.validation as any}
          onChange={() => {
            clearErrors([
              `${baseFormPath}.sections.${sectionIndex}.questions.${questionIndex}.value`,
              `${baseFormPath}.sections.${sectionIndex}`,
            ]);
          }}
        />
      )}
    </>
  );
}

export default QuestionItem;
