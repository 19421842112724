import React, { useState } from "react";

import Stack from "@mui/material/Stack";

import { useForm } from "react-hook-form";

import BaseForm from "../baseForm";
import { HighlightedSeparatorText, HighlightedText } from "../theme";

import { useStore } from "../store";
import { PastureManagementSwitch } from "./pastureManagementSwitch";
import { PastureManagementFormProps } from "./PastureManagementFormProps";
import { PastureManagementFormData } from "./PastureManagementFormData";
import { NumberInputField } from "./numberInputField";
import { FieldUsageInputField } from "./fieldUsageInputField";
import { translate } from "../common/locales/polyglot";

const PastureManagementForm = (props: PastureManagementFormProps) => {
  const { reset, control, handleSubmit } = useForm<PastureManagementFormData>({
    mode: "onChange",
  });

  const onSubmit = (pastureManagementFormData: PastureManagementFormData) => {
    const yearsOrganic = !pastureManagementFormData.isFarmOrganic
      ? 0
      : Number.parseFloat(pastureManagementFormData.yearsOrganic || "0");
    const areaTemporaryPasture = !pastureManagementFormData.hasTemporaryPasture
      ? 0
      : Number.parseFloat(
          pastureManagementFormData.areaTemporaryPasture || "0"
        );
    const yearsInPasture = !pastureManagementFormData.hasTemporaryPasture
      ? 0
      : Number.parseFloat(pastureManagementFormData.yearsInPasture || "0");
    const yearsInArable = !pastureManagementFormData.hasTemporaryPasture
      ? 0
      : Number.parseFloat(pastureManagementFormData.yearsInArable || "0");
    const areaMultiSpeciesSward = Number.parseFloat(
      pastureManagementFormData.areaMultiSpeciesSward || "0"
    );
    const rotationalGrazingArea =
      !pastureManagementFormData.hasRotationalGrazingImplemented
        ? 0
        : Number.parseFloat(
            pastureManagementFormData.rotationalGrazingArea || "0"
          );
    const rotationalGrazingDaysMovement =
      !pastureManagementFormData.hasRotationalGrazingImplemented
        ? 0
        : Number.parseFloat(
            pastureManagementFormData.rotationalGrazingDaysMovement || "0"
          );
    const areaWithSoilLessThan30cmDeep =
      !pastureManagementFormData.hasAreaWithSoilLessThan30cmDeep
        ? 0
        : Number.parseFloat(
            pastureManagementFormData.areaWithSoilLessThan30cmDeep || "0"
          );
    const depthOfSoilLessThan30cmDeep =
      !pastureManagementFormData.hasAreaWithSoilLessThan30cmDeep
        ? 0
        : Number.parseFloat(
            pastureManagementFormData.depthOfSoilLessThan30cmDeep || "0"
          );
    props.onCompleted({
      isFarmOrganic: pastureManagementFormData.isFarmOrganic,
      yearsOrganic: yearsOrganic,
      hasTemporaryPasture: pastureManagementFormData.hasTemporaryPasture,
      areaTemporaryPasture: areaTemporaryPasture,
      yearsInPasture: yearsInPasture,
      yearsInArable: yearsInArable,
      areaMultiSpeciesSward: areaMultiSpeciesSward,
      hasRotationalGrazingImplemented:
        pastureManagementFormData.hasRotationalGrazingImplemented,
      rotationalGrazingArea: rotationalGrazingArea,
      rotationalGrazingDaysMovement: rotationalGrazingDaysMovement,
      hasAreaWithSoilLessThan30cmDeep:
        pastureManagementFormData.hasAreaWithSoilLessThan30cmDeep,
      areaWithSoilLessThan30cmDeep: areaWithSoilLessThan30cmDeep,
      depthOfSoilLessThan30cmDeep: depthOfSoilLessThan30cmDeep,
    });
  };

  const onReset = () => {
    props.onCancelled();
    reset();
  };

  const questionnaire = useStore((state) => state.questionnaire);
  const unit = useStore((state) => state.areaUnit);

  const availableLand: number =
    questionnaire?.farmlandData?.allPastureIncludedInRuumiProgram ?? 0;
  const [isFarmOrganic, setIsFarmOrganic] = useState<boolean>(
    props.defaultIsFarmOrganic
  );
  const [hasTemporaryPasture, setHasTemporaryPasture] = useState<boolean>(
    props.defaultHasTemporaryPasture
  );
  const [hasRotationalGrazingImplemented, setHasRotationalGrazingImplemented] =
    useState<boolean>(props.defaultHasRotationalGrazingImplemented);
  const [hasAreaWithSoilLessThan30cmDeep, setHasAreaWithSoilLessThan30cmDeep] =
    useState<boolean>(props.defaultHasAreaWithSoilLessThan30cmDeep);

  const { yearStart, yearEnd } = props;

  const form = (
    <form
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
      }}
    >
      <Stack spacing={4}>
        <HighlightedText>
          {translate("common.applicationRange", { yearStart, yearEnd })}
        </HighlightedText>

        <HighlightedSeparatorText>
          {translate("pastureManagementForm.sectionTitles.temporaryGrassland")}
        </HighlightedSeparatorText>
        <PastureManagementSwitch
          name="isFarmOrganic"
          label={
            isFarmOrganic
              ? translate("pastureManagementForm.sections.isFarmOrganic.label")
              : translate(
                  "pastureManagementForm.sections.isFarmOrganic.labelDisabled"
                )
          }
          control={control}
          value={isFarmOrganic}
          setValue={setIsFarmOrganic}
        />
        {isFarmOrganic && (
          <NumberInputField
            control={control}
            defaultValue={props.defaultYearsOrganic}
            name="yearsOrganic"
            label={translate(
              "pastureManagementForm.sections.yearsOrganic.label"
            )}
          />
        )}
        <PastureManagementSwitch
          name="hasTemporaryPasture"
          label={
            hasTemporaryPasture
              ? translate(
                  "pastureManagementForm.sections.hasTemporaryPasture.label"
                )
              : translate(
                  "pastureManagementForm.sections.hasTemporaryPasture.labelDisabled"
                )
          }
          control={control}
          value={hasTemporaryPasture}
          setValue={setHasTemporaryPasture}
        />
        {hasTemporaryPasture && (
          <FieldUsageInputField
            control={control}
            defaultValue={props.defaultAreaTemporaryPasture}
            name="areaTemporaryPasture"
            label={translate(
              "pastureManagementForm.sections.areaTemporaryPasture.label"
            )}
            availableLand={availableLand}
            unit={unit}
          />
        )}
        {hasTemporaryPasture && (
          <NumberInputField
            control={control}
            defaultValue={props.defaultYearsInPasture}
            name="yearsInPasture"
            label={translate(
              "pastureManagementForm.sections.yearsInPasture.label"
            )}
          />
        )}
        {hasTemporaryPasture && (
          <NumberInputField
            control={control}
            defaultValue={props.defaultYearsInArable}
            name="yearsInArable"
            label={translate(
              "pastureManagementForm.sections.yearsInArable.label"
            )}
          />
        )}
        <HighlightedSeparatorText>
          {translate("pastureManagementForm.sectionTitles.pasture")}
        </HighlightedSeparatorText>
        <FieldUsageInputField
          control={control}
          defaultValue={props.defaultAreaMultiSpeciesSward}
          name="areaMultiSpeciesSward"
          label={translate(
            "pastureManagementForm.sections.areaMultiSpeciesSward.label"
          )}
          availableLand={availableLand}
          unit={unit}
        />

        <HighlightedSeparatorText>
          {translate("pastureManagementForm.sectionTitles.grazing")}
        </HighlightedSeparatorText>
        <PastureManagementSwitch
          name="hasRotationalGrazingImplemented"
          label={
            hasRotationalGrazingImplemented
              ? translate(
                  "pastureManagementForm.sections.hasRotationalGrazingImplemented.label"
                )
              : translate(
                  "pastureManagementForm.sections.hasRotationalGrazingImplemented.labelDisabled"
                )
          }
          control={control}
          value={hasRotationalGrazingImplemented}
          setValue={setHasRotationalGrazingImplemented}
        />

        {hasRotationalGrazingImplemented && (
          <NumberInputField
            control={control}
            defaultValue={props.defaultRotationalGrazingDaysMovement}
            name="rotationalGrazingDaysMovement"
            label={translate(
              "pastureManagementForm.sections.rotationalGrazingDaysMovement.label"
            )}
          />
        )}
        {hasRotationalGrazingImplemented && (
          <FieldUsageInputField
            control={control}
            defaultValue={props.defaultRotationalGrazingArea}
            name="rotationalGrazingArea"
            label={translate(
              "pastureManagementForm.sections.rotationalGrazingArea.label"
            )}
            availableLand={availableLand}
            unit={unit}
          />
        )}
        <HighlightedSeparatorText>
          {translate("pastureManagementForm.sectionTitles.soil")}
        </HighlightedSeparatorText>
        <PastureManagementSwitch
          name="hasAreaWithSoilLessThan30cmDeep"
          label={
            hasAreaWithSoilLessThan30cmDeep
              ? translate(
                  "pastureManagementForm.sections.hasAreaWithSoilLessThan30cmDeep.label"
                )
              : translate(
                  "pastureManagementForm.sections.hasAreaWithSoilLessThan30cmDeep.labelDisabled"
                )
          }
          control={control}
          value={hasAreaWithSoilLessThan30cmDeep}
          setValue={setHasAreaWithSoilLessThan30cmDeep}
        />

        {hasAreaWithSoilLessThan30cmDeep && (
          <FieldUsageInputField
            control={control}
            defaultValue={props.defaultAreaWithSoilLessThan30cmDeep}
            name="areaWithSoilLessThan30cmDeep"
            label={translate(
              "pastureManagementForm.sections.areaWithSoilLessThan30cmDeep.label"
            )}
            availableLand={availableLand}
            unit={unit}
          />
        )}
        {hasAreaWithSoilLessThan30cmDeep && (
          <NumberInputField
            control={control}
            defaultValue={props.defaultDepthOfSoilLessThan30cmDeep}
            name="depthOfSoilLessThan30cmDeep"
            label={translate(
              "pastureManagementForm.sections.depthOfSoilLessThan30cmDeep.label"
            )}
          />
        )}
      </Stack>
    </form>
  );

  return (
    <BaseForm
      title={translate("pastureManagementForm.title")}
      description={translate("pastureManagementForm.description", {
        availableLand,
        unit,
      })}
      form={form}
      onReset={onReset}
      onSave={handleSubmit(onSubmit)}
    />
  );
};

export default PastureManagementForm;
