import { HttpError, httpGet, httpPost } from "../http";
import {
  Details,
  FarmAnimalTypeDetailsData,
} from "../steps/FarmAnimalTypeDetailsStep";

export interface FarmAnimalTypeDetailsDTO {
  details: Details[];
}

export default class FarmAnimalTypeDetailsClient {
  constructor(private kApiEndpoint: string) {}

  public async getFarmAnimalTypeDetails(
    guid?: string
  ): Promise<FarmAnimalTypeDetailsData | undefined> {
    if (!guid) {
      return undefined;
    }
    try {
      const response = await httpGet<FarmAnimalTypeDetailsDTO>(
        `${this.kApiEndpoint}/v1/farmAnimalTypeDetails/${guid}`
      );
      return {
        details: response.details,
      };
    } catch (e: any) {
      if (e instanceof HttpError) {
        if (e.originalResponse.status === 404) {
          return undefined;
        }
      }
      throw e;
    }
  }

  public async createFarmAnimalTypeDetails(
    questionnaireGuid: string,
    farmAnimalTypeDetails: FarmAnimalTypeDetailsData
  ): Promise<string> {
    interface CreateFarmAnimalTypeDetailsRequestDTO
      extends FarmAnimalTypeDetailsDTO {
      questionnaireGuid: string;
    }

    interface CreateFarmAnimalTypeDetailsResponseDTO
      extends FarmAnimalTypeDetailsDTO {
      guid: string;
    }

    const response = await httpPost<
      CreateFarmAnimalTypeDetailsRequestDTO,
      CreateFarmAnimalTypeDetailsResponseDTO
    >(`${this.kApiEndpoint}/v1/farmAnimalTypeDetails`, {
      questionnaireGuid: questionnaireGuid,
      details: farmAnimalTypeDetails.details,
    });
    return response.guid;
  }
}
