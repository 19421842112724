import React, { useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import { Control, Controller, useForm } from "react-hook-form";
import { useStore } from "./store";
import BaseForm from "./baseForm";
import { Description, ErrorMessage, HighlightedText } from "./theme";
import { FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";
import { FarmlandData } from "./api";
import { onWheelOverride, positiveFloatRegex } from "./util";
import { translate } from "./common/locales/polyglot";

export interface FarmlandFormData {
  allPasture: string;
  rentedPasture: string;
  allPastureIncludedInRuumiProgram: string;
  includeRentedPasture: boolean;
  unmanageablePasture: string;
}

export interface FarmlandFormProps {
  onCompleted(farmlandData: FarmlandData): void;
  onCancelled(): void;
  defaultAllPasture: string;
  defaultRentedPasture: string;
  defaultAllPastureIncludedInRuumiProgram: string;
  defaultIncludeRentedPasture: boolean;
  defaultUnmanageablePasture: string;
  yearStart: number;
  yearEnd: number;
}

type AreaInfo =
  | "allPasture"
  | "rentedPasture"
  | "allPastureIncludedInRuumiProgram"
  | "unmanageablePasture";

export interface NumberFieldProps {
  control: Control<FarmlandFormData, any>;
  labelText: string;
  description: string;
  name: AreaInfo;
  defaultValue: string;
  updateValue: any;
  validate: any;
}
const numberRule = {
  value: positiveFloatRegex,
  message: "Please provide a positive number such as 90 or 54.2",
};
const requiredRule = { value: true, message: "This value is required" };
const defaultValidate = {
  required: requiredRule,
  pattern: numberRule,
};
export const NumberInputField = (fieldProps: NumberFieldProps) => (
  <Controller
    name={fieldProps.name}
    control={fieldProps.control}
    defaultValue={fieldProps.defaultValue}
    rules={{ ...defaultValidate, validate: fieldProps.validate }}
    render={({ field, fieldState: { error } }) => {
      return (
        <>
          <Description>{fieldProps.description}</Description>
          <TextField
            name={field.name}
            onBlur={field.onBlur}
            onWheel={onWheelOverride}
            ref={field.ref}
            value={field.value}
            onChange={(changeEvent) => {
              const value = changeEvent.currentTarget.value;
              fieldProps.updateValue(Number.parseFloat(value));
              field.onChange(changeEvent);
            }}
            label={fieldProps.labelText}
            helperText={error ? error.message : ""}
            inputMode="text"
            variant="filled"
            error={!!error}
          />
        </>
      );
    }}
  />
);

const LandForm = (props: FarmlandFormProps) => {
  const { reset, control, handleSubmit, trigger, formState } =
    useForm<FarmlandFormData>();

  const onSubmit = (landFormData: FarmlandFormData) => {
    props.onCompleted({
      allPasture: Number.parseFloat(landFormData.allPasture),
      rentedPasture: Number.parseFloat(landFormData.rentedPasture),
      allPastureIncludedInRuumiProgram: Number.parseFloat(
        landFormData.allPastureIncludedInRuumiProgram
      ),
      includeRentedPasture: landFormData.includeRentedPasture,
      unmanageablePasture: Number.parseFloat(landFormData.unmanageablePasture),
    });
  };

  const onReset = () => {
    props.onCancelled();
    reset();
  };

  const unit = useStore((state) => state.areaUnit);
  const [allPasture, setAllPasture] = useState<number>(
    Number.parseFloat(props.defaultAllPasture)
  );
  const [rentedPasture, setRentedPasture] = useState<number>(
    Number.parseFloat(props.defaultRentedPasture)
  );
  const [includeRentedPasture, setIncludeRentedPasture] = useState<boolean>(
    props.defaultIncludeRentedPasture
  );
  const [unmanageablePasture, setUnmanageablePasture] = useState<number>(
    Number.parseFloat(props.defaultUnmanageablePasture)
  );
  const [
    allPastureIncludedInRuumiProgram,
    setAllPastureIncludedInRuumiProgram,
  ] = useState<number>(
    Number.parseFloat(props.defaultAllPastureIncludedInRuumiProgram)
  );
  type FarmlandValues =
    | "allPasture"
    | "rentedPasture"
    | "includeRentedPasture"
    | "unmanageablePasture"
    | "allPastureIncludedInRuumiProgram";
  useEffect(() => {
    const allFields: FarmlandValues[] = [
      "allPasture",
      "rentedPasture",
      "includeRentedPasture",
      "unmanageablePasture",
      "allPastureIncludedInRuumiProgram",
    ];
    for (const v of allFields) {
      if (v in formState.dirtyFields) {
        trigger(v);
      }
    }
  }, [
    allPasture,
    rentedPasture,
    includeRentedPasture,
    unmanageablePasture,
    allPastureIncludedInRuumiProgram,
  ]);
  const unitSnippet = `in ${unit}s`;
  const { yearStart, yearEnd } = props;

  const validateUnmanageablePasture = (newUnmanageabePasture: string) => {
    let availablePasture = allPasture;
    if (!includeRentedPasture) {
      availablePasture = availablePasture - rentedPasture;
    }
    let remainingLand =
      availablePasture - Number.parseFloat(newUnmanageabePasture);
    if (remainingLand <= 0) {
      return translate("farmlandForm.validation.remainingLand", {
        availablePasture,
        unit,
      });
    } else {
      return true;
    }
  };
  const validateAllPastureIncludedInRuumiProgramme = (
    newAllPastureIncludedInRuumiProgramme: string
  ) => {
    const availablePasture = includeRentedPasture
      ? allPasture - unmanageablePasture
      : allPasture - rentedPasture - unmanageablePasture;
    if (
      availablePasture <
      Number.parseFloat(newAllPastureIncludedInRuumiProgramme)
    ) {
      return translate("farmlandForm.validation.notMoreThanIncluded");
    }

    return true;
  };
  const validateAllPasture = (newAllPasture: string) => {
    const n = Number.parseFloat(newAllPasture);
    if (n > 0) {
      return true;
    }
    return translate("farmlandForm.validation.somePasture");
  };
  const validateRentedPasture = (newRentedPasture: string) => {
    const n = Number.parseFloat(newRentedPasture);
    if (n > allPasture) {
      return translate("farmlandForm.validation.rentedPasture");
    }
    return true;
  };
  const validateIncludeRentedPasture = {
    validate: (newIncludeRentedLand: boolean) => {
      let availablePasture = allPasture;
      if (!newIncludeRentedLand) {
        availablePasture = allPasture - rentedPasture;
      }
      if (availablePasture <= 0) {
        return translate("farmlandForm.validation.availablePasture");
      } else {
        return true;
      }
    },
  };

  const includeRentedLandController = (
    <Controller
      control={control}
      name="includeRentedPasture"
      rules={validateIncludeRentedPasture}
      defaultValue={includeRentedPasture}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={includeRentedPasture}
                    name={field.name}
                    onBlur={field.onBlur}
                    ref={field.ref}
                    value={includeRentedPasture}
                    onChange={(changeEvent) => {
                      setIncludeRentedPasture(!includeRentedPasture);
                      field.onChange(changeEvent);
                    }}
                  />
                }
                label={
                  includeRentedPasture
                    ? translate(
                        "farmlandForm.sections.includeRentedPasture.label"
                      )
                    : translate(
                        "farmlandForm.sections.includeRentedPasture.labelDisabled"
                      )
                }
                labelPlacement="start"
              />
            </FormGroup>
            {error && <ErrorMessage role="alert">{error.message}</ErrorMessage>}
          </>
        );
      }}
    />
  );

  let remainingLandBeforeUnmanaged = includeRentedPasture
    ? allPasture
    : allPasture - rentedPasture;
  if (Number.isNaN(remainingLandBeforeUnmanaged))
    remainingLandBeforeUnmanaged = 0;
  let remainingLandManaged = includeRentedPasture
    ? allPasture - unmanageablePasture
    : allPasture - rentedPasture - unmanageablePasture;
  if (Number.isNaN(remainingLandManaged)) remainingLandManaged = 0;

  const form = (
    <form
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
      }}
    >
      <Stack spacing={4}>
        <Description>
          {translate("farmlandForm.sections.description")}
        </Description>
        <HighlightedText>
          {translate("common.applicationRange", { yearStart, yearEnd })}
        </HighlightedText>

        <NumberInputField
          name="allPasture"
          defaultValue={props.defaultAllPasture}
          control={control}
          labelText={translate("farmlandForm.sections.allPasture.label", {
            unitSnippet,
          })}
          description={translate(
            "farmlandForm.sections.allPasture.description",
            {
              unitSnippet,
            }
          )}
          updateValue={setAllPasture}
          validate={validateAllPasture}
        />
        <NumberInputField
          name="rentedPasture"
          defaultValue={props.defaultRentedPasture}
          control={control}
          labelText={translate("farmlandForm.sections.rentedPasture.label", {
            unitSnippet,
          })}
          description={translate(
            "farmlandForm.sections.rentedPasture.description",
            {
              unitSnippet,
            }
          )}
          updateValue={setRentedPasture}
          validate={validateRentedPasture}
        />
        {includeRentedLandController}

        <NumberInputField
          name="unmanageablePasture"
          defaultValue={props.defaultUnmanageablePasture}
          control={control}
          labelText={translate(
            "farmlandForm.sections.unmanageablePasture.label",
            {
              unitSnippet,
            }
          )}
          description={translate(
            "farmlandForm.sections.unmanageablePasture.description",
            { remainingLandBeforeUnmanaged, unit }
          )}
          updateValue={setUnmanageablePasture}
          validate={validateUnmanageablePasture}
        />
        <NumberInputField
          name="allPastureIncludedInRuumiProgram"
          defaultValue={props.defaultAllPastureIncludedInRuumiProgram}
          control={control}
          labelText={translate(
            "farmlandForm.sections.allPastureIncludedInRuumiProgram.label",
            { unitSnippet }
          )}
          description={translate(
            "farmlandForm.sections.allPastureIncludedInRuumiProgram.description",
            { remainingLandManaged, unit }
          )}
          updateValue={setAllPastureIncludedInRuumiProgram}
          validate={validateAllPastureIncludedInRuumiProgramme}
        />
      </Stack>
    </form>
  );

  return (
    <BaseForm
      title={translate("farmlandForm.title")}
      description={translate("farmlandForm.description")}
      form={form}
      onReset={onReset}
      onSave={handleSubmit(onSubmit)}
    />
  );
};

export default LandForm;
