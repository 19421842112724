import { YearlyData } from "../components/YearlyQuestionnaire/interfaces";
import { HttpError, httpGet, httpPost } from "../http";
import {
  FuelConsumptionData,
  FuelVolumeUnit,
} from "../steps/FuelConsumptionStep";
import Qty from "js-quantities";
import { identity, mapYearlyValues } from "../util";

const gallonImpToLitre = (v: number) =>
  Qty.swiftConverter("gallon-imp", "litre")(Math.floor(v));
const litreToGallonImp = (v: number) =>
  Math.floor(Qty.swiftConverter("litre", "gallon-imp")(v));

export interface FuelConsumptionDTO {
  data: {
    unit: FuelVolumeUnit;
    dieselLitrePerYear: number;
    petrolLitrePerYear: number;
    yearlyChangesData?:
      | {
          dieselLitrePerYear: YearlyData[];
          petrolLitrePerYear: YearlyData[];
        }
      | undefined;
    yearlyChangesSwitchValue: boolean | undefined;
  };
}

function fuelConsumptionDataToDTO(
  fuelConsumption: FuelConsumptionData
): FuelConsumptionDTO {
  const { unit } = fuelConsumption;
  const convert = unit === FuelVolumeUnit.GALLON ? gallonImpToLitre : identity;

  const {
    yearlyChangesData,
    dieselUnitPerYear,
    petrolUnitPerYear,
    yearlyChangesSwitchValue,
  } = fuelConsumption;
  return {
    data: {
      unit,
      dieselLitrePerYear: convert(dieselUnitPerYear),
      petrolLitrePerYear: convert(petrolUnitPerYear),
      yearlyChangesData: yearlyChangesData
        ? {
            dieselLitrePerYear: mapYearlyValues(
              yearlyChangesData.dieselUnitPerYear,
              convert
            ),
            petrolLitrePerYear: mapYearlyValues(
              yearlyChangesData.petrolUnitPerYear,
              convert
            ),
          }
        : undefined,
      yearlyChangesSwitchValue,
    },
  };
}

export function dtoToFuelConsumptionData(
  dto: FuelConsumptionDTO
): FuelConsumptionData {
  const { data } = dto;
  const { unit } = data;
  const convert = unit === FuelVolumeUnit.GALLON ? litreToGallonImp : identity;

  const {
    yearlyChangesData,
    dieselLitrePerYear,
    petrolLitrePerYear,
    yearlyChangesSwitchValue,
  } = data;
  return {
    unit,
    dieselUnitPerYear: convert(dieselLitrePerYear),
    petrolUnitPerYear: convert(petrolLitrePerYear),
    yearlyChangesData: yearlyChangesData
      ? {
          dieselUnitPerYear: mapYearlyValues(
            yearlyChangesData.dieselLitrePerYear,
            convert
          ),
          petrolUnitPerYear: mapYearlyValues(
            yearlyChangesData.petrolLitrePerYear,
            convert
          ),
        }
      : undefined,
    yearlyChangesSwitchValue,
  };
}

export default class FuelConsumptionClient {
  constructor(private kApiEndpoint: string) {}

  public async getFuelConsumption(
    guid?: string
  ): Promise<FuelConsumptionData | undefined> {
    if (!guid) {
      return undefined;
    }
    try {
      const response = await httpGet<FuelConsumptionDTO>(
        `${this.kApiEndpoint}/v1/fuelConsumption/${guid}`
      );
      return {
        ...dtoToFuelConsumptionData(response),
      };
    } catch (e: any) {
      if (e instanceof HttpError) {
        if (e.originalResponse.status === 404) {
          return undefined;
        }
      }
      throw e;
    }
  }

  public async createFuelConsumption(
    questionnaireGuid: string,
    fuelConsumptionData: FuelConsumptionData
  ): Promise<string> {
    interface CreateFuelConsumptionRequestDTO extends FuelConsumptionDTO {
      questionnaireGuid: string;
    }

    interface CreateFuelConsumptionResponseDTO extends FuelConsumptionDTO {
      guid: string;
    }

    const response = await httpPost<
      CreateFuelConsumptionRequestDTO,
      CreateFuelConsumptionResponseDTO
    >(`${this.kApiEndpoint}/v1/fuelConsumption`, {
      questionnaireGuid: questionnaireGuid,
      ...fuelConsumptionDataToDTO(fuelConsumptionData),
    });
    return response.guid;
  }
}
