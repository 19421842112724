import { HttpError, httpGet, httpPost } from "../http";
import { AllAnimalsType, ManureMangementTechniqueType } from "../interfaces";
import { ManureManagementData } from "../steps/ManureManagementStep";
import { YearlyValueDTO } from "./SyntheticFertilizerUsageClient";

export interface ManureManagementTechniqueDTO {
  name: ManureMangementTechniqueType;
  value?: number;
  yearlyChange?: YearlyDataDTO[];
  usesThisTechnique: boolean;
}

export interface ManureManagementForAnimalDTO {
  animalType: AllAnimalsType;
  manureManagementTechniques: ManureManagementTechniqueDTO[];
  yearlyChangesSwitchValue: boolean;
  quantityPerYear?: YearlyDataDTO[];
}

export interface YearlyDataDTO {
  year: number;
  value?: YearlyValueDTO;
}

export interface ManureManagementDTO {
  manureManagement: ManureManagementForAnimalDTO[];
}

export default class ManureManagementClient {
  constructor(private kApiEndpoint: string) {}

  public async getManureManagementData(
    guid?: string
  ): Promise<ManureManagementData | undefined> {
    if (!guid) {
      return undefined;
    }
    try {
      const response = await httpGet<ManureManagementDTO>(
        `${this.kApiEndpoint}/v1/manureManagement/${guid}`
      );

      return {
        manureManagement: response.manureManagement,
      };
    } catch (e: any) {
      if (e instanceof HttpError) {
        if (e.originalResponse.status === 404) {
          return undefined;
        }
      }
      throw e;
    }
  }

  public async createManureManagementData(
    questionnaireGuid: string,
    manureManagement: ManureManagementData
  ): Promise<string> {
    interface CreateManureManagementRequestDTO extends ManureManagementDTO {
      questionnaireGuid: string;
    }

    interface CreateManureManagementResponseDTO extends ManureManagementDTO {
      guid: string;
    }

    const response = await httpPost<
      CreateManureManagementRequestDTO,
      CreateManureManagementResponseDTO
    >(`${this.kApiEndpoint}/v1/manureManagement`, {
      questionnaireGuid: questionnaireGuid,
      manureManagement: manureManagement.manureManagement,
    });
    return response.guid;
  }
}
