import { Box, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import YearlyQuestionnaire from "../../components/YearlyQuestionnaire";
import {
  PartialFormData,
  HTMLInputTypeAttribute,
  YearlyData,
} from "../../components/YearlyQuestionnaire/interfaces";
import BaseStepComponent from "../../components/BaseStepComponent";
import { getYearlyValue, mergeYearlyData } from "../../util";
import { HighlightedText } from "../../theme";
import { translate } from "../../common/locales/polyglot";

interface SyntheticFertilizerStepProps {
  data: SyntheticFertilizerUsageData;
  onPrevStep(data: SyntheticFertilizerUsageData): void;
  onNextStep(data: SyntheticFertilizerUsageData): void;
  yearStart: number;
  yearEnd: number;
  years: number[];
}

export interface SyntheticFertilizerProduct {
  name: string;
  tonnesAppliedPerYear: number;
  tonnesAppliedPerYearYearlyChangesData: YearlyData[];
  nitrogenPercentage: number;
  yearlyChangesSwitchValue: boolean;
}

export interface SyntheticFertilizerUsageData {
  products: SyntheticFertilizerProduct[];
}

function syntheticFertilizerFormSectionGenerator(
  index: number,
  yearStart: number,
  yearEnd: number,
  years: number[],
  data?: SyntheticFertilizerProduct
) {
  return {
    yearlyChangesSwitchValue: false,
    name: `product-${index}`,
    title: translate("syntheticFertiliserUsage.questions.title", { index }),
    yearlyChangesSwitch: {
      label: "syntheticFertiliserUsage.yearlyChangesSwitch.label",
      value: data?.yearlyChangesSwitchValue || false,
    },
    questions: [
      {
        name: "product-name",
        value: data?.name ?? "",
        label: translate(
          "syntheticFertiliserUsage.questions.productName.label"
        ),
        type: "text" as HTMLInputTypeAttribute,
        required: true,
      },
      {
        name: "tonnes-product-applied",
        label: translate(
          "syntheticFertiliserUsage.questions.tonnesApplied.label"
        ),
        type: "number" as HTMLInputTypeAttribute,
        required: true,
        value: data?.tonnesAppliedPerYear,
        validation: { required: true, min: 0 },
        yearlyChangesData: mergeYearlyData(
          years,
          data?.tonnesAppliedPerYearYearlyChangesData ?? []
        ),
      },
      {
        name: "n-percentage",
        label: translate(
          "syntheticFertiliserUsage.questions.percentageNitrogen.label"
        ),
        type: "number" as HTMLInputTypeAttribute,
        required: true,
        value: data?.nitrogenPercentage,
        validation: { required: true, min: 0, max: 100 },
      },
    ],
  };
}

function formDataToSyntheticFertilizerUsageData(
  data: PartialFormData
): SyntheticFertilizerUsageData {
  const { syntheticFertilizerUsageForm } = data;

  const products = syntheticFertilizerUsageForm.sections.map((section) => ({
    name: section.questions[0].value as string, // expecting array order to be unchanged, otherwise could also find by questions[i].name
    tonnesAppliedPerYear: section.questions[1].value as number,
    nitrogenPercentage: section.questions[2].value as number,
    tonnesAppliedPerYearYearlyChangesData:
      section.questions[1].yearlyChangesData?.map(getYearlyValue) || [],
    yearlyChangesSwitchValue: section.yearlyChangesSwitch
      ? section.yearlyChangesSwitch.value
      : false,
  }));
  return {
    products,
  };
}

const SyntheticFertilizerUsageStep = (props: SyntheticFertilizerStepProps) => {
  const { products } = props.data;
  const methods = useForm<PartialFormData>({
    mode: "onBlur",
    defaultValues: {
      syntheticFertilizerUsageForm: {
        sections:
          products.length > 0 // if any products are provided, we use those to fill the form values
            ? products.map((product, i) =>
                syntheticFertilizerFormSectionGenerator(
                  i + 1,
                  props.yearStart,
                  props.yearEnd,
                  props.years,
                  product
                )
              )
            : [
                syntheticFertilizerFormSectionGenerator(
                  1,
                  props.yearStart,
                  props.yearEnd,
                  props.years
                ),
              ], // otherwise just create a single empty product
      },
    },
  });

  const defaultSectionGenerator = (index: number, data?: any) =>
    syntheticFertilizerFormSectionGenerator(
      index,
      props.yearStart,
      props.yearEnd,
      props.years,
      data
    );

  return (
    <FormProvider {...methods}>
      <form noValidate>
        <BaseStepComponent
          title={translate("syntheticFertiliserUsage.title")}
          description={translate("syntheticFertiliserUsage.description")}
          onPrevStep={(data: PartialFormData) =>
            props.onPrevStep(formDataToSyntheticFertilizerUsageData(data))
          }
          onNextStep={(data: PartialFormData) =>
            props.onNextStep(formDataToSyntheticFertilizerUsageData(data))
          }
        >
          <Stack spacing={4}>
            <HighlightedText>
              All questions apply from {props.yearStart} - {props.yearEnd}
            </HighlightedText>
            <Box>
              <YearlyQuestionnaire
                years={props.years}
                sectionsEditable={true}
                baseFormPath={"syntheticFertilizerUsageForm"}
                defaultSectionGenerator={defaultSectionGenerator}
              />
            </Box>
          </Stack>
        </BaseStepComponent>
      </form>
    </FormProvider>
  );
};

export default SyntheticFertilizerUsageStep;
