import { Control, Controller, Path } from "react-hook-form";
import { FormControlLabel, FormControlLabelProps, Switch } from "@mui/material";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { ErrorMessage } from "../../theme";

type IProps = Omit<FormControlLabelProps, "control">;

export type SwitchElementProps<T extends FieldValues> = IProps & {
  name: Path<T>;
  control?: Control<T>;
};

export default function SwitchElement<TFieldValues extends FieldValues>({
  name,
  control,
  ...other
}: SwitchElementProps<TFieldValues>) {
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <Switch
                {...field}
                checked={!!field.value}
                onChange={(ev) => {
                  field.onChange(ev);
                  if (typeof other.onChange === "function") {
                    other.onChange(ev, field.value);
                  }
                }}
              />
              {error && (
                <ErrorMessage role="alert">{error.message}</ErrorMessage>
              )}
            </>
          )}
        />
      }
      {...other}
    />
  );
}
