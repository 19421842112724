import { Box, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import YearlyQuestionnaire from "../../components/YearlyQuestionnaire";
import {
  PartialFormData,
  HTMLInputTypeAttribute,
  YearlyData,
} from "../../components/YearlyQuestionnaire/interfaces";
import BaseStepComponent from "../../components/BaseStepComponent";
import { AnimalType, AnimalTypeNames } from "../../interfaces";
import { useStore } from "../../store";
import { getYearlyValue, mergeYearlyData } from "../../util";
import { HighlightedText } from "../../theme";
import { translate } from "../../common/locales/polyglot";

interface FarmAnimalTypeDetailsStepProps {
  data: FarmAnimalTypeDetailsData;
  onPrevStep(data: FarmAnimalTypeDetailsData): void;
  onNextStep(data: FarmAnimalTypeDetailsData): void;
  yearStart: number;
  yearEnd: number;
  years: number[];
}

export interface FarmAnimalTypeDetailsData {
  details: Details[];
}

export interface Details {
  animalType: AnimalType;
  numberOfAnimalsPerYear?: number;
  monthsOnFarmPerYear?: number;
  averageAnimalWeight?: number;
  monthsOutdoorGrazingPerYear?: number;
  yearlyChangesData?:
    | {
        numberOfAnimalsPerYear: YearlyData[];
        monthsOnFarmPerYear: YearlyData[];
        averageAnimalWeight: YearlyData[];
        monthsOutdoorGrazingPerYear: YearlyData[];
      }
    | undefined;
  yearlyChangesSwitchValue?: boolean | undefined;
}

function farmAnimalTypeDetailsFormSectionGenerator(
  index: number,
  yearStart: number,
  yearEnd: number,
  years: number[],
  data?: Details
) {
  return {
    animalType: data?.animalType,
    yearlyChangesSwitchValue: false,
    name: `farm-animal-type-details-${index}-${data?.animalType}`,
    title: AnimalTypeNames[data!.animalType],
    yearlyChangesSwitch: {
      label: "farmAnimalTypeDetailsStep.questions.yearlyChangesSwitch.label",
      value: data?.yearlyChangesSwitchValue || false,
    },
    questions: [
      {
        name: "number-of-animals-per-year",
        value: data?.numberOfAnimalsPerYear,
        label: translate(
          "farmAnimalTypeDetailsStep.questions.numberOfAnimalsPerYear.label"
        ),
        type: "number" as HTMLInputTypeAttribute,
        required: true,
        validation: { required: true, min: 0 },
        yearlyChangesData: mergeYearlyData(
          years,
          data?.yearlyChangesData?.numberOfAnimalsPerYear ?? []
        ),
      },
      {
        name: "months-on-farm-per-year",
        label: translate(
          "farmAnimalTypeDetailsStep.questions.monthsOnFarmPerYear.label"
        ),
        type: "number" as HTMLInputTypeAttribute,
        required: true,
        value: data?.monthsOnFarmPerYear,
        validation: { required: true, min: 0, max: 12 },
        yearlyChangesData: mergeYearlyData(
          years,
          data?.yearlyChangesData?.monthsOnFarmPerYear ?? []
        ),
      },
      {
        name: "average-animal-weight",
        label: translate(
          "farmAnimalTypeDetailsStep.questions.averageAnimalWeight.label"
        ),
        type: "number" as HTMLInputTypeAttribute,
        required: true,
        value: data?.averageAnimalWeight,
        validation: { required: true, min: 0 },
        yearlyChangesData: mergeYearlyData(
          years,
          data?.yearlyChangesData?.averageAnimalWeight ?? []
        ),
      },
      {
        name: "months-outdoor-grazing-per-year",
        label: translate(
          "farmAnimalTypeDetailsStep.questions.monthsOutdoorGrazingPerYear.label"
        ),
        type: "number" as HTMLInputTypeAttribute,
        required: true,
        value: data?.monthsOutdoorGrazingPerYear,
        validation: { required: true, min: 0, max: 12 },
        yearlyChangesData: mergeYearlyData(
          years,
          data?.yearlyChangesData?.monthsOutdoorGrazingPerYear ?? []
        ),
      },
    ],
  };
}

function formDataTofarmAnimalTypeDetailsData(
  data: PartialFormData
): FarmAnimalTypeDetailsData {
  const { farmAnimalTypeDetailsForm } = data;

  const details = farmAnimalTypeDetailsForm.sections.map(
    (detail): Details => ({
      animalType: (detail as any).animalType,
      numberOfAnimalsPerYear: detail.questions[0].value as number,
      monthsOnFarmPerYear: detail.questions[1].value as number,
      averageAnimalWeight: detail.questions[2].value as number,
      monthsOutdoorGrazingPerYear: detail.questions[3].value as number,
      yearlyChangesData: {
        numberOfAnimalsPerYear:
          detail.questions[0].yearlyChangesData?.map(getYearlyValue) || [],
        monthsOnFarmPerYear:
          detail.questions[1].yearlyChangesData?.map(getYearlyValue) || [],
        averageAnimalWeight:
          detail.questions[2].yearlyChangesData?.map(getYearlyValue) || [],
        monthsOutdoorGrazingPerYear:
          detail.questions[3].yearlyChangesData?.map(getYearlyValue) || [],
      },
      yearlyChangesSwitchValue: detail.yearlyChangesSwitch
        ? detail.yearlyChangesSwitch.value
        : false,
    })
  );
  return {
    details,
  };
}

function onSubmitValidation(formData: any, clearErrors: any, years: number[]) {
  const errors = [];
  for (
    let sectionIndex = 0;
    sectionIndex < formData.farmAnimalTypeDetailsForm.sections.length;
    sectionIndex++
  ) {
    clearErrors(`farmAnimalTypeDetailsForm.sections.${sectionIndex}`);

    const section = formData.farmAnimalTypeDetailsForm.sections[sectionIndex];

    const { questions } = section;

    const yearlyChangesSwitchValue =
      section.yearlyChangesSwitch?.value || false;

    if (yearlyChangesSwitchValue) {
      years.forEach((_, index) => {
        const monthsOutdoorGrazingPerYear =
          parseInt(questions[3].yearlyChangesData?.[index]?.value, 10) || 0;
        const monthsOnFarmPerYear =
          parseInt(questions[1].yearlyChangesData?.[index]?.value, 10) || 0;

        if (monthsOutdoorGrazingPerYear > monthsOnFarmPerYear) {
          errors.push({
            name: `farmAnimalTypeDetailsForm.sections.${sectionIndex}.questions.3.yearlyChangesData.${index}.value`,
            type: "custom",
            message: "",
          });

          errors.push({
            name: `farmAnimalTypeDetailsForm.sections.${sectionIndex}.sectionError`,
            type: "custom",
            message: translate(
              "farmAnimalTypeDetailsStep.validation.monthsOutdoorGrazing"
            ),
          });
        }
      });
    } else {
      const monthsOutdoorGrazingPerYear = parseInt(questions[3].value, 10) || 0;
      const monthsOnFarmPerYear = parseInt(questions[1].value, 10) || 0;

      if (monthsOutdoorGrazingPerYear > monthsOnFarmPerYear) {
        errors.push({
          name: `farmAnimalTypeDetailsForm.sections.${sectionIndex}.questions.3.value`,
          type: "custom",
          message: translate(
            "farmAnimalTypeDetailsStep.validation.monthsOutdoorGrazing"
          ),
        });
      }
    }
  }

  return errors;
}

const FarmAnimalTypeDetailsStep = (props: FarmAnimalTypeDetailsStepProps) => {
  const { details } = props.data;
  const { yearStart, yearEnd, years } = props;
  const { animalTypes } = useStore(
    (state) => state.questionnaire?.farmSelectedAnimalTypesData
  ) || { animalTypes: [] };
  const methods = useForm<PartialFormData>({
    mode: "onBlur",
    defaultValues: {
      farmAnimalTypeDetailsForm: {
        sections: animalTypes.map((animalType, i) => {
          const detailsItem = details
            .filter((item) => item.animalType === animalType)
            .pop() || { animalType };

          return farmAnimalTypeDetailsFormSectionGenerator(
            i,
            yearStart,
            yearEnd,
            years,
            detailsItem
          );
        }),
      },
    },
  });

  const { clearErrors, setError } = methods;

  return (
    <FormProvider {...methods}>
      <form noValidate>
        <BaseStepComponent
          title={translate("farmAnimalTypeDetailsStep.title")}
          description={translate("farmAnimalTypeDetailsStep.description")}
          onPrevStep={(data: PartialFormData) => {
            const errors = setErrors(data, clearErrors, setError, years);
            if (errors.length === 0) {
              props.onPrevStep(formDataTofarmAnimalTypeDetailsData(data));
            }
          }}
          onNextStep={(data: PartialFormData) => {
            const errors = setErrors(data, clearErrors, setError, years);
            if (errors.length === 0) {
              props.onNextStep(formDataTofarmAnimalTypeDetailsData(data));
            }
          }}
        >
          <Stack spacing={4}>
            <HighlightedText>
              All questions apply from {props.yearStart} - {props.yearEnd}
            </HighlightedText>
            <Box>
              <YearlyQuestionnaire
                years={props.years}
                sectionsEditable={false}
                baseFormPath={"farmAnimalTypeDetailsForm"}
              />
            </Box>
          </Stack>
        </BaseStepComponent>
      </form>
    </FormProvider>
  );
};

export default FarmAnimalTypeDetailsStep;

function setErrors(
  data: PartialFormData,
  clearErrors: any,
  setError: any,
  years: number[]
) {
  const errors = onSubmitValidation(data, clearErrors, years);
  for (const error of errors) {
    setError(error.name as any, {
      type: error.type,
      message: error.message,
    });
  }
  return errors;
}
