import { default as Polyglot } from "node-polyglot";
import React from "react";
import * as en from "./en.json";

function customReplace(
  this: string,
  interpolationRegex: RegExp,
  cb: (wholeMatch: string, param: string) => any
) {
  const phrase = this;
  let i = 0;
  const children = [];
  const matches = Array.from(phrase.matchAll(interpolationRegex));

  matches.forEach((match: RegExpMatchArray) => {
    if (match.index && match.index > i) {
      children.push(phrase.substring(i, match.index));
    }
    children.push(cb(match[0], match[1]));
    i = match.index ? match.index + match[0].length : 0;
  });
  if (i < phrase.length) {
    children.push(phrase.substring(i));
  }

  return React.createElement("div", null, children);
}

// We have to ts-ignore this line because the type library is not up to date :(
// there is an open PR to merge the type definitions, but it hasn't been done yet.
//@ts-ignore
const poly = new Polyglot({ replace: customReplace });

poly.extend(en);

export const translate = (
  key: string,
  options?: Polyglot.InterpolationOptions
) => poly.t(key, options);
