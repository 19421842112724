import React from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useIsMobileLayout } from "./hooks";
import Logo from "./logo";

export interface HeaderProps {
  // empty
}

const Header = (props: HeaderProps) => {
  const isMobileLayout = useIsMobileLayout();

  return (
    <Box
      component="header"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Link to="/" style={{ color: "inherit", textDecoration: "inherit" }}>
        <Typography component="h1" variant="h3">
          ruumi
        </Typography>
      </Link>

      <Link to="/" style={{ color: "inherit", textDecoration: "inherit" }}>
        <Logo sx={{ fontSize: isMobileLayout ? 32 : 48 }} />
      </Link>
    </Box>
  );
};

export default Header;
