import { HttpError, httpGet, httpPost } from "../http";
import { SyntheticFertilizerUsageData } from "../steps/SyntheticFertilizerUsageStep";
import {
  AppliedOrganicFertiliserData,
  AppliedOrganicFertiliserTypeData,
} from "../steps/TotalAppliedOrganicFertiliser";

export interface OrganicFertiliserDTO
  extends AppliedOrganicFertiliserTypeData {}

export interface YearlyDataDTO {
  year: number;
  value?: YearlyValueDTO;
}

export type YearlyValueDTO = number | string | undefined; // possible values for the answers

export interface OrganicFertiliserApplicationDTO {
  appliedOrganicFertiliser: AppliedOrganicFertiliserTypeData[];
}

export default class OrganicFertiliserApplicationClient {
  constructor(private kApiEndpoint: string) {}

  public async getOrganicFertilizerApplicationData(
    guid?: string
  ): Promise<AppliedOrganicFertiliserData | undefined> {
    if (!guid) {
      return undefined;
    }
    try {
      const response = await httpGet<OrganicFertiliserApplicationDTO>(
        `${this.kApiEndpoint}/v1/organicFertiliser/${guid}`
      );
      return {
        organicFertiliser: response.appliedOrganicFertiliser,
      };
    } catch (e: any) {
      if (e instanceof HttpError) {
        if (e.originalResponse.status === 404) {
          return undefined;
        }
      }
      throw e;
    }
  }

  public async createOrganicFertiliserApplicationData(
    questionnaireGuid: string,
    organicFertiliserApplication: AppliedOrganicFertiliserTypeData[]
  ): Promise<string> {
    interface CreateOrganicFertilizerApplicationRequestDTO
      extends OrganicFertiliserApplicationDTO {
      questionnaireGuid: string;
    }

    interface CreateOrganicFertilizerApplicationResponseDTO
      extends OrganicFertiliserApplicationDTO {
      guid: string;
    }

    const response = await httpPost<
      CreateOrganicFertilizerApplicationRequestDTO,
      CreateOrganicFertilizerApplicationResponseDTO
    >(`${this.kApiEndpoint}/v1/organicFertiliser`, {
      questionnaireGuid,
      appliedOrganicFertiliser: organicFertiliserApplication,
    });
    return response.guid;
  }
}
