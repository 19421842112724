export interface QuestionnaireConfig {
  QUESTIONNAIRE_YEARS_RANGE: {
    start: number;
    end: number;
  };
  AVAILABLE_ANIMAL_TYPES: AnimalType[];
  AVAILABLE_IMPORTED_MANURE_ANIMAL_TYPES: ImportedManureAnimalType[];
}

export enum AnimalType {
  BEEF_CATTLE = "BEEF_CATTLE",
  DAIRY_COWS = "DAIRY_COWS",
  SHEEP = "SHEEP",
}

export enum OrganicFertiliserType {
  FYM = "FARMYARD_MANURE",
  SLURRY = "SLURRY",
  BIODIGESTATE = "BIODIGESTATE",
}

export const manureManagementTechniquesStrings = [
  "fym",
  "slurry",
  "biodigestate",
] as const;

export type ManureMangementTechniqueType =
  typeof manureManagementTechniquesStrings[number];

export function isManureMangementTechniqueType(
  name: any
): name is ManureMangementTechniqueType {
  return manureManagementTechniquesStrings.includes(name);
}

export const OrganicFertiliserDict = {
  fym: OrganicFertiliserType.FYM,
  slurry: OrganicFertiliserType.SLURRY,
  biodigestate: OrganicFertiliserType.BIODIGESTATE,
};

export const OrganicFertiliserTypeStrings = {
  [OrganicFertiliserType.FYM]: "Farmyard Manure",
  [OrganicFertiliserType.SLURRY]: "Slurry",
  [OrganicFertiliserType.BIODIGESTATE]: "Biodigestate",
};

export const OrganicFertiliserStorageStrings = {
  [OrganicFertiliserType.FYM]: "as farmyard manure",
  [OrganicFertiliserType.SLURRY]: " in a slurry pit",
  [OrganicFertiliserType.BIODIGESTATE]: "in a biodigester",
};

export enum ImportedManureAnimalType {
  BEEF_CATTLE = "BEEF_CATTLE",
  DAIRY_COWS = "DAIRY_COWS",
  SHEEP = "SHEEP",
  PIG = "PIG",
  CHICKEN = "CHICKEN",
  HORSE = "HORSE",
  BIODIGESTATE = "BIODIGESTATE",
  BIOSOLIDS = "BIOSOLIDS",
}

export const AllAnimals = Object.values({
  ...AnimalType,
  ...ImportedManureAnimalType,
});

export const ImportedManureNames = {
  [ImportedManureAnimalType.BEEF_CATTLE]: "beef cattle manure",
  [ImportedManureAnimalType.DAIRY_COWS]: "dairy cow manure",
  [ImportedManureAnimalType.SHEEP]: "sheep manure",
  [ImportedManureAnimalType.PIG]: "pig manure",
  [ImportedManureAnimalType.CHICKEN]: "chicken litter",
  [ImportedManureAnimalType.HORSE]: "horse manure",
  [ImportedManureAnimalType.BIODIGESTATE]: "biodigestate",
  [ImportedManureAnimalType.BIOSOLIDS]: "biosolids",
};

export const ImportedManureAnimalTypeNames = {
  [ImportedManureAnimalType.BEEF_CATTLE]: "Beef Cattle",
  [ImportedManureAnimalType.DAIRY_COWS]: "Dairy Cows",
  [ImportedManureAnimalType.SHEEP]: "Sheep",
  [ImportedManureAnimalType.PIG]: "Pig",
  [ImportedManureAnimalType.CHICKEN]: "Chicken",
  [ImportedManureAnimalType.HORSE]: "Horse",
  [ImportedManureAnimalType.BIODIGESTATE]: "Biodigestate",
  [ImportedManureAnimalType.BIOSOLIDS]: "Biosolids",
};

export const AnimalTypeNames = {
  [AnimalType.BEEF_CATTLE]: "Beef Cattle",
  [AnimalType.DAIRY_COWS]: "Dairy Cows",
  [AnimalType.SHEEP]: "Sheep",
};

const AllAnimalNames = Object.values({
  ...ImportedManureAnimalTypeNames,
  ...AnimalTypeNames,
});

export type AllAnimalsType = typeof AllAnimalNames[number];

export interface OrganicFertiliserByAnimal {
  animalType: AllAnimalsType;
  organicFertiliserType: OrganicFertiliserType;
}

export const AllAnimalsNames: { [key: string]: string } = {
  ...ImportedManureAnimalTypeNames,
  ...AnimalTypeNames,
};
