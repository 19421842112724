export class HttpError extends Error {
  originalResponse: Response;
  constructor(message: string, originalResponse: Response) {
    super(message);
    this.name = "HttpError";
    this.originalResponse = originalResponse;
  }
}

export async function httpGet<R>(url: string): Promise<R> {
  let headers = new Headers({
    "Content-Type": "application/json",
  });

  const resp = await fetch(url, {
    method: "GET",
    headers: headers,
  });

  if (!resp.ok) {
    throw new HttpError(resp.statusText, resp);
  }

  return resp.json() as Promise<R>;
}

export async function httpPost<T, R>(url: string, body: T): Promise<R> {
  let headers = new Headers({
    "Content-Type": "application/json",
  });

  const resp = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  });

  if (!resp.ok) {
    throw new HttpError(resp.statusText, resp);
  }

  return resp.json() as Promise<R>;
}

export async function httpPut<T, R>(url: string, body: T): Promise<R> {
  let headers = new Headers({
    "Content-Type": "application/json",
  });

  const resp = await fetch(url, {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(body),
  });

  if (!resp.ok) {
    throw new HttpError(resp.statusText, resp);
  }

  return resp.json() as Promise<R>;
}

export async function httpDelete<R>(url: string): Promise<R> {
  let headers = new Headers({
    "Content-Type": "application/json",
  });

  const resp = await fetch(url, {
    method: "DELETE",
    headers: headers,
  });

  if (!resp.ok) {
    throw new HttpError(resp.statusText, resp);
  }

  return resp.json() as Promise<R>;
}
