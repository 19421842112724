import mixpanel, { OverridedMixpanel } from "mixpanel-browser";

const useMixPanel =
  process.env.NODE_ENV === "production" && process.env.REACT_APP_MIXPANEL_TOKEN;

type UsedMixpanelMethods =
  | "init"
  | "identify"
  | "alias"
  | "track"
  | "register"
  | "people"
  | "reset"
  | "time_event"; // Add more methods to the type if used

const mockMixpanel: Pick<OverridedMixpanel, UsedMixpanelMethods> = {
  // To use additional mixpanel API features, add them to this proxy object

  // eslint-disable-next-line @typescript-eslint/unbound-method
  init: mixpanel.init, // the init method can be proxied directly since it doesn't matter if mixpanel is initialised in development
  identify: (id) => null,
  alias: (id) => null,
  track: (name, props) => null,
  time_event: (event_name) => null,
  register: (props) => null,
  people: {
    set: () => null,
    set_once: () => null,
    unset: () => null,
    increment: () => null,
    remove: () => null,
    append: () => null,
    union: () => null,
    track_charge: () => null,
    clear_charges: () => null,
    delete_user: () => null,
  },
  reset: () => null,
};

const activeMixPanel = useMixPanel ? mixpanel : mockMixpanel;

export enum MixpanelEvents {
  CLICK_GENERATE_ID_BUTTON = "Click generate id button",
  CLICK_QUESTIONNARE_START_BUTTON = "Click questionnaire start button",

  LOAD_QUESTIONNAIRE = "Load questionnaire",

  OPEN_GENERATE_ID_PAGE = "Open generate id page",
  OPEN_INTRO_PAGE = "Open intro page",
  OPEN_LINK_NOT_VALID_PAGE = "Open link not valid page",
  OPEN_NOT_FOUND_ERROR_PAGE = "Open not found error page",
  OPEN_OUTRO_PAGE = "Open outro page",
  OPEN_START_PAGE = "Open start page",
  OPEN_UNKNOWN_ERROR_PAGE = "Open unknown error page",

  QUESTIONNAIRE_FORM_STARTED = "Questionnaire form started",
  QUESTIONNAIRE_FORM_COMPLETED = "Questionnaire form completed",
  QUESTIONNAIRE_NEXT_STEP = "Questionnaire next step",
  QUESTIONNAIRE_PREVIOUS_STEP = "Questionnaire previous step",
}

export { activeMixPanel as mixpanel };
