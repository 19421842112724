import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import YearlyQuestionnaire from "../../components/YearlyQuestionnaire";
import {
  HTMLInputTypeAttribute,
  PartialFormData,
  YearlyData,
} from "../../components/YearlyQuestionnaire/interfaces";
import { defaultNumberValidate } from "../../util";
import BaseStepComponent from "../../components/BaseStepComponent";
import { useStore } from "../../store";
import {
  AllAnimalsNames,
  AllAnimalsType,
  OrganicFertiliserByAnimal,
  OrganicFertiliserDict,
  OrganicFertiliserType,
  OrganicFertiliserTypeStrings,
} from "../../interfaces";
import { translate } from "../../common/locales/polyglot";

export interface AppliedOrganicFertiliserTypeData {
  animalType: AllAnimalsType;
  organicFertiliserType: OrganicFertiliserType;
  amount: number;
  yearlyChangesSwitchValue: boolean;
  quantityPerYear?: YearlyData[];
}

export type AppliedOrganicFertiliserData = {
  organicFertiliser: AppliedOrganicFertiliserTypeData[];
};

interface AppliedOrganicFertiliserStepProps {
  defaultValues: AppliedOrganicFertiliserData;
  onPrevStep(data: AppliedOrganicFertiliserData): void;
  onNextStep(data: AppliedOrganicFertiliserData): void;
  years: number[];
  yearStart: number;
  yearEnd: number;
}

function formDataToOrganicFertiliserData(
  data: PartialFormData
): AppliedOrganicFertiliserData {
  const { organicFertiliser } = data;

  let output: AppliedOrganicFertiliserTypeData[] = [];

  organicFertiliser.sections.forEach((section: any) => {
    let { questions, animalType, organicFertiliserType, yearlyChangesSwitch } =
      section;
    let { value } = yearlyChangesSwitch;
    let yearlyChangeValue = value;

    questions.forEach((question: any) => {
      let questionData: AppliedOrganicFertiliserTypeData = {
        amount: 0,
        animalType: "",
        organicFertiliserType: OrganicFertiliserType.BIODIGESTATE,
        yearlyChangesSwitchValue: false,
      };

      let { value } = question;

      questionData.animalType = animalType;
      questionData.organicFertiliserType = organicFertiliserType;
      questionData.amount = value;
      if (yearlyChangeValue) {
        questionData.yearlyChangesSwitchValue = yearlyChangeValue;
        questionData.quantityPerYear = question.yearlyChangesData;
      }
      output.push(questionData);
    });
  });

  return {
    organicFertiliser: output,
  };
}

const organicFertiliserDataToFormData = (
  appliedOrganicFertiliserData: AppliedOrganicFertiliserData | undefined,
  organicFertiliserTypes: OrganicFertiliserByAnimal[],
  years: number[],
  yearStart: number,
  yearEnd: number
) => {
  const savedSections = appliedOrganicFertiliserData?.organicFertiliser || [];
  const returnData = {
    organicFertiliser: {
      sections: organicFertiliserTypes.map(
        (organicFertiliserByAnimal: OrganicFertiliserByAnimal) => {
          const section = savedSections
            .filter(
              (sectionData) =>
                sectionData.organicFertiliserType ===
                  organicFertiliserByAnimal.organicFertiliserType &&
                sectionData.animalType === organicFertiliserByAnimal.animalType
            )
            .pop();
          const sectionFormData = {
            animalType: organicFertiliserByAnimal.animalType,
            organicFertiliserType:
              organicFertiliserByAnimal.organicFertiliserType,
            name: `manure-management-${organicFertiliserByAnimal.animalType}-${organicFertiliserByAnimal.organicFertiliserType}`,
            title: translate(
              "totalAppliedOrganicFertiliserStep.questions.title",
              {
                animalType:
                  AllAnimalsNames[organicFertiliserByAnimal.animalType],
                method:
                  OrganicFertiliserTypeStrings[
                    organicFertiliserByAnimal.organicFertiliserType
                  ],
              }
            ),
            yearlyChangesSwitch: {
              label:
                "totalAppliedOrganicFertiliserStep.yearlyChangesSwitch.label",
              value: section?.yearlyChangesSwitchValue || false,
            },
            questions: [
              {
                name: "amount",
                value: section?.amount,
                label: translate(
                  "totalAppliedOrganicFertiliserStep.questions.amountTonnes.label"
                ),
                type: "number" as HTMLInputTypeAttribute,
                validation: defaultNumberValidate,
                yearlyChangesData: years.map((year, yearIndex) => {
                  return {
                    year,
                    value: section?.quantityPerYear?.[yearIndex]?.value,
                  };
                }),
              },
            ],
          };
          return sectionFormData;
        }
      ),
    },
  };
  return returnData;
};

const OrganicFertiliserStep = (props: AppliedOrganicFertiliserStepProps) => {
  const { years } = props;
  const manureData = useStore((state) => {
    return state.questionnaire?.manureManagementData.manureManagement;
  });

  let uniqueFertilisersByAnimal: OrganicFertiliserByAnimal[] = [];

  manureData?.forEach((manureManagementPerAnimal) => {
    const animalType = manureManagementPerAnimal.animalType;
    manureManagementPerAnimal.manureManagementTechniques.forEach(
      (manureManagement) => {
        if (manureManagement.usesThisTechnique) {
          uniqueFertilisersByAnimal.push({
            animalType,
            organicFertiliserType: OrganicFertiliserDict[manureManagement.name],
          });
        }
      }
    );
  });
  const methods = useForm<PartialFormData>({
    mode: "onChange",
    defaultValues: organicFertiliserDataToFormData(
      props.defaultValues,
      uniqueFertilisersByAnimal,
      years,
      props.yearStart,
      props.yearEnd
    ),
  });

  return (
    <FormProvider {...methods}>
      <form>
        <BaseStepComponent
          title={translate("totalAppliedOrganicFertiliserStep.title")}
          description={translate(
            "totalAppliedOrganicFertiliserStep.description"
          )}
          onPrevStep={(data: PartialFormData) => {
            props.onPrevStep(formDataToOrganicFertiliserData(data));
          }}
          onNextStep={(data: PartialFormData) => {
            props.onNextStep(formDataToOrganicFertiliserData(data));
          }}
        >
          <Box>
            <YearlyQuestionnaire
              years={years}
              sectionsEditable={false}
              baseFormPath={"organicFertiliser"}
            />
          </Box>
        </BaseStepComponent>
      </form>
    </FormProvider>
  );
};

export default OrganicFertiliserStep;
