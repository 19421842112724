import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

import { useIsMobileLayout } from "./hooks";

export interface FooterProps {
  // empty
}

const Footer = (props: FooterProps) => {
  const isMobileLayout = useIsMobileLayout();

  return (
    <Box component="footer" display="flex" justifyContent="center">
      <Stack direction={isMobileLayout ? "column" : "row"} spacing={4}>
        <Link underline="hover" href="https://graze.ruumi.io">
          Grazing App
        </Link>
        <Link underline="hover" href="https://www.ruumi.io">
          Homepage
        </Link>
        <Link underline="hover" href="https://careers.ruumi.io">
          Jobs
        </Link>
        <Link underline="hover" href="https://www.ruumi.io/legal-notice">
          Legal Notice
        </Link>
        <Link underline="hover" href="https://www.ruumi.io/data-protection">
          Data Protection
        </Link>
      </Stack>
    </Box>
  );
};

export default Footer;
