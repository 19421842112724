import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Spreadsheet from "./spreadsheet";
import Spacer from "./spacer";
import { Title } from "./theme";
import { useIsMobileLayout } from "./hooks";
import { translate } from "./common/locales/polyglot";

export interface IntroProps {
  onStartForms: () => void;
  disableStartButton: boolean;
  startButtonText: string;
  yearStart: number;
  yearEnd: number;
}

const Intro = (props: IntroProps) => {
  const isMobileLayout = useIsMobileLayout();
  const { yearStart, yearEnd } = props;

  return (
    <Box>
      <Title>{translate("intro.title")}</Title>

      <Spacer height={8} />

      <Stack direction={isMobileLayout ? "column" : "row"} spacing={4}>
        <Spreadsheet sx={{ fontSize: isMobileLayout ? 256 : 256 }} />

        <Box>
          <Typography>{translate("intro.description")}</Typography>

          <Spacer height={2} />

          <Typography>{translate("intro.transition")}</Typography>

          <Spacer height={2} />

          <Typography>
            {translate("intro.qualify", { yearStart, yearEnd })}
          </Typography>

          <Spacer height={2} />

          <Typography>{translate("intro.start")}</Typography>
        </Box>
      </Stack>

      <Spacer height={4} />

      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          disabled={props.disableStartButton}
          onClick={props.onStartForms}
        >
          {props.startButtonText}
        </Button>
      </Box>

      <Spacer height={8} />
    </Box>
  );
};

export default Intro;
