import { httpGet, httpPost } from "../http";

export type AdditionalInfoData = {
  guid: string;
  data: string;
};

interface CreateAdditionalInfoRequestDTO {
  questionnaireGuid: string;
  data: string;
}

interface CreateAdditionalInfoResponseDTO {
  guid: string;
}

export default class AdditionalInfoClient {
  constructor(private kApiEndpoint: string) {}
  public async createAdditionalInfo(
    questionnaireGuid: string,
    additionalInfoDetails: AdditionalInfoData
  ): Promise<string> {
    const response = await httpPost<
      CreateAdditionalInfoRequestDTO,
      CreateAdditionalInfoResponseDTO
    >(`${this.kApiEndpoint}/v1/additionalInfo`, {
      questionnaireGuid: questionnaireGuid,
      data: additionalInfoDetails.data,
    });
    return response.guid;
  }
  public async getAdditionalInformation(
    guid?: string
  ): Promise<AdditionalInfoData | undefined> {
    if (!guid) {
      return undefined;
    }
    try {
      const response = await httpGet<AdditionalInfoData>(
        `${this.kApiEndpoint}/v1/additionalInfo/${guid}`
      );
      return response;
    } catch (e: any) {
      return undefined;
    }
  }
}
