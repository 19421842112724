import React from "react";
import { Control, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { PastureManagementFormData } from "./PastureManagementFormData";
import { onWheelOverride, positiveFloatRegex } from "../util";
const requiredRule = (value?: string) => {
  if (!value) {
    return "This value is required";
  }
  return true;
};
const numberRule = {
  value: positiveFloatRegex,
  message: "Please provide a positive number such as 90 or 54.2",
};

type validNames =
  | "yearsOrganic"
  | "yearsInPasture"
  | "yearsInArable"
  | "rotationalGrazingDaysMovement"
  | "depthOfSoilLessThan30cmDeep";

interface NumberInputFieldProps {
  control: Control<PastureManagementFormData> | undefined;
  defaultValue: string;
  name: validNames;
  label: string;
}
export const NumberInputField = (props: NumberInputFieldProps) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={{
        pattern: numberRule,
        validate: requiredRule,
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <TextField
              {...field}
              label={props.label}
              helperText={error ? error.message : ""}
              inputMode="text"
              variant="filled"
              onWheel={onWheelOverride}
              error={!!error}
            />
          </>
        );
      }}
    />
  );
};
