import {
  useFormContext,
  useWatch,
  FieldValues,
  Path,
  DeepPartial,
  ControllerProps,
} from "react-hook-form";

export type SectionsEditable = {
  sectionsEditable?: boolean; // whether it should allow to add/remove sections (e.g. with "+" button: "Product 1", "Product 2")
  minSections?: number; // minimum required number of sections after removing another one
  defaultSectionGenerator?: (index: number, data?: any) => SectionFormData; // only to create a new default values for the sections when creating a new one with + button
};

export type YearlyQuestionnaireProps = SectionsEditable & {
  years: number[];
  baseFormPath: Path<PartialFormData>; // base path to this yearly questionnaire store in the form data, e.g. if form data is like {form1: {sections: [...]}}, path must be "form1"
};

export type YearlyValue = number | string | undefined; // possible values for the answers

export type SwitchProps =
  | {
      label: string; // e.g. "are you using this?" or "does this change?"
      value: boolean;
    }
  | false
  | undefined;

export type YearlyProps =
  | {
      data: YearlyData[];
    }
  | false
  | undefined;

export interface YearlyData {
  year: number;
  value?: YearlyValue;
}

export type HTMLInputTypeAttribute =
  | "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

export interface QuestionFormData {
  // answers to the questions, like "Tonnes cattle FYM", "Tonnes product applied/year", etc
  name: string; // dom name
  label: string;
  value?: YearlyValue;
  type: HTMLInputTypeAttribute;
  questionEnabledSwitch?: SwitchProps; // "are you using this" switch, shows or hides the number input
  yearlyChangesData?: YearlyData[]; // yearly changes data for each year, in the form {year: value}
  validation?: ControllerProps["rules"];
  required?: boolean;
}

export interface SectionFormData {
  // section, like "beef", "sheep" or "Product #1"
  name: string; // base name for form DOM elements, required by react-use-form
  title: string;
  yearlyChangesSwitch?: SwitchProps;
  questions: QuestionFormData[];
}

export interface QuestionnaireFormData {
  // form data for the react-hook-form, items must have the same order as YearlyQuestionnaireProps items
  sections: SectionFormData[];
}

export interface PartialFormData extends FieldValues {
  [k: string]: QuestionnaireFormData;
}

export const useQuestionnaireFormValues = (
  path: Path<PartialFormData>
): DeepPartial<QuestionnaireFormData> => {
  // custom hook to watch form values update
  const { getValues } = useFormContext<PartialFormData>();

  return {
    ...useWatch(path as any), // subscribe to form value updates
    ...getValues(path), // always merge with latest form values
  };
};
