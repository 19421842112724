import { Control, Controller } from "react-hook-form";
import { ErrorMessage } from "../theme";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { PastureManagementFormData } from "./PastureManagementFormData";

export interface PastureManagementSwitchProps {
  control: Control<PastureManagementFormData, any> | undefined;
  name: validNames;
  label: string;
  value: boolean;
  setValue: any;
}
type validNames =
  | "isFarmOrganic"
  | "hasTemporaryPasture"
  | "hasRotationalGrazingImplemented"
  | "hasAreaWithSoilLessThan30cmDeep";

export const PastureManagementSwitch = (
  props: PastureManagementSwitchProps
) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={props.value}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    {...field}
                    checked={props.value}
                    value={props.value}
                    onChange={(changeEvent) => {
                      props.setValue(!props.value);
                      field.onChange(changeEvent);
                    }}
                  />
                }
                label={props.label}
              />
            </FormGroup>
            {error && <ErrorMessage role="alert">{error.message}</ErrorMessage>}
          </>
        );
      }}
    />
  );
};
