import { Fragment } from "react";
import { DeepPartial, Path, useFormContext } from "react-hook-form";
import SwitchElement from "../inputs/SwitchElement";
import { PartialFormData, SectionFormData, YearlyValue } from "./interfaces";
import { YearlyChangesSection } from "./YearlyChangesSection";
import QuestionItem from "./QuestionItem";
import { ErrorMessage, HighlightedSeparatorText } from "../../theme";
import { translate } from "../../common/locales/polyglot";
import { useStore } from "../../store";

interface QuestionListProps {
  sectionIndex: number;
  section: DeepPartial<SectionFormData>;
  years: number[];
  baseFormPath: Path<PartialFormData>;
}

function QuestionList({
  section,
  sectionIndex,
  years,
  baseFormPath,
}: QuestionListProps) {
  const { control, formState, setValue, clearErrors } =
    useFormContext<PartialFormData>();

  const { yearlyChangesSwitch, questions } = section;
  let toggleValue = false;
  let labelValue: string | undefined = "";
  const questionnaireConfig = useStore((state) => state.questionnaireConfig);
  const yearStart = questionnaireConfig?.QUESTIONNAIRE_YEARS_RANGE.start || 0;
  const yearEnd = questionnaireConfig?.QUESTIONNAIRE_YEARS_RANGE.end || 0;

  if (yearlyChangesSwitch && yearlyChangesSwitch.value !== undefined) {
    toggleValue = yearlyChangesSwitch.value;
    labelValue = toggleValue
      ? yearlyChangesSwitch?.label
      : `${yearlyChangesSwitch.label}Disabled`;
  }

  if (!questions) {
    return null;
  }

  const canShowYearlyChangesSection =
    !!yearlyChangesSwitch && yearlyChangesSwitch.value;

  const atLeastOneQuestionEnabled =
    questions.findIndex((questionField) => {
      // find any question without "are you using switch" or with "are you using switch" enabled
      return (
        !questionField?.questionEnabledSwitch ||
        questionField?.questionEnabledSwitch.value
      );
    }) !== -1;

  const errors = (
    formState.errors?.[baseFormPath]?.sections?.[sectionIndex] as any
  )?.sectionError;

  return (
    <Fragment key={`section-${section.name}`}>
      <HighlightedSeparatorText>{section.title}</HighlightedSeparatorText>
      {questions.map((questionField, questionIndex) => {
        if (!questionField) {
          return null;
        }
        return (
          <QuestionItem
            key={`questionitem-${questionField?.name}`}
            question={questionField}
            sectionName={section.name || ""}
            baseFormPath={baseFormPath}
            questionIndex={questionIndex}
            sectionIndex={sectionIndex}
            yearlyChangesEnabled={canShowYearlyChangesSection}
          />
        );
      })}

      {section.yearlyChangesSwitch && (
        <SwitchElement
          label={translate(labelValue ?? "", {
            yearStart,
            yearEnd,
            stock: section.title,
          })}
          labelPlacement="start"
          name={`${baseFormPath}.sections.${sectionIndex}.yearlyChangesSwitch.value`}
          control={control}
          onChange={() => {
            clearErrors(`${baseFormPath}.sections.${sectionIndex}`);
            questions.forEach((questionField, questionIndex) => {
              if (!questionField?.yearlyChangesData) {
                // don't copy default values for fields that don't have yearly data
                return;
              }
              const defaultValue: YearlyValue = questionField?.value;
              if (typeof defaultValue === "undefined" || defaultValue === "") {
                return;
              }
              const touched: boolean = years
                .map((year, yearlyChangesIndex) => {
                  const currentValue =
                    questionField?.yearlyChangesData?.[yearlyChangesIndex]
                      ?.value;
                  return typeof currentValue !== "undefined";
                })
                .reduce(
                  (anyFieldWasTouched, currentFieldWasTouched) =>
                    anyFieldWasTouched || currentFieldWasTouched,
                  false
                );
              if (!touched) {
                years.forEach((year, yearlyChangesIndex) => {
                  setValue(
                    `${baseFormPath}.sections.${sectionIndex}.questions.${questionIndex}.yearlyChangesData.${yearlyChangesIndex}.value`,
                    defaultValue,
                    {
                      shouldValidate: true,
                    }
                  );
                });
              }
            });
          }}
        />
      )}

      {canShowYearlyChangesSection && atLeastOneQuestionEnabled && (
        <YearlyChangesSection
          years={years}
          sectionIndex={sectionIndex}
          sectionName={section.name || ""}
          questions={questions}
          baseFormPath={baseFormPath}
        />
      )}
      <ErrorMessage role="alert">{errors?.message}</ErrorMessage>
    </Fragment>
  );
}

export default QuestionList;
