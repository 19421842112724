import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { grey, blueGrey } from "@mui/material/colors";

import "@fontsource/roboto-flex/variable-full.css";
import { mixpanel, logRocket, logRocketConfig } from "./common/analytics";

const mixpanelConfig = {
  api_host: "https://api-eu.mixpanel.com",
};

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN ?? "";

mixpanel.init(mixpanelToken, mixpanelConfig);

logRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID ?? "", logRocketConfig);

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      // Material UI was designed with Roboto in mind. Apple's San Francisco is very similar,
      // so we first try to load these system fonts, which means on mobile devices we do not
      // have to load the Roboto Flex variable font we are shipping. On desktop where neither
      // Apple's San Francisco not Google's Roboto are installed, we ship our own Roboto Flex.
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Roboto, Roboto FlexVariable, sans-serif",
    },
    palette: {
      primary: {
        main: "#3aac8a",
        light: "#43c69f",
        dark: "#319376",
        contrastText: "#ffffff",
      },
      text: {
        primary: blueGrey[900],
        secondary: blueGrey[700],
        disabled: blueGrey[200],
      },
      background: {
        paper: grey[50],
        default: grey[100],
      },
    },
  })
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md">
        <HashRouter>
          <App />
        </HashRouter>
      </Container>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
