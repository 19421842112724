import { FarmSelectedAnimalTypesData } from "../animalTypesForm";
import { HttpError, httpGet, httpPost } from "../http";
import { AnimalType } from "../interfaces";

export interface FarmSelectedAnimalTypesDTO {
  animalTypes: AnimalType[];
}

export default class FarmSelectedAnimalTypesClient {
  constructor(private kApiEndpoint: string) {}

  public async getFarmSelectedAnimalTypes(
    guid?: string
  ): Promise<FarmSelectedAnimalTypesData | undefined> {
    if (!guid) {
      return undefined;
    }
    try {
      const response = await httpGet<FarmSelectedAnimalTypesDTO>(
        `${this.kApiEndpoint}/v1/farmSelectedAnimalTypes/${guid}`
      );
      return {
        animalTypes: response.animalTypes,
      };
    } catch (e: any) {
      if (e instanceof HttpError) {
        if (e.originalResponse.status === 404) {
          return undefined;
        }
      }
      throw e;
    }
  }

  public async createFarmSelectedAnimalTypes(
    questionnaireGuid: string,
    data: FarmSelectedAnimalTypesData
  ): Promise<string> {
    interface CreateFarmSelectedAnimalTypesRequestDTO
      extends FarmSelectedAnimalTypesDTO {
      questionnaireGuid: string;
    }

    interface CreateFarmSelectedAnimalTypesResponseDTO
      extends FarmSelectedAnimalTypesDTO {
      guid: string;
    }

    const response = await httpPost<
      CreateFarmSelectedAnimalTypesRequestDTO,
      CreateFarmSelectedAnimalTypesResponseDTO
    >(`${this.kApiEndpoint}/v1/farmSelectedAnimalTypes`, {
      questionnaireGuid,
      animalTypes: data.animalTypes,
    });
    return response.guid;
  }
}
