import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FieldError } from "react-hook-form";
import { onWheelOverride } from "../util";
import { translate } from "../common/locales/polyglot";

interface CountrySelectProps {
  value: CountryCode | null;
  onChange: (value: CountryCode | null) => void;
  onBlur: () => void;
  inputRef: any;
  error: FieldError | undefined;
}

export enum CountryCode {
  GB = "GB",
  GBSCT = "GB-SCT",
  GBWLS = "GB-WLS",
  IE = "IE",
}

export default function CountrySelect(props: CountrySelectProps) {
  return (
    <Autocomplete
      id="country-autocomplete"
      sx={{ width: 300 }}
      options={Object.values(CountryCode)}
      autoHighlight
      getOptionLabel={(option) => countriesMap[option]?.label}
      value={props.value}
      onChange={(_, value) => props.onChange(value)}
      onBlur={props.onBlur}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${countriesMap[
              option
            ].code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${countriesMap[
              option
            ].code.toLowerCase()}.png 2x`}
            alt={countriesMap[option].label}
          />
          {countriesMap[option].label} ({countriesMap[option].code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          name={"country"}
          {...params}
          label={translate("countryDropdown.label")}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          error={!!props.error}
          helperText={props.error ? props.error.message : ""}
          inputRef={props.inputRef}
          onWheel={onWheelOverride}
        />
      )}
    />
  );
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countriesMap = {
  [CountryCode.GB]: {
    code: CountryCode.GB,
    label: "United Kingdom",
  },
  [CountryCode.GBSCT]: {
    code: CountryCode.GBSCT,
    label: "Scotland",
  },
  [CountryCode.GBWLS]: {
    code: CountryCode.GBWLS,
    label: "Wales",
  },
  [CountryCode.IE]: { code: CountryCode.IE, label: "Ireland" },
};
