import { Button, Grid, Stack, Tooltip } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import { translate } from "../../common/locales/polyglot";
import {
  PartialFormData,
  useQuestionnaireFormValues,
  YearlyQuestionnaireProps,
} from "./interfaces";
import QuestionList from "./QuestionList";

// React-hook-form is a completely separate store that manages form elements and we treat it as such: pass form default values to the react-hook-form and get data back on submit.

function YearlyQuestionnaire({
  years,
  sectionsEditable,
  minSections,
  defaultSectionGenerator: sectionGenerator,
  baseFormPath,
}: YearlyQuestionnaireProps) {
  const { control } = useFormContext<PartialFormData>();
  const { sections } = useQuestionnaireFormValues(`${baseFormPath}`);

  const { append, remove } = useFieldArray<
    PartialFormData,
    `${string}.sections`,
    "id"
  >({
    control,
    name: `${baseFormPath}.sections`,
  });

  if (!sections) {
    return null;
  }

  return (
    <>
      {sections.map((sectionField, sectionIndex) => {
        if (!sectionField) {
          return null;
        }
        return (
          <Stack
            spacing={4}
            key={`section-${sectionField.name}`}
            data-testid={`${baseFormPath}.sections.${sectionIndex}`}
          >
            <QuestionList
              section={sectionField}
              sectionIndex={sectionIndex}
              years={years}
              baseFormPath={baseFormPath}
            />
          </Stack>
        );
      })}

      {sectionsEditable && (
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Tooltip title={translate("yearlyQuestionnaire.addRow.label")}>
            <Button
              variant="contained"
              onClick={() => {
                sectionGenerator &&
                  append(sectionGenerator(sections.length + 1));
              }}
            >
              Add
            </Button>
          </Tooltip>
          {sections.length > (minSections || 0) && (
            <Tooltip title={translate("yearlyQuestionnaire.removeRow.label")}>
              <Button
                variant="contained"
                onClick={() => {
                  remove(sections.length - 1);
                }}
              >
                Remove
              </Button>
            </Tooltip>
          )}
        </Stack>
      )}
    </>
  );
}

export default YearlyQuestionnaire;
