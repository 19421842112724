import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import Spacer from "./spacer";
import { Title, Description } from "./theme";
import { useIsMobileLayout } from "./hooks";
import { useEffect } from "react";
import { mixpanel, MixpanelEvents } from "./common/analytics";
import { translate } from "./common/locales/polyglot";

const LinkNotValid = () => {
  const isMobileLayout = useIsMobileLayout();

  useEffect(() => {
    mixpanel.track(MixpanelEvents.OPEN_LINK_NOT_VALID_PAGE);
  }, []);

  return (
    <Paper sx={{ p: isMobileLayout ? 2 : 4 }}>
      <Stack>
        <Title>{translate("linkNotValid.title")}</Title>

        <Spacer height={2} />

        <Description>
          {translate("linkNotValid.helper", {
            contact: <a href="mailto:hello@ruumi.io">hello@ruumi.io</a>,
          })}
        </Description>
      </Stack>
    </Paper>
  );
};

export default LinkNotValid;
