import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import YearlyQuestionnaire from "../../components/YearlyQuestionnaire";
import {
  HTMLInputTypeAttribute,
  PartialFormData,
  YearlyData,
} from "../../components/YearlyQuestionnaire/interfaces";
import { defaultNumberValidate } from "../../util";
import BaseStepComponent from "../../components/BaseStepComponent";
import { useStore } from "../../store";
import {
  AllAnimalsNames,
  AllAnimalsType,
  ImportedManureNames,
  isManureMangementTechniqueType,
  manureManagementTechniquesStrings,
  ManureMangementTechniqueType,
  OrganicFertiliserDict,
  OrganicFertiliserStorageStrings,
  OrganicFertiliserTypeStrings,
} from "../../interfaces";
import { translate } from "../../common/locales/polyglot";
import { Details } from "../FarmAnimalTypeDetailsStep";

export interface ManureManagementTechnique {
  name: ManureMangementTechniqueType;
  value?: number;
  yearlyChange?: YearlyData[];
  usesThisTechnique: boolean;
}
export interface ManureManagementForAnimalTypeData {
  animalType: AllAnimalsType;
  manureManagementTechniques: ManureManagementTechnique[];
  yearlyChangesSwitchValue: boolean;
}

export type ManureManagementData = {
  manureManagement: ManureManagementForAnimalTypeData[];
};

interface ManureManagementStepProps {
  defaultValues: ManureManagementData;
  onPrevStep(data: ManureManagementData): void;
  onNextStep(data: ManureManagementData): void;
  yearStart: number;
  yearEnd: number;
  years: number[];
}

const onSubmitValidation = (
  formData: any,
  clearErrors: any,
  years: number[]
) => {
  const errors = [];
  // validating that %FYM+%Slurry is less than 100%
  // we can't use react-hook-form resolver, because it will disable all other fields validation
  // therefore we validate only on submit
  for (
    let sectionIndex = 0;
    sectionIndex < formData.manureManagement.sections.length;
    sectionIndex++
  ) {
    clearErrors(`manureManagement.sections.${sectionIndex}`);
    const section = formData.manureManagement.sections[sectionIndex];
    const yearlyChangesSwitchValue = section.yearlyChangesSwitch
      ? section.yearlyChangesSwitch.value
      : false;
    const { questions } = section;
    if (!yearlyChangesSwitchValue) {
      let sum: number = 0;
      questions.forEach((question: any) => {
        const uses = question.questionEnabledSwitch
          ? question.questionEnabledSwitch.value
          : false;
        sum += uses ? parseInt(question.value) : 0;
      });
      if (sum !== 100) {
        errors.push({
          name: `manureManagement.sections.${sectionIndex}.sectionError`,
          type: "custom",
          message: translate("manureManagementStep.validation.mustSum"),
        });
      }
    }

    if (yearlyChangesSwitchValue) {
      years.forEach((year, index) => {
        let sum: number = 0;
        questions.forEach((question: any) => {
          const uses = question.questionEnabledSwitch
            ? question.questionEnabledSwitch.value
            : false;
          sum += uses
            ? parseInt(question.yearlyChangesData?.[index]?.value || "0")
            : 0;
        });
        if (sum !== 100) {
          errors.push({
            name: `manureManagement.sections.${sectionIndex}.sectionError`,
            type: "custom",
            message: translate(
              "manureManagementStep.validation.mustSumPerYear"
            ),
          });
        }
      });
    }
  }

  return errors;
};

function formDataToManureManagementData(
  data: PartialFormData
): ManureManagementData {
  const { manureManagement } = data;
  const manureManagementData = manureManagement.sections.map((section) => {
    const manureManagementValues: ManureManagementTechnique[] = [];
    section.questions.forEach((question) => {
      if (isManureMangementTechniqueType(question.name)) {
        const usesThisTechnique = question.questionEnabledSwitch
          ? question.questionEnabledSwitch.value
          : false;
        const name = question.name;
        const value = question.value as number;
        const yearlyChange = question.yearlyChangesData;
        manureManagementValues.push({
          usesThisTechnique,
          value,
          name,
          yearlyChange,
        });
      } else {
        console.log(
          `Encountered unknown management technique ${question.name}. It will be dropped.`
        );
      }
    });
    return {
      animalType: (section as any).animalType,
      manureManagementTechniques: manureManagementValues,
      yearlyChangesSwitchValue: section.yearlyChangesSwitch
        ? section.yearlyChangesSwitch.value
        : false,
    };
  });

  return {
    manureManagement: manureManagementData,
  };
}

const manureManagementDataToFormData = (
  manureManagementData: ManureManagementData | undefined,
  selectedAnimalTypes: AllAnimalsType[],
  selectedYears: number[],
  yearStart: number,
  yearEnd: number
) => {
  const savedSections = manureManagementData?.manureManagement || [];
  return {
    manureManagement: {
      sections: selectedAnimalTypes.map((animalType: AllAnimalsType) => {
        const section = savedSections
          .filter((sectionData) => sectionData.animalType === animalType)
          .pop();
        return {
          animalType,
          name: `manure-management-${animalType}`,
          title: translate("manureManagementStep.questions.title", {
            manureType: (ImportedManureNames as any)[animalType],
          }),
          yearlyChangesSwitch: {
            label: "manureManagementStep.yearlyChangesSwitch.label",
            value: section?.yearlyChangesSwitchValue || false,
          },
          questions: manureManagementTechniquesStrings.map(
            (manureManagementTechniqueName) => {
              const manureMangement = (
                section?.manureManagementTechniques ?? []
              )
                .filter(
                  (manureManagementTechnique) =>
                    manureManagementTechnique.name ===
                    manureManagementTechniqueName
                )
                .pop() ?? {
                value: undefined,
                yearlyChange: undefined,
                usesThisTechnique: false,
                name: manureManagementTechniqueName,
              };
              const defaultYearlyChangesData = selectedYears.map((year) => {
                return {
                  year,
                };
              });

              const returnValue = {
                name: manureMangement.name,
                value: manureMangement.value,
                label: `%${
                  OrganicFertiliserTypeStrings[
                    OrganicFertiliserDict[manureMangement.name]
                  ]
                }`,
                type: "number" as HTMLInputTypeAttribute,
                questionEnabledSwitch: {
                  label: translate(
                    "manureManagementStep.questions.questionEnabledSwitch.label",
                    {
                      managementType:
                        OrganicFertiliserStorageStrings[
                          OrganicFertiliserDict[manureMangement.name]
                        ],
                    }
                  ),
                  value: manureMangement.usesThisTechnique,
                },
                validation: defaultNumberValidate,

                yearlyChangesData:
                  manureMangement.yearlyChange ?? defaultYearlyChangesData,
              };
              return returnValue;
            }
          ),
        };
      }),
    },
  };
};

export const outdoorAnimalFiltering = (
  farmAnimalMonthsOutdoor: Details[],
  animals: AllAnimalsType[]
): string[] => {
  const alwaysOutdoorAnimals = farmAnimalMonthsOutdoor.filter(
    (detail: Details) => {
      if (!detail.yearlyChangesSwitchValue || !detail.yearlyChangesData) {
        return (
          detail.monthsOnFarmPerYear === detail.monthsOutdoorGrazingPerYear
        );
      }

      let { monthsOnFarmPerYear, monthsOutdoorGrazingPerYear } =
        detail.yearlyChangesData;

      const indexFound = monthsOnFarmPerYear.find((yearData, index) => {
        let yearValue = yearData.value!;
        let grazingValue = monthsOutdoorGrazingPerYear[index].value!;

        if (grazingValue === undefined || yearValue === undefined) {
          return (
            detail.monthsOnFarmPerYear === detail.monthsOutdoorGrazingPerYear
          );
        }

        if (Number(grazingValue) < Number(yearValue)) {
          return true;
        }
        return false;
      });
      return indexFound === undefined;
    }
  );

  let output = animals.filter((animal: string) => {
    if (alwaysOutdoorAnimals.length === 0) return true;

    return !alwaysOutdoorAnimals.find((outdoorAnimal: Details) => {
      return animal === outdoorAnimal.animalType;
    });
  });
  return output;
};

const ManureManagementStep = (props: ManureManagementStepProps) => {
  let animals: AllAnimalsType[] =
    useStore(
      (state) => state.questionnaire?.farmSelectedAnimalTypesData?.animalTypes
    ) || [];

  const importedManureAnimals: AllAnimalsType[] =
    useStore(
      (state) =>
        state.questionnaire?.selectedImportedManureAnimalTypesData
          ?.selectedImportedManureAnimalTypes
    ) || [];

  const farmAnimalMonthsOutdoor =
    useStore(
      (state) => state.questionnaire?.farmAnimalTypeDetailsData.details
    ) || [];

  animals = outdoorAnimalFiltering(farmAnimalMonthsOutdoor, animals);
  const mergedAnimals: AllAnimalsType[] = animals.concat(importedManureAnimals);
  const uniqueAnimals = mergedAnimals.filter((v, i, a) => a.indexOf(v) === i);

  const methods = useForm<PartialFormData>({
    mode: "onChange",
    defaultValues: manureManagementDataToFormData(
      props.defaultValues,
      uniqueAnimals,
      props.years,
      props.yearStart,
      props.yearEnd
    ),
  });

  const { clearErrors, setError } = methods;

  return (
    <FormProvider {...methods}>
      <form>
        <BaseStepComponent
          title={translate("manureManagementStep.title")}
          description={translate("manureManagementStep.description")}
          onPrevStep={(data: PartialFormData) => {
            const errors = setErrors(data, clearErrors, setError, props.years);
            if (errors.length === 0) {
              props.onPrevStep(formDataToManureManagementData(data));
            }
          }}
          onNextStep={(data: PartialFormData) => {
            const errors = setErrors(data, clearErrors, setError, props.years);
            if (errors.length === 0) {
              props.onNextStep(formDataToManureManagementData(data));
            }
          }}
        >
          <Box>
            <YearlyQuestionnaire
              years={props.years}
              sectionsEditable={false}
              baseFormPath={"manureManagement"}
            />
          </Box>
        </BaseStepComponent>
      </form>
    </FormProvider>
  );
};

export default ManureManagementStep;
function setErrors(
  data: PartialFormData,
  clearErrors: any,
  setError: any,
  years: number[]
) {
  const errors = onSubmitValidation(data, clearErrors, years);
  for (const error of errors) {
    setError(error.name as any, {
      type: error.type,
      message: error.message,
    });
  }
  return errors;
}
