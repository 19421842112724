import React, { useEffect } from "react";

import Divider from "@mui/material/Divider";

import { Routes, Route, useNavigate } from "react-router-dom";

import { onGenerateAppIdCompleted } from "./store";

import Header from "./header";
import Main from "./main";
import Footer from "./footer";

import Spacer from "./spacer";

import Startpage from "./startpage";
import GenerateQuestionnaireIdForm from "./generateQuestionnaireIdForm";

import LinkNotValid from "./LinkNotValid";
import NotFoundError from "./NotFoundError";
import UnknownError from "./UnknownError";
import { mixpanel, MixpanelEvents } from "./common/analytics";

const App = () => {
  return (
    <div className="App">
      <Spacer height={2} />
      <Header />
      <Spacer height={8} />

      <Routes>
        <Route path="/" element={<Startpage />} />

        <Route path="/q" element={<CreateQuestionnaire />} />
        <Route path="/q/:questionnaireId" element={<Questionnaire />} />

        <Route path="/link-not-valid" element={<LinkNotValid />} />
        <Route path="/unknown-error" element={<UnknownError />} />

        <Route path="*" element={<NotFoundError />} />
      </Routes>

      <Spacer height={8} />
      <Divider light />
      <Spacer height={8} />
      <Footer />
      <Spacer height={8} />
    </div>
  );
};

const Questionnaire = () => {
  return <Main />;
};

const CreateQuestionnaire = () => {
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track(MixpanelEvents.OPEN_GENERATE_ID_PAGE);
  }, []);

  const createGuid = async () => {
    try {
      const newQuestionnaireId = await onGenerateAppIdCompleted();
      mixpanel.track(MixpanelEvents.CLICK_GENERATE_ID_BUTTON, {
        newQuestionnaireId,
      });
      navigate(`/q/${newQuestionnaireId}`);
    } catch (e) {
      console.log(e);
      navigate(`/unknown-error`);
    }
  };

  return <GenerateQuestionnaireIdForm onCompleted={createGuid} />;
};

export default App;
