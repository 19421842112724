import { YearlyData } from "./components/YearlyQuestionnaire/interfaces";

export const positiveFloatRegex = /^[+]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
export const numberRule = {
  value: positiveFloatRegex,
  message: "Please provide a positive number such as 90 or 54.2",
};
export const requiredRule = { value: true, message: "This value is required" };
export const defaultNumberValidate = {
  required: requiredRule,
  pattern: numberRule,
  setValueAs: (v: string) => {
    return parseInt(v, 10);
  },
};

export function getYearlyValue(item: any) {
  const value = parseInt(item.value as string, 10); // react hook form returns strings
  return {
    year: item.year,
    value: !isNaN(value) && value >= 0 ? value : undefined, // converting empty strings to undefined, see https://github.com/react-hook-form/react-hook-form/issues/656
  };
}

export function mapYearlyValues(
  items: YearlyData[],
  convert: (v: number) => number
): YearlyData[] {
  return items.map((item) => ({
    year: item.year,
    value: typeof item.value === "number" ? convert(item.value) : item.value,
  }));
}

export function mergeYearlyData(
  years: number[],
  yearlyData: YearlyData[]
): YearlyData[] {
  return years.map((year) => ({
    year,
    value: yearlyData.find((item) => item.year === year)?.value,
  }));
}

export function identity<T>(a: T): T {
  return a;
}

export function onWheelOverride(e: any) {
  e.target instanceof HTMLElement && e.target.blur();
}

export {};
