import { Box, useMediaQuery, useTheme } from "@mui/material";
import { DeepPartial, Path, useFormContext } from "react-hook-form";
import { PartialFormData, QuestionFormData } from "./interfaces";
import TextFieldElement from "../inputs/TextFieldElement";
import { onWheelOverride } from "../../util";

interface YearlyChangesSectionProps {
  years: number[];
  sectionIndex: number;
  sectionName: string;
  questions: DeepPartial<QuestionFormData[]>;
  baseFormPath: Path<PartialFormData>;
}

export function YearlyChangesSection({
  years,
  sectionIndex,
  questions,
  sectionName,
  baseFormPath,
}: YearlyChangesSectionProps) {
  // 768px is tablet width, for anything less than this we should use a column
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  if (!questions) {
    return null;
  }
  return (
    <>
      {questions.map((questionField, questionIndex) => {
        if (!questionField || !questionField.yearlyChangesData) {
          return null;
        }
        return (
          <>
            <p>
              {questionField.label !== undefined ? questionField.label : null}
            </p>
            <Box
              display="flex"
              alignItems="center"
              flexDirection={matches ? "column" : "row"}
              sx={{ p: 0.1 }}
            >
              {years.map((year) => {
                return (
                  <YearlyChangesItem
                    key={`yearlychanges-${year}-${sectionName}-${questions[questionIndex]?.name}`}
                    questionField={questionField}
                    year={year}
                    sectionIndex={sectionIndex}
                    questionIndex={questionIndex}
                    sectionName={sectionName}
                    baseFormPath={baseFormPath}
                  />
                );
              })}
            </Box>
          </>
        );
      })}
    </>
  );
}

interface YearlyChangesItemProps<T extends PartialFormData> {
  questionField: DeepPartial<QuestionFormData>;
  year: number;
  sectionIndex: number;
  questionIndex: number;
  sectionName: string;
  baseFormPath: Path<T>;
}

function YearlyChangesItem<T extends PartialFormData>({
  questionField,
  year,
  sectionIndex,
  questionIndex,
  baseFormPath,
}: YearlyChangesItemProps<T>) {
  const { control, formState, clearErrors } = useFormContext<PartialFormData>();
  const errors = formState.errors?.[baseFormPath]?.sections?.[sectionIndex];

  if (!questionField) {
    return null;
  }
  const questionEnabled = // we show only enabled questions
    !questionField.questionEnabledSwitch || // either switch is false and doesn't matter
    !!questionField.questionEnabledSwitch.value; //  or it's value must be true

  if (!questionEnabled) {
    return null;
  }

  const yearlyChangesIndex = questionField.yearlyChangesData?.findIndex(
    (i) => i && i.year === year
  );

  if (yearlyChangesIndex === -1 || typeof yearlyChangesIndex === "undefined") {
    return null;
  }

  const error =
    errors?.questions?.[questionIndex]?.yearlyChangesData?.[yearlyChangesIndex]
      ?.value;

  return (
    <>
      <TextFieldElement
        inputMode="text"
        variant="filled"
        type={questionField.type}
        label={year}
        control={control}
        helperText={error?.message || ""}
        error={!!error}
        name={`${baseFormPath}.sections.${sectionIndex}.questions.${questionIndex}.yearlyChangesData.${yearlyChangesIndex}.value`}
        validation={questionField.validation as any}
        required={questionField.required}
        onWheel={onWheelOverride}
        data-testid={`${baseFormPath}.sections.${sectionIndex}.questions.${questionIndex}.yearlyChangesData.${year}`}
        onChange={() => {
          clearErrors(`${baseFormPath}.sections.${sectionIndex}`);
        }}
      />
    </>
  );
}
