import SyntheticFertilizerUsageClient from "./api/SyntheticFertilizerUsageClient";
import ManureManagementClient from "./api/ManureManagementClient";
import { HttpError, httpGet, httpPost } from "./http";
import { PastureManagementData } from "./pastureManagementTab/PastureManagementData";
import { SyntheticFertilizerUsageData } from "./steps/SyntheticFertilizerUsageStep";
import { ManureManagementData } from "./steps/ManureManagementStep";
import { QuestionnaireConfig } from "./interfaces";
import FarmSelectedAnimalTypesClient from "./api/FarmSelectedAnimalTypesClient";
import { FarmSelectedAnimalTypesData } from "./animalTypesForm";
import FarmAnimalTypeDetailsClient from "./api/FarmAnimalTypeDetailsClient";
import { FarmAnimalTypeDetailsData } from "./steps/FarmAnimalTypeDetailsStep";
import FuelConsumptionClient from "./api/FuelConsumptionClient";
import { FuelConsumptionData } from "./steps/FuelConsumptionStep";
import FeedUsageClient from "./api/FeedUsageClient";
import { FeedUsageData } from "./steps/FeedUsageStep";
import SelectedImportedManureAnimalTypesClient from "./api/SelectedImportedManureAnimalTypesClient";
import { SelectedImportedManureAnimalTypesData } from "./importedManureAnimalTypesForm";
import ImportedManureClient from "./api/ImportedManureClient";
import { ImportedManureData } from "./steps/ImportedManureStep";
import OrganicFertiliserApplicationClient from "./api/OrganicFertiliserApplication";
import { AppliedOrganicFertiliserData } from "./steps/TotalAppliedOrganicFertiliser";
import { CountryCode } from "./components/CountryDropdown";
import AdditionalInfoClient, {
  AdditionalInfoData,
} from "./api/AdditionalInfoClient";

const kApiEndpoint =
  process.env.NODE_ENV === "production"
    ? "https://carbonapi.ruumi.io"
    : "http://localhost:5000";

export const syntheticFertilizerUsageClient =
  new SyntheticFertilizerUsageClient(kApiEndpoint);

export const manureManagementClient = new ManureManagementClient(kApiEndpoint);

export const farmSelectedAnimalTypesClient = new FarmSelectedAnimalTypesClient(
  kApiEndpoint
);

export const farmAnimalTypeDetailsClient = new FarmAnimalTypeDetailsClient(
  kApiEndpoint
);

export const fuelConsumptionClient = new FuelConsumptionClient(kApiEndpoint);
export const feedUsageClient = new FeedUsageClient(kApiEndpoint);
export const importedManureClient = new ImportedManureClient(kApiEndpoint);
export const organicFertiliserClient = new OrganicFertiliserApplicationClient(
  kApiEndpoint
);

export const selectedImportedManureAnimalTypesClient =
  new SelectedImportedManureAnimalTypesClient(kApiEndpoint);

export const additionalInfoClient = new AdditionalInfoClient(kApiEndpoint);

export const getStatus = async (): Promise<boolean | undefined> => {
  try {
    interface StatusResponse {
      ok: boolean;
    }

    const res = await httpGet<StatusResponse>(`${kApiEndpoint}/v1/status`);

    return res.ok;
  } catch (e: any) {
    return undefined;
  }
};

export const postNotification = async (): Promise<boolean | undefined> => {
  try {
    interface NotificationRequest {}
    interface NotificationResponse {
      ok: boolean;
    }

    const res = await httpPost<NotificationRequest, NotificationResponse>(
      `${kApiEndpoint}/v1/notification`,
      {}
    );

    return res.ok;
  } catch (e: any) {
    return undefined;
  }
};

export const createQuestionnaireId = async (): Promise<string> => {
  interface CreateQuestionnaireRequest {}
  interface CreateQuestionnaireResponse {
    guid: string;
  }
  const response = await httpPost<
    CreateQuestionnaireRequest,
    CreateQuestionnaireResponse
  >(`${kApiEndpoint}/v1/questionnaire`, {});
  return response.guid;
};

export interface ContactInformation {
  name: string;
  mail: string;
  phone: string;
  countryCode: string;
  streetLine1: string;
  streetLine2: string;
  city: string;
  country: CountryCode;
  postCode: string;
}
export interface AnimalType {
  animalTypeName: string;
  selected: boolean;
}
export interface AnimalTypes {
  animalTypes: AnimalType[];
}
export interface FarmlandData {
  allPasture: number;
  rentedPasture: number;
  allPastureIncludedInRuumiProgram: number;
  includeRentedPasture: boolean;
  unmanageablePasture: number;
}
export interface Questionnaire {
  guid: string;
  contactInformation?: ContactInformation;
  farmlandData?: FarmlandData;
  pastureManagementData?: PastureManagementData;
  syntheticFertilizerUsageData?: SyntheticFertilizerUsageData;
  manureManagementData?: ManureManagementData;
  farmSelectedAnimalTypesData?: FarmSelectedAnimalTypesData;
  farmAnimalTypeDetailsData?: FarmAnimalTypeDetailsData;
  fuelConsumptionData?: FuelConsumptionData;
  feedUsageData?: FeedUsageData;
  selectedImportedManureAnimalTypesData?: SelectedImportedManureAnimalTypesData;
  importedManureData?: ImportedManureData;
  organicFertiliserData?: AppliedOrganicFertiliserData;
  additionalInfoData?: AdditionalInfoData;
}

export async function getQuestionnaireConfig() {
  return httpGet<QuestionnaireConfig>(`${kApiEndpoint}/v1/questionnaireConfig`);
}

export const getQuestionnaire = async (
  guid: string
): Promise<Questionnaire> => {
  interface GetQuestionnaireResponse {
    guid: string;
    contactInformationGuid: string;
    additionalInfoGuid: string;
    farmlandGuid: string;
    pastureManagementGuid: string;
    syntheticFertlizerUsageGuid: string;
    manureManagementGuid: string;
    farmSelectedAnimalTypesGuid: string;
    farmAnimalTypeDetailsGuid: string;
    fuelConsumptionGuid: string;
    feedUsageGuid: string;
    selectedImportedManureAnimalTypesGuid: string;
    importedManureGuid: string;
    organicFertiliserGuid: string;
  }
  const res = await httpGet<GetQuestionnaireResponse>(
    `${kApiEndpoint}/v1/questionnaire/${guid}`
  );
  const contactInformation: ContactInformation | undefined =
    await getContactInformation(res.contactInformationGuid);

  const additionalInfoData: AdditionalInfoData | undefined =
    await additionalInfoClient.getAdditionalInformation(res.additionalInfoGuid);

  const farmlandData: FarmlandData | undefined = await getFarmlandData(
    res.farmlandGuid
  );

  const pastureManagementData: PastureManagementData | undefined =
    await getPastureManagementData(res.pastureManagementGuid);

  const syntheticFertilizerUsageData =
    await syntheticFertilizerUsageClient.getSyntheticFertilizerUsageData(
      res.syntheticFertlizerUsageGuid
    );

  const manureManagementData =
    await manureManagementClient.getManureManagementData(
      res.manureManagementGuid
    );

  const farmSelectedAnimalTypesData =
    await farmSelectedAnimalTypesClient.getFarmSelectedAnimalTypes(
      res.farmSelectedAnimalTypesGuid
    );

  const farmAnimalTypeDetailsData =
    await farmAnimalTypeDetailsClient.getFarmAnimalTypeDetails(
      res.farmAnimalTypeDetailsGuid
    );

  const fuelConsumptionData = await fuelConsumptionClient.getFuelConsumption(
    res.fuelConsumptionGuid
  );

  const feedUsageData = await feedUsageClient.getFeedUsage(res.feedUsageGuid);

  const selectedImportedManureAnimalTypesData =
    await selectedImportedManureAnimalTypesClient.getSelectedImportedManureAnimalTypes(
      res.selectedImportedManureAnimalTypesGuid
    );

  const organicFertiliserData =
    await organicFertiliserClient.getOrganicFertilizerApplicationData(
      res.organicFertiliserGuid
    );

  const importedManureData = await importedManureClient.getImportedManure(
    res.importedManureGuid
  );
  return {
    guid: res.guid,
    contactInformation,
    farmlandData,
    pastureManagementData,
    syntheticFertilizerUsageData,
    manureManagementData,
    farmSelectedAnimalTypesData,
    farmAnimalTypeDetailsData,
    fuelConsumptionData,
    feedUsageData,
    selectedImportedManureAnimalTypesData,
    importedManureData,
    organicFertiliserData,
    additionalInfoData,
  };
};

export const createContactInformation = async (
  questionnaireGuid: string,
  contactInformation: ContactInformation
): Promise<string> => {
  interface CreateContactInformationResponse {
    guid: string;
    name: string;
    mail: string;
    phone: string;
    countryCode: string;
    streetLine1: string;
    streetLine2: string;
    city: string;
    country: CountryCode;
    postCode: string;
  }
  interface CreateContactInformationRequest {
    questionnaireGuid: string;
    name: string;
    phone: string;
    countryCode: string;
    mail: string;
    streetLine1: string;
    streetLine2: string;
    city: string;
    country: CountryCode;
    postCode: string;
  }
  const response = await httpPost<
    CreateContactInformationRequest,
    CreateContactInformationResponse
  >(`${kApiEndpoint}/v1/contactInformation`, {
    questionnaireGuid: questionnaireGuid,
    ...contactInformation,
  });
  return response.guid;
};

const getContactInformation = async (
  guid?: string
): Promise<ContactInformation | undefined> => {
  if (!guid) {
    return undefined;
  }
  try {
    const response = await httpGet<ContactInformation>(
      `${kApiEndpoint}/v1/contactInformation/${guid}`
    );
    return {
      name: response.name,
      mail: response.mail,
      phone: response.phone,
      countryCode: response.countryCode,
      streetLine1: response.streetLine1,
      streetLine2: response.streetLine2,
      city: response.city,
      country: response.country,
      postCode: response.postCode,
    };
  } catch (e: any) {
    return undefined;
  }
};

const getFarmlandData = async (
  guid?: string
): Promise<FarmlandData | undefined> => {
  if (!guid) {
    return undefined;
  }
  try {
    const response = await httpGet<FarmlandData>(
      `${kApiEndpoint}/v1/farmland/${guid}`
    );
    return {
      allPasture: response.allPasture,
      rentedPasture: response.rentedPasture,
      includeRentedPasture: response.includeRentedPasture,
      unmanageablePasture: response.unmanageablePasture,
      allPastureIncludedInRuumiProgram:
        response.allPastureIncludedInRuumiProgram,
    };
  } catch (e: any) {
    if (e instanceof HttpError) {
      if (e.originalResponse.status === 404) {
        return undefined;
      }
    }
    throw e;
  }
};

export const createFarmlandData = async (
  questionnaireGuid: string,
  farmlandData: FarmlandData
): Promise<string> => {
  interface CreateFarmlandDataResponse {
    guid: string;
    allPasture: number;
    rentedPasture: number;
    allPastureIncludedInRuumiProgram: number;
    includeRentedPasture: boolean;
    unmanageablePasture: number;
  }
  interface CreateFarmlandDataRequest {
    questionnaireGuid: string;
    allPasture: number;
    rentedPasture: number;
    allPastureIncludedInRuumiProgram: number;
    includeRentedPasture: boolean;
    unmanageablePasture: number;
  }
  const response = await httpPost<
    CreateFarmlandDataRequest,
    CreateFarmlandDataResponse
  >(`${kApiEndpoint}/v1/farmland`, {
    questionnaireGuid: questionnaireGuid,
    ...farmlandData,
  });
  return response.guid;
};

const getPastureManagementData = async (
  guid?: string
): Promise<PastureManagementData | undefined> => {
  if (!guid) {
    return undefined;
  }
  try {
    const response = await httpGet<PastureManagementData>(
      `${kApiEndpoint}/v1/pastureManagement/${guid}`
    );
    return {
      isFarmOrganic: response.yearsOrganic > 0,
      yearsOrganic: response.yearsOrganic,
      hasTemporaryPasture: response.areaTemporaryPasture > 0,
      areaTemporaryPasture: response.areaTemporaryPasture,
      yearsInPasture: response.yearsInPasture,
      yearsInArable: response.yearsInArable,
      areaMultiSpeciesSward: response.areaMultiSpeciesSward,
      hasRotationalGrazingImplemented: response.rotationalGrazingArea > 0,
      rotationalGrazingArea: response.rotationalGrazingArea,
      rotationalGrazingDaysMovement: response.rotationalGrazingDaysMovement,
      hasAreaWithSoilLessThan30cmDeep:
        response.areaWithSoilLessThan30cmDeep > 0,
      areaWithSoilLessThan30cmDeep: response.areaWithSoilLessThan30cmDeep,
      depthOfSoilLessThan30cmDeep: response.depthOfSoilLessThan30cmDeep,
    };
  } catch (e: any) {
    if (e instanceof HttpError) {
      if (e.originalResponse.status === 404) {
        return undefined;
      }
    }
    throw e;
  }
};

export const createPastureManagementData = async (
  questionnaireGuid: string,
  pastureManagementData: PastureManagementData
): Promise<string> => {
  interface CreatePastureManagementDataResponse {
    guid: string;
    hasTemporaryPasture: boolean;
    areaTemporaryPasture: number;
    yearsInPasture: number;
    yearsInArable: number;
    areaMultiSpeciesSward: number;
    hasRotationalGrazingImplemented: boolean;
    rotationalGrazingArea: number;
    rotationalGrazingDaysMovement: number;
    hasAreaWithSoilLessThan30cmDeep: boolean;
    areaWithSoilLessThan30cmDeep: number;
    depthOfSoilLessThan30cmDeep: number;
  }
  interface CreatePastureManagementDataRequest {
    questionnaireGuid: string;
    areaTemporaryPasture: number;
    yearsInPasture: number;
    yearsInArable: number;
    areaMultiSpeciesSward: number;
    rotationalGrazingArea: number;
    rotationalGrazingDaysMovement: number;
    areaWithSoilLessThan30cmDeep: number;
    depthOfSoilLessThan30cmDeep: number;
  }
  const response = await httpPost<
    CreatePastureManagementDataRequest,
    CreatePastureManagementDataResponse
  >(`${kApiEndpoint}/v1/pastureManagement`, {
    questionnaireGuid: questionnaireGuid,
    ...pastureManagementData,
  });
  return response.guid;
};
