import { HttpError, httpGet, httpPost } from "../http";
import { SelectedImportedManureAnimalTypesData } from "../importedManureAnimalTypesForm";
import { ImportedManureAnimalType } from "../interfaces";

export interface SelectedImportedManureAnimalTypesDTO {
  selectedImportedManureAnimalTypes: ImportedManureAnimalType[];
}

export default class SelectedImportedManureAnimalTypesClient {
  constructor(private kApiEndpoint: string) {}

  public async getSelectedImportedManureAnimalTypes(
    guid?: string
  ): Promise<SelectedImportedManureAnimalTypesData | undefined> {
    if (!guid) {
      return undefined;
    }
    try {
      const response = await httpGet<SelectedImportedManureAnimalTypesDTO>(
        `${this.kApiEndpoint}/v1/selectedImportedManureAnimalTypes/${guid}`
      );
      return {
        selectedImportedManureAnimalTypes:
          response.selectedImportedManureAnimalTypes,
      };
    } catch (e: any) {
      if (e instanceof HttpError) {
        if (e.originalResponse.status === 404) {
          return undefined;
        }
      }
      throw e;
    }
  }

  public async createSelectedImportedManureAnimalTypes(
    questionnaireGuid: string,
    data: SelectedImportedManureAnimalTypesData
  ): Promise<string> {
    interface CreateSelectedImportedManureAnimalTypesRequestDTO
      extends SelectedImportedManureAnimalTypesDTO {
      questionnaireGuid: string;
    }

    interface CreateSelectedImportedManureAnimalTypesResponseDTO
      extends SelectedImportedManureAnimalTypesDTO {
      guid: string;
    }

    const response = await httpPost<
      CreateSelectedImportedManureAnimalTypesRequestDTO,
      CreateSelectedImportedManureAnimalTypesResponseDTO
    >(`${this.kApiEndpoint}/v1/selectedImportedManureAnimalTypes`, {
      questionnaireGuid,
      selectedImportedManureAnimalTypes: data.selectedImportedManureAnimalTypes,
    });
    return response.guid;
  }
}
