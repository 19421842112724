import { Box, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import YearlyQuestionnaire from "../../components/YearlyQuestionnaire";
import {
  PartialFormData,
  HTMLInputTypeAttribute,
  YearlyData,
  SectionFormData,
} from "../../components/YearlyQuestionnaire/interfaces";
import BaseStepComponent from "../../components/BaseStepComponent";
import { getYearlyValue, mergeYearlyData } from "../../util";
import { RadioButtonGroup } from "react-hook-form-mui";
import { HighlightedText } from "../../theme";
import { translate } from "../../common/locales/polyglot";

interface FuelConsumptionStepProps {
  data?: FuelConsumptionData;
  onPrevStep(data: FuelConsumptionData): void;
  onNextStep(data: FuelConsumptionData): void;
  yearStart: number;
  yearEnd: number;
  years: number[];
}

export interface FuelConsumptionData {
  unit: FuelVolumeUnit;
  dieselUnitPerYear: number;
  petrolUnitPerYear: number;
  yearlyChangesData?:
    | {
        dieselUnitPerYear: YearlyData[];
        petrolUnitPerYear: YearlyData[];
      }
    | undefined;
  yearlyChangesSwitchValue: boolean | undefined;
}

export enum FuelVolumeUnit {
  LITRE = "LITRE",
  GALLON = "GALLON",
}

const fuelConsumptionUnitNameMapperPlural = {
  [FuelVolumeUnit.LITRE]: "Litres",
  [FuelVolumeUnit.GALLON]: "Gallons",
};

function fuelConsumptionFormSectionGenerator(
  index: number,
  yearStart: number,
  yearEnd: number,
  years: number[],
  data?: FuelConsumptionData
) {
  return {
    name: `fuel-consumption-${index}`,
    title: ``,
    yearlyChangesSwitch: {
      label: "fuelConsumptionStep.yearlyChangesSwitch.label",
      value: data?.yearlyChangesSwitchValue || false,
    },
    questions: [
      {
        name: "diesel-unit-per-year",
        value: data?.dieselUnitPerYear || 0,
        label: getDieselQuestionLabel(data?.unit),
        type: "number" as HTMLInputTypeAttribute,
        required: true,
        validation: { required: true, min: 0 },
        yearlyChangesData: mergeYearlyData(
          years,
          data?.yearlyChangesData?.dieselUnitPerYear ?? []
        ),
      },
      {
        name: "petrol-unit-per-year",
        label: getPetrolQuestionLabel(data?.unit),
        type: "number" as HTMLInputTypeAttribute,
        required: true,
        value: data?.petrolUnitPerYear || 0,
        validation: { required: true, min: 0 },
        yearlyChangesData: mergeYearlyData(
          years,
          data?.yearlyChangesData?.petrolUnitPerYear ?? []
        ),
      },
    ],
  };
}

function getDieselQuestionLabel(unit: FuelVolumeUnit | undefined) {
  return `Total ${
    fuelConsumptionUnitNameMapperPlural[unit || FuelVolumeUnit.LITRE]
  } of Diesel/year`;
}
function getPetrolQuestionLabel(unit: FuelVolumeUnit | undefined) {
  return `Total ${
    fuelConsumptionUnitNameMapperPlural[unit || FuelVolumeUnit.LITRE]
  } of Petrol/year`;
}

function formDataToFuelConsumptionData(
  data: FuelConsumptionFormData
): FuelConsumptionData {
  const { fuelConsumptionForm } = data;

  const fuelConsumptionData = fuelConsumptionForm.sections.map(
    (item): FuelConsumptionData => ({
      unit: fuelConsumptionForm.fuelConsumptionUnit,
      dieselUnitPerYear: item.questions[0].value as number,
      petrolUnitPerYear: item.questions[1].value as number,
      yearlyChangesData: {
        dieselUnitPerYear:
          item.questions[0].yearlyChangesData?.map(getYearlyValue) || [],
        petrolUnitPerYear:
          item.questions[1].yearlyChangesData?.map(getYearlyValue) || [],
      },
      yearlyChangesSwitchValue: item.yearlyChangesSwitch
        ? item.yearlyChangesSwitch.value
        : false,
    })
  );

  return fuelConsumptionData[0];
}

interface FuelConsumptionFormData extends PartialFormData {
  [k: string]: {
    fuelConsumptionUnit: FuelVolumeUnit;
    sections: SectionFormData[];
  };
}

const FuelConsumptionStep = (props: FuelConsumptionStepProps) => {
  const { data } = props;
  const { yearStart, yearEnd, years } = props;

  const methods = useForm<FuelConsumptionFormData>({
    mode: "onBlur",
    defaultValues: {
      fuelConsumptionForm: {
        fuelConsumptionUnit: data?.unit || FuelVolumeUnit.LITRE,
        sections: [
          fuelConsumptionFormSectionGenerator(
            0,
            yearStart,
            yearEnd,
            years,
            data
          ),
        ],
      },
    },
  });

  const { clearErrors, setValue } = methods;

  return (
    <FormProvider {...methods}>
      <form noValidate>
        <BaseStepComponent
          title={translate("fuelConsumptionStep.title")}
          description={translate("fuelConsumptionStep.description")}
          onPrevStep={(data: FuelConsumptionFormData) => {
            props.onPrevStep(formDataToFuelConsumptionData(data));
          }}
          onNextStep={(data: FuelConsumptionFormData) => {
            props.onNextStep(formDataToFuelConsumptionData(data));
          }}
        >
          <Stack spacing={4}>
            <HighlightedText>
              {translate("common.applicationRange", { yearStart, yearEnd })}
            </HighlightedText>
            <Box>
              <RadioButtonGroup
                label={translate(
                  "fuelConsumptionStep.questions.volumeMetric.label"
                )}
                name="fuelConsumptionForm.fuelConsumptionUnit"
                options={[
                  {
                    id: FuelVolumeUnit.LITRE,
                    label:
                      fuelConsumptionUnitNameMapperPlural[FuelVolumeUnit.LITRE],
                  },
                  {
                    id: FuelVolumeUnit.GALLON,
                    label:
                      fuelConsumptionUnitNameMapperPlural[
                        FuelVolumeUnit.GALLON
                      ],
                  },
                ]}
                onChange={(value) => {
                  setValue(
                    "fuelConsumptionForm.sections.0.questions.0.label",
                    getDieselQuestionLabel(value as FuelVolumeUnit)
                  );
                  setValue(
                    "fuelConsumptionForm.sections.0.questions.1.label",
                    getPetrolQuestionLabel(value as FuelVolumeUnit)
                  );
                  clearErrors("fuelConsumptionForm");
                }}
                required
              />
            </Box>
          </Stack>
          <Box>
            <YearlyQuestionnaire
              years={props.years}
              sectionsEditable={false}
              baseFormPath={"fuelConsumptionForm"}
            />
          </Box>
        </BaseStepComponent>
      </form>
    </FormProvider>
  );
};

export default FuelConsumptionStep;
