import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import Spacer from "./spacer";
import { Title, Description } from "./theme";
import { useIsMobileLayout } from "./hooks";
import { translate } from "./common/locales/polyglot";

export interface GenerateQuestionnaireIdProps {
  onCompleted(): void;
}
const GenerateQuestionnaireIdForm = (props: GenerateQuestionnaireIdProps) => {
  const onSubmit = () => {
    props.onCompleted();
  };

  const isMobileLayout = useIsMobileLayout();
  return (
    <Paper sx={{ p: isMobileLayout ? 2 : 4 }}>
      <Stack direction="column" spacing={isMobileLayout ? 2 : 4}>
        <Stack>
          <Title>{translate("generateQuestionnaireIdForm.title")}</Title>

          <Spacer height={2} />

          <Description>
            {translate("generateQuestionnaireIdForm.description")}
          </Description>
        </Stack>
      </Stack>

      {!isMobileLayout && <Spacer height={8} />}
      {!isMobileLayout && <Divider light />}

      <Spacer height={4} />

      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={onSubmit}>
          {translate("generateQuestionnaireIdForm.generate")}
        </Button>
      </Box>
    </Paper>
  );
};

export default GenerateQuestionnaireIdForm;
