import { YearlyData } from "../components/YearlyQuestionnaire/interfaces";
import { HttpError, httpGet, httpPost } from "../http";
import { FeedUsageData } from "../steps/FeedUsageStep";

export interface FeedUsageDTO {
  data: {
    concentrateFeedTonnesPerYear: number;
    grainTonnesPerYear: number;
    yearlyChangesData?:
      | {
          concentrateFeedTonnesPerYear: YearlyData[];
          grainTonnesPerYear: YearlyData[];
        }
      | undefined;
    yearlyChangesSwitchValue: boolean | undefined;
  };
}

export default class FeedUsageClient {
  constructor(private kApiEndpoint: string) {}

  public async getFeedUsage(guid?: string): Promise<FeedUsageData | undefined> {
    if (!guid) {
      return undefined;
    }
    try {
      const response = await httpGet<FeedUsageDTO>(
        `${this.kApiEndpoint}/v1/feedUsage/${guid}`
      );
      return {
        ...response.data,
      };
    } catch (e: any) {
      if (e instanceof HttpError) {
        if (e.originalResponse.status === 404) {
          return undefined;
        }
      }
      throw e;
    }
  }

  public async createFeedUsage(
    questionnaireGuid: string,
    feedUsageData: FeedUsageData
  ): Promise<string> {
    interface CreateFeedUsageRequestDTO extends FeedUsageDTO {
      questionnaireGuid: string;
    }

    interface CreateFeedUsageResponseDTO extends FeedUsageDTO {
      guid: string;
    }

    const response = await httpPost<
      CreateFeedUsageRequestDTO,
      CreateFeedUsageResponseDTO
    >(`${this.kApiEndpoint}/v1/feedUsage`, {
      questionnaireGuid: questionnaireGuid,
      data: feedUsageData,
    });
    return response.guid;
  }
}
