import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import YearlyQuestionnaire from "../../components/YearlyQuestionnaire";
import {
  PartialFormData,
  HTMLInputTypeAttribute,
  YearlyData,
} from "../../components/YearlyQuestionnaire/interfaces";
import BaseStepComponent from "../../components/BaseStepComponent";
import {
  ImportedManureAnimalType,
  ImportedManureAnimalTypeNames,
} from "../../interfaces";
import { useStore } from "../../store";
import { translate } from "../../common/locales/polyglot";

interface ImportedManureStepProps {
  importedManureData: ImportedManureData;
  onPrevStep(data: ImportedManureData): void;
  onNextStep(data: ImportedManureData): void;
  yearStart: number;
  yearEnd: number;
  years: number[];
}

export interface ImportedManureData {
  importedManure: ImportedManure[];
}

export interface ImportedManure {
  importedManureAnimalType: ImportedManureAnimalType;
  amountImported?: number;
  yearlyChangesData?:
    | {
        amountImported: YearlyData[];
      }
    | undefined;
  yearlyChangesSwitchValue?: boolean | undefined;
}

function importedManureFormSectionGenerator(
  index: number,
  yearStart: number,
  yearEnd: number,
  years: number[],
  data?: ImportedManure
) {
  return {
    importedManureAnimalType: data?.importedManureAnimalType,
    yearlyChangesSwitchValue: false,
    name: `imported-manure-${index}-${data?.importedManureAnimalType}`,
    title:
      ImportedManureAnimalTypeNames[
        ImportedManureAnimalType[data!.importedManureAnimalType]
      ],
    yearlyChangesSwitch: {
      label: "importedManureStep.yearlyChangesSwitch.label",
      value: data?.yearlyChangesSwitchValue || false,
    },
    questions: [
      {
        name: "amount-imported",
        value: data?.amountImported,
        label: translate("importedManureStep.questions.amountImported.label"),
        type: "number" as HTMLInputTypeAttribute,
        required: true,
        validation: { required: true, min: 0 },
        yearlyChangesData: mergeYearlyData(
          years,
          data?.yearlyChangesData?.amountImported ?? []
        ),
      },
    ],
  };
}

function getNumber(item: any) {
  const value = parseInt(item.value as string, 10); // react hook form returns strings
  return {
    year: item.year,
    value: !isNaN(value) && value >= 0 ? value : undefined, // converting empty strings to undefined, see https://github.com/react-hook-form/react-hook-form/issues/656
  };
}

function mergeYearlyData(
  years: number[],
  yearlyChangesData: YearlyData[]
): YearlyData[] {
  return years.map((year) => ({
    year,
    value: yearlyChangesData.find((item) => item.year === year)?.value,
  }));
}

function formDataToImportedManureData(
  data: PartialFormData
): ImportedManureData {
  const { importedManureForm } = data;

  const importedManure = importedManureForm.sections.map(
    (detail): ImportedManure => ({
      importedManureAnimalType: (detail as any).importedManureAnimalType,
      amountImported: detail.questions[0].value as number,
      yearlyChangesData: {
        amountImported:
          detail.questions[0].yearlyChangesData?.map(getNumber) || [],
      },
      yearlyChangesSwitchValue: detail.yearlyChangesSwitch
        ? detail.yearlyChangesSwitch.value
        : false,
    })
  );
  return {
    importedManure: importedManure,
  };
}

// Needed?
function onSubmitValidation(formData: any, clearErrors: any, years: number[]) {
  const errors: any[] = [];
  for (
    let sectionIndex = 0;
    sectionIndex < formData.importedManureForm.sections.length;
    sectionIndex++
  ) {
    clearErrors(`importedManureForm.sections.${sectionIndex}`);

    const section = formData.importedManureForm.sections[sectionIndex];

    const { questions } = section;

    const yearlyChangesSwitchValue =
      section.yearlyChangesSwitch?.value || false;

    if (yearlyChangesSwitchValue) {
      years.forEach((_, index) => {
        const importedAmount =
          parseInt(questions[0].yearlyChangesData?.[index]?.value, 10) || 0;
      });
    } else {
      const importedAmount = parseInt(questions[0].value, 10) || 0;
    }
  }
  return errors;
}

const ImportedManureStep = (props: ImportedManureStepProps) => {
  const importedManureData = props.importedManureData;
  const { yearStart, yearEnd, years } = props;
  const selectedImportedManureAnimalTypes = useStore(
    (state) => state.questionnaire?.selectedImportedManureAnimalTypesData
  ) || { selectedImportedManureAnimalTypes: [] };
  const methods = useForm<PartialFormData>({
    mode: "onBlur",
    defaultValues: {
      importedManureForm: {
        sections:
          selectedImportedManureAnimalTypes.selectedImportedManureAnimalTypes.map(
            (importedManureAnimalType, i) => {
              const detailsItem = importedManureData.importedManure
                .filter(
                  (item) =>
                    item.importedManureAnimalType === importedManureAnimalType
                )
                .pop() || { importedManureAnimalType };
              return importedManureFormSectionGenerator(
                i,
                yearStart,
                yearEnd,
                years,
                detailsItem
              );
            }
          ),
      },
    },
  });

  const { clearErrors, setError } = methods;

  return (
    <FormProvider {...methods}>
      <form noValidate>
        <BaseStepComponent
          title=""
          description={translate(
            "importedManureStep.questions.manureYouImport.label"
          )}
          onPrevStep={(data: PartialFormData) => {
            const errors = setErrors(data, clearErrors, setError, years);
            if (errors.length === 0) {
              props.onPrevStep(formDataToImportedManureData(data));
            }
          }}
          onNextStep={(data: PartialFormData) => {
            const errors = setErrors(data, clearErrors, setError, years);
            if (errors.length === 0) {
              props.onNextStep(formDataToImportedManureData(data));
            }
          }}
        >
          <Box>
            <YearlyQuestionnaire
              years={props.years}
              sectionsEditable={false}
              baseFormPath={"importedManureForm"}
            />
          </Box>
        </BaseStepComponent>
      </form>
    </FormProvider>
  );
};

export default ImportedManureStep;

function setErrors(
  data: PartialFormData,
  clearErrors: any,
  setError: any,
  years: number[]
) {
  const errors = onSubmitValidation(data, clearErrors, years);
  for (const error of errors) {
    setError(error.name as any, {
      type: error.type,
      message: error.message,
    });
  }
  return [];
}
