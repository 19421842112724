import React from "react";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { Controller, useForm } from "react-hook-form";
import { MuiTelInput, MuiTelInputCountry } from "mui-tel-input";
import BaseForm from "./baseForm";

import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { ContactInformation } from "./api";
import CountrySelect, { CountryCode } from "./components/CountryDropdown";
import { onWheelOverride } from "./util";
import { translate } from "./common/locales/polyglot";

export interface ContactInformationFormProps {
  onCompleted(contactInformation: ContactInformation): void;
  onCancelled(): void;
  defaultName: string;
  defaultMail: string;
  defaultPhone: string;
  defaultCountryCode: string;
  defaultStreetLine1: string;
  defaultStreetLine2: string;
  defaultCity: string;
  defaultCountry: CountryCode;
  defaultPostCode: string;
}
const getCountryCode = (phoneNumber: string): string => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
  if (parsedPhoneNumber) {
    if (parsedPhoneNumber.country) {
      return parsedPhoneNumber.country;
    }
  }
  return "GB";
};

const countryDefault: MuiTelInputCountry = "GB";
const countriesSupported: MuiTelInputCountry[] = ["GB", "IE"];

const ContactInformationForm = (props: ContactInformationFormProps) => {
  const { reset, control, handleSubmit } = useForm<ContactInformation>();

  const onSubmit = (data: ContactInformation) => {
    const countryCode = getCountryCode(data.phone);
    props.onCompleted({
      name: data.name,
      mail: data.mail,
      phone: data.phone,
      countryCode: countryCode,
      streetLine1: data.streetLine1,
      streetLine2: data.streetLine2,
      city: data.city,
      country: data.country,
      postCode: data.postCode,
    });
  };

  const onReset = () => {
    props.onCancelled();
    reset();
  };

  const form = (
    <form
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
      }}
    >
      <Stack spacing={4}>
        <Controller
          control={control}
          name="name"
          defaultValue={props.defaultName}
          rules={{
            required: true,
          }}
          render={({ field: renderProps, fieldState: { error } }) => (
            <TextField
              {...renderProps}
              autoFocus
              label="Name"
              variant="filled"
              error={!!error}
              onWheel={onWheelOverride}
              data-private
            />
          )}
        />

        <Controller
          control={control}
          name="mail"
          defaultValue={props.defaultMail}
          rules={{
            required: true,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Mail"
              variant="filled"
              error={!!error}
              onWheel={onWheelOverride}
              data-private
            />
          )}
        />

        <Controller
          control={control}
          name="phone"
          defaultValue={props.defaultPhone}
          rules={{
            validate: isValidPhoneNumber,
          }}
          render={({ field, fieldState }) => (
            <MuiTelInput
              {...field}
              variant="filled"
              defaultCountry={countryDefault}
              preferredCountries={[countryDefault]}
              onlyCountries={countriesSupported}
              helperText={
                fieldState.error
                  ? translate("contactInformationForm.validation.phone")
                  : ""
              }
              error={!!fieldState.error}
              data-private
            />
          )}
        />

        <Controller
          control={control}
          name="streetLine1"
          defaultValue={props.defaultStreetLine1}
          rules={{
            required: true,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Street Line 1"
              variant="filled"
              error={!!error}
              onWheel={onWheelOverride}
              data-private
            />
          )}
        />
        <Controller
          control={control}
          name="streetLine2"
          defaultValue={props.defaultStreetLine2}
          rules={{
            required: false,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Street Line 2"
              variant="filled"
              error={!!error}
              onWheel={onWheelOverride}
              data-private
            />
          )}
        />
        <Controller
          control={control}
          name="city"
          defaultValue={props.defaultCity}
          rules={{
            required: true,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="City"
              variant="filled"
              error={!!error}
              onWheel={onWheelOverride}
              data-private
            />
          )}
        />
        <Controller
          control={control}
          name="postCode"
          defaultValue={props.defaultPostCode}
          rules={{
            required: true,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Post Code"
              variant="filled"
              error={!!error}
              data-private
            />
          )}
        />
        <Controller
          control={control}
          name="country"
          defaultValue={props.defaultCountry}
          rules={{
            required: true,
          }}
          render={({ field, fieldState: { error } }) => (
            <CountrySelect
              value={field.value || null}
              onBlur={field.onBlur}
              onChange={field.onChange}
              inputRef={field.ref}
              error={error}
              data-private
            />
          )}
        />
      </Stack>
    </form>
  );

  return (
    <BaseForm
      title={translate("contactInformationForm.title")}
      description={translate("contactInformationForm.description")}
      form={form}
      onReset={onReset}
      onSave={handleSubmit(onSubmit)}
    />
  );
};

export default ContactInformationForm;
