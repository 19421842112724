import { Box, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { translate } from "../../common/locales/polyglot";
import BaseForm from "../../baseForm";
import { AdditionalInfoData } from "../../api/AdditionalInfoClient";

interface AdditionalInfoStepProps {
  data?: string;
  onCompleted(contactInformation: AdditionalInfoData): void;
  onCancelled(): void;
}

const AdditionalInfoStep = (props: AdditionalInfoStepProps) => {
  const { reset, control, handleSubmit } = useForm<AdditionalInfoData>({
    mode: "onBlur",
    defaultValues: {
      data: props.data,
    },
  });

  const onReset = () => {
    props.onCancelled();
    reset();
  };

  const onSubmit = (data: AdditionalInfoData) => {
    props.onCompleted(data);
  };

  const form = (
    <form noValidate>
      <Box>
        <Controller
          control={control}
          name="data"
          render={({ field, fieldState: { error } }) => {
            return (
              <TextField
                value={field.value !== undefined ? field.value : props.data}
                onBlur={field.onBlur}
                onChange={field.onChange}
                inputRef={field.ref}
                label="Additional Information"
                variant="filled"
                multiline
                fullWidth
                minRows={2}
                data-private
              />
            );
          }}
        />
      </Box>
    </form>
  );
  return (
    <BaseForm
      title={translate("additionalInfoStep.title")}
      description={translate("additionalInfoStep.description")}
      form={form}
      onReset={onReset}
      onSave={handleSubmit(onSubmit)}
    />
  );
};

export default AdditionalInfoStep;
