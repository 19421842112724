import React from "react";
import { Control, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { PastureManagementFormData } from "./PastureManagementFormData";
import { onWheelOverride, positiveFloatRegex } from "../util";
import { translate } from "../common/locales/polyglot";
const requiredRule = (value?: string) => {
  if (!value) {
    return "This value is required";
  }
  return true;
};
const numberRule = {
  value: positiveFloatRegex,
  message: translate("fieldUsageInputField.validation.number"),
};

type validNames =
  | "areaTemporaryPasture"
  | "areaMultiSpeciesSward"
  | "rotationalGrazingArea"
  | "areaWithSoilLessThan30cmDeep";

interface FieldUsageInputFieldProps {
  control: Control<PastureManagementFormData> | undefined;
  defaultValue: string;
  name: validNames;
  label: string;
  availableLand: number;
  unit: string;
}
export const FieldUsageInputField = (props: FieldUsageInputFieldProps) => {
  let { availableLand, unit } = props;
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={{
        pattern: numberRule,
        validate: (area?: string) => {
          if (props.availableLand - Number.parseFloat(area ?? "0") < 0) {
            return translate(
              "fieldUsageInputField.validation.cannotAddMoreLand",
              { availableLand, unit }
            );
          }
          return requiredRule(area);
        },
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <TextField
              {...field}
              label={props.label}
              helperText={error ? error.message : ""}
              inputMode="text"
              variant="filled"
              error={!!error}
              onWheel={onWheelOverride}
            />
          </>
        );
      }}
    />
  );
};
