import React from "react";

import Stack from "@mui/material/Stack";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import BaseForm from "./baseForm";
import {
  AnimalType as AnimalTypeSelection,
  AnimalTypeNames,
} from "./interfaces";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { translate } from "./common/locales/polyglot";
import { ErrorMessage } from "./theme";
import { ContentPasteGoOutlined } from "@mui/icons-material";

export interface FarmSelectedAnimalTypesData {
  animalTypes: AnimalTypeSelection[];
}

export interface AnimalTypeFormProps {
  availableAnimalTypes: AnimalTypeSelection[];
  selectedAnimalTypes: FarmSelectedAnimalTypesData;
  onCompleted(data: FarmSelectedAnimalTypesData): void;
  onCancelled(): void;
  yearStart: number;
  yearEnd: number;
}

interface AnimalTypeField {
  animalTypeValue: AnimalTypeSelection;
  label: string;
  selected: boolean;
}

const onSubmitValidation = (data: AnimalTypeField[], clearErrors: any) =>
  data.find((value) => value.selected);

const AnimalTypeForm = (props: AnimalTypeFormProps) => {
  const {
    reset,
    control,
    handleSubmit,
    clearErrors,
    setError,
    getValues,
    formState,
  } = useForm<{
    animalTypes: AnimalTypeField[];
  }>({
    defaultValues: {
      animalTypes: props.availableAnimalTypes.map((animalTypeValue) => ({
        animalTypeValue,
        label: AnimalTypeNames[animalTypeValue],
        selected:
          props.selectedAnimalTypes.animalTypes.includes(animalTypeValue),
      })),
    },
  });

  const { fields } = useFieldArray<{
    animalTypes: AnimalTypeField[];
  }>({
    name: "animalTypes",
    control,
  });

  const onSubmit = (data: { animalTypes: AnimalTypeField[] }) => {
    const errors = setErrors(getValues().animalTypes, clearErrors, setError);
    if (errors) {
      return;
    }
    props.onCompleted({
      animalTypes: data.animalTypes
        .filter((item) => item.selected)
        .map((item) => item.animalTypeValue),
    });
  };

  const onReset = () => {
    props.onCancelled();
    reset();
  };

  const { yearStart, yearEnd } = props;

  const formErrors = formState.errors;

  const form = (
    <form
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
      }}
    >
      <Stack spacing={0}>
        <div>{translate("animalTypesForm.fields.description")}</div>

        <>
          {fields.map((field: AnimalTypeField, iteration: number) => {
            return (
              <Box
                key={field.animalTypeValue}
                display="flex"
                alignItems="center"
                flexDirection="row"
              >
                <Controller
                  control={control}
                  name={`animalTypes.${iteration}.selected`}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <FormControlLabel
                        label={field.label}
                        control={
                          <Checkbox
                            checked={value}
                            onChange={(ev) => {
                              clearErrors();
                              onChange(ev);
                            }}
                          />
                        }
                      />
                    </>
                  )}
                />
              </Box>
            );
          })}
        </>
      </Stack>
    </form>
  );
  return (
    <>
      <BaseForm
        title={translate("animalTypesForm.title")}
        description={translate("animalTypesForm.description", {
          contact: <a href="mailto:support@ruumi.io">support@ruumi.io</a>,
        })}
        form={form}
        onReset={onReset}
        onSave={handleSubmit(onSubmit)}
      />
      {Object.entries(formErrors).length > 0 && (
        <ErrorMessage>At least one option must be selected.</ErrorMessage>
      )}
    </>
  );
};

export default AnimalTypeForm;
function setErrors(data: AnimalTypeField[], clearErrors: any, setError: any) {
  const hasSelection = onSubmitValidation(data, clearErrors);

  if (!hasSelection) {
    setError("custom", {
      type: "min",
      message: "At least one value must be selected.",
    });
  }
  return !hasSelection;
}
