import { useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useStore } from "./store";
import Completion from "./completion";
import Spacer from "./spacer";
import { Title } from "./theme";
import { useIsMobileLayout } from "./hooks";
import { mixpanel, MixpanelEvents } from "./common/analytics";
import { translate } from "./common/locales/polyglot";

export interface OutroProps {
  // empty
}

const Outro = (props: OutroProps) => {
  const navigate = useNavigate();
  const isMobileLayout = useIsMobileLayout();

  const guid = useStore((state) => state.questionnaire?.guid);
  const contactInfo = useStore(
    (state) => state.questionnaire?.contactInformation
  );

  useEffect(() => {
    mixpanel.track(MixpanelEvents.OPEN_OUTRO_PAGE);
    mixpanel.track(MixpanelEvents.QUESTIONNAIRE_FORM_COMPLETED);
  }, []);

  // When showing the outro component, the store's questionnaire
  // must be there, otherwise this is a logic error on our side.
  useEffect(() => {
    if (!guid || !contactInfo) {
      navigate("/unknown-error");
    }
  }, [navigate, guid, contactInfo]);

  return (
    <Box>
      <Title>{translate("outro.title")}</Title>
      <Spacer height={8} />
      <Stack direction={isMobileLayout ? "column" : "row"} spacing={4}>
        <Completion sx={{ fontSize: isMobileLayout ? 256 : 256 }} />
        <Box>
          <Typography>
            {translate("outro.description", {
              contact: (
                <a href="https://ruumi.pipedrive.com/scheduler/ElbzEAh1/farm-report-shareback">
                  this link
                </a>
              ),
            })}
          </Typography>
          <Spacer height={2} />
          <Typography>{translate("outro.volume")}</Typography>
          <Spacer height={2} />
          <Typography>
            {translate("outro.potential", {
              contact: <a href="mailto:support@ruumi.io">support@ruumi.io</a>,
            })}
          </Typography>
          <Spacer height={2} />
          <Typography>{translate("outro.contact")}</Typography>
        </Box>
      </Stack>
      <Spacer height={8} />
    </Box>
  );
};

export default Outro;
