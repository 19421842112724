import React, { ReactNode } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import Spacer from "../spacer";
import { useIsMobileLayout } from "../hooks";
import { Description, Title } from "../theme";
import { FieldValues, useFormContext } from "react-hook-form";

interface BaseFormProps {
  title: string;
  description: string;
  onPrevStep(data?: FieldValues): void;
  onNextStep(data?: FieldValues): void;
}

function BaseStepComponent(props: React.PropsWithChildren<BaseFormProps>) {
  const isMobileLayout = useIsMobileLayout();
  const { handleSubmit, clearErrors, watch } = useFormContext();
  const formData = watch();

  return (
    <Paper sx={{ p: isMobileLayout ? 2 : 4 }}>
      <Stack direction="column" spacing={isMobileLayout ? 2 : 4}>
        <Box>
          <Stack>
            <Title>{props.title}</Title>

            <Spacer height={2} />

            <Description>{props.description}</Description>

            {isMobileLayout && <Spacer height={4} />}
          </Stack>
        </Box>

        <Box>{props.children}</Box>
      </Stack>

      {!isMobileLayout && <Spacer height={8} />}
      {!isMobileLayout && <Divider light />}

      <Spacer height={4} />

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          variant="text"
          onClick={async () => {
            await props.onPrevStep(formData);
            clearErrors();
          }}
        >
          Back
        </Button>

        <Button variant="contained" onClick={handleSubmit(props.onNextStep)}>
          Save
        </Button>
      </Stack>
    </Paper>
  );
}

export default BaseStepComponent;
