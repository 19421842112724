import React from "react";

import Stack from "@mui/material/Stack";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import BaseForm from "./baseForm";
import {
  ImportedManureAnimalType,
  ImportedManureAnimalTypeNames,
} from "./interfaces";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { translate } from "./common/locales/polyglot";

export interface SelectedImportedManureAnimalTypesData {
  selectedImportedManureAnimalTypes: ImportedManureAnimalType[];
}

export interface ImportedManureAnimalTypeFormProps {
  availableImportedManureAnimalTypes: ImportedManureAnimalType[];
  selectedImportedManureAnimalTypes: SelectedImportedManureAnimalTypesData;
  onCompleted(data: SelectedImportedManureAnimalTypesData): void;
  onCancelled(): void;
}

interface ImportedManureAnimalTypeField {
  importedManureAnimalTypeValue: ImportedManureAnimalType;
  label: string;
  selected: boolean;
}

const ImportedManureAnimalTypeForm = (
  props: ImportedManureAnimalTypeFormProps
) => {
  const { reset, control, handleSubmit, register } = useForm<{
    selectedImportedManureAnimalTypes: ImportedManureAnimalTypeField[];
  }>({
    defaultValues: {
      selectedImportedManureAnimalTypes:
        props.availableImportedManureAnimalTypes.map(
          (importedManureAnimalType) => ({
            importedManureAnimalTypeValue: importedManureAnimalType,
            label: ImportedManureAnimalTypeNames[importedManureAnimalType],
            selected:
              props.selectedImportedManureAnimalTypes.selectedImportedManureAnimalTypes.includes(
                importedManureAnimalType
              ),
          })
        ),
    },
  });

  const { fields } = useFieldArray<{
    selectedImportedManureAnimalTypes: ImportedManureAnimalTypeField[];
  }>({
    name: "selectedImportedManureAnimalTypes",
    control,
  });

  const onSubmit = (data: {
    selectedImportedManureAnimalTypes: ImportedManureAnimalTypeField[];
  }) => {
    props.onCompleted({
      selectedImportedManureAnimalTypes: data.selectedImportedManureAnimalTypes
        .filter((item) => item.selected)
        .map((item) => item.importedManureAnimalTypeValue),
    });
  };

  const onReset = () => {
    props.onCancelled();
    reset();
  };

  const form = (
    <form
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
      }}
    >
      <Stack spacing={0}>
        <div>
          {translate("importedManureAnimalTypesForm.fields.description")}
        </div>

        <>
          {fields.map(
            (field: ImportedManureAnimalTypeField, iteration: number) => {
              return (
                <Box
                  key={field.importedManureAnimalTypeValue}
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                >
                  <Controller
                    control={control}
                    name={`selectedImportedManureAnimalTypes.${iteration}.selected`}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        label={field.label}
                        control={
                          <Checkbox checked={value} onChange={onChange} />
                        }
                      />
                    )}
                  />
                </Box>
              );
            }
          )}
        </>
      </Stack>
    </form>
  );
  return (
    <BaseForm
      title={translate("importedManureAnimalTypesForm.title")}
      description={translate("importedManureAnimalTypesForm.description")}
      form={form}
      onReset={onReset}
      onSave={handleSubmit(onSubmit)}
    />
  );
};

export default ImportedManureAnimalTypeForm;
