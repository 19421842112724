import { HttpError, httpGet, httpPost } from "../http";
import { SyntheticFertilizerUsageData } from "../steps/SyntheticFertilizerUsageStep";

export interface SyntheticFertilizerProductDTO {
  name: string;
  tonnesAppliedPerYear: number;
  tonnesAppliedPerYearYearlyChangesData: YearlyDataDTO[];
  nitrogenPercentage: number;
  yearlyChangesSwitchValue: boolean;
}

export interface YearlyDataDTO {
  year: number;
  value?: YearlyValueDTO;
}

export type YearlyValueDTO = number | string | undefined; // possible values for the answers

export interface SyntheticFertilizerUsageDTO {
  products: SyntheticFertilizerProductDTO[];
}

export default class SyntheticFertilizerUsageClient {
  constructor(private kApiEndpoint: string) {}

  public async getSyntheticFertilizerUsageData(
    guid?: string
  ): Promise<SyntheticFertilizerUsageData | undefined> {
    if (!guid) {
      return undefined;
    }
    try {
      const response = await httpGet<SyntheticFertilizerUsageDTO>(
        `${this.kApiEndpoint}/v1/syntheticFertilizerUsage/${guid}`
      );
      return {
        products: response.products,
      };
    } catch (e: any) {
      if (e instanceof HttpError) {
        if (e.originalResponse.status === 404) {
          return undefined;
        }
      }
      throw e;
    }
  }

  public async createSyntheticFertilizerUsageData(
    questionnaireGuid: string,
    syntheticFertilizerUsage: SyntheticFertilizerUsageData
  ): Promise<string> {
    interface CreateSyntheticFertilizerUsageRequestDTO
      extends SyntheticFertilizerUsageDTO {
      questionnaireGuid: string;
    }

    interface CreateSyntheticFertilizerUsageResponseDTO
      extends SyntheticFertilizerUsageDTO {
      guid: string;
    }

    const response = await httpPost<
      CreateSyntheticFertilizerUsageRequestDTO,
      CreateSyntheticFertilizerUsageResponseDTO
    >(`${this.kApiEndpoint}/v1/syntheticFertilizerUsage`, {
      questionnaireGuid,
      products: syntheticFertilizerUsage.products,
    });
    return response.guid;
  }
}
